import React from "react";
import './CartPage.css';
import {Button, Icon, Paper, InputBase} from "@mui/material";
import ArticleList from "../../components/article/articleList/ArticleList";
import CartAPI from "../../apis/CartAPI";
import {ExposedAPI} from "../../apis/ExposedAPI";
import {withRouter} from "../../withRouter";

class CartPage extends React.Component{

    constructor(props) {
        super(props);
        this.state={

            refToSearch:"",
            refError:false,
            refSuccess:false,

            articles:[],

            articleAmount:0,
            htTotal:0,
            ttcTotal:0,
            taxesTotal:0,
        }
    }

    _CART_UPDATESubscription;

    componentDidMount(){
        this.getCartContent()
        this._CART_UPDATESubscription = global.emitter.addListener("CART_UPDATE",()=>{
            this.getCartContent()
        })
    }

    componentWillUnmount() {
        if(this._CART_UPDATESubscription){this._CART_UPDATESubscription.remove()}
    }

    getCartContent(){
        CartAPI.getCartContent()
            .then((cartArticles)=>{
                let articles = [];
                let articleAmount = 0;
                let htTotal = 0;
                let ttcTotal = 0;
                let taxesTotal = 0;

                for(let cartArticle of cartArticles){
                    let art = cartArticle.article;
                    art.quantity = parseInt(cartArticle.quantity);
                    articles.push(art);

                    let quantity = parseInt(cartArticle.quantity);
                    let htPrice = +cartArticle.article.basePrice;
                    let ttcPrice = +cartArticle.article.ttcPrice;

                    if(art.activeSaleCampaign){
                        htPrice = htPrice*(1-parseFloat(art.activeSaleCampaign.percentage)/100);
                        ttcPrice = ttcPrice*(1-parseFloat(art.activeSaleCampaign.percentage)/100);
                    }

                    articleAmount = articleAmount + quantity;
                    htTotal = htTotal + quantity*htPrice;
                    ttcTotal = ttcTotal + quantity*ttcPrice;
                }

                htTotal = htTotal.toFixed(2)
                ttcTotal = ttcTotal.toFixed(2)

                taxesTotal = (ttcTotal - htTotal).toFixed(2);

                this.setState({
                    articles:articles,
                    articleAmount:articleAmount,
                    htTotal:htTotal,
                    ttcTotal:ttcTotal,
                    taxesTotal:taxesTotal,
                })

            })
            .catch((err)=>{
                console.log(err);
            })
    }

    tryAddFromRef(){

        this.setState({
            refError:false,
            refSuccess:false,
        })

        ExposedAPI.getArticleByReference(this.state.refToSearch)
            .then((res)=>{
                if(res.length > 0 && res[0].soft_deleted !== "1"){
                    let article = res[0];

                    this.setState({refSuccess:true})
                    setTimeout(()=>{this.setState({refSuccess:false})},3000)

                    let cartMatch = this.state.articles.find((a)=>{return a.id + "" === article.id + ""})

                    let promise = CartAPI.addArticleToCart(article,1);
                    if(cartMatch){
                        let newAmount = parseInt(cartMatch.quantity) + 1;
                        promise = CartAPI.setArticleQuantity(article.id,newAmount)
                    }

                    promise
                        .then(()=>{

                        })
                        .catch((err)=>{console.log(err)})
                }
                else{
                    this.setState({refError:true})
                    setTimeout(()=>{this.setState({refError:false})},3000)
                }
            })
            .catch((err)=>{
                console.log(err);
                this.setState({refError:true})
                setTimeout(()=>{this.setState({refError:false})},3000)
            })

    }

    render(){

        const addRefDisabled = this.state.refToSearch.length < 3;

        return(
            <div style={{width:"100%"}}>

                <div className={"cart-header"}>

                    <div className={"card-header-bottom-left"}>

                        <Paper style={{width:"100%", padding:12, display:"flex", flexDirection:"row"}}>

                            <div style={{flex:1, position:"relative", height:36}}>
                                <InputBase style={{width:"100%", height:36, borderRadius:4, border:"1px solid #CCCCCC", overflow:"hidden", backgroundColor:"#FAFAFA",
                                    paddingLeft:8, paddingRight:8, position:"relative"}} placeholder={"Ajout rapide par référence"}
                                           onChange={(e)=>{this.setState({refToSearch:e.target.value.toUpperCase()})}}
                                           value={this.state.refToSearch}>
                                </InputBase>

                                {this.state.refError &&
                                    <p style={{position:"absolute", right:11, top:9, color:"#EE3E3B", textAlign:"right", zIndex:4, margin:0, fontSize:14}}>
                                        ✕ Référence inconnue
                                    </p>
                                }

                                {this.state.refSuccess &&
                                    <p style={{position:"absolute", right:11, top:9, color:"#1FAF3F", textAlign:"right", zIndex:4, margin:0, fontSize:14}}>
                                        ✓ Référence ajoutée
                                    </p>
                                }

                            </div>


                            <Button size={"small"} variant={"contained"} style={{color:"#FFFFFF", backgroundColor:addRefDisabled ? "#CCCCCC" : "#0068B3",
                            marginLeft:12}} onClick={()=>{this.tryAddFromRef()}}>
                                AJOUTER <Icon style={{color:"#FFFFFF", fontSize:16, marginLeft:5}}>add</Icon>
                            </Button>

                        </Paper>
                    </div>


                    <div className={"cart-header-grid"}>
                        <p style={{margin:0, fontSize:24, color:"#555555"}}>Mon devis</p>

                        <p style={{width:"100%", margin:0, fontSize:14, textAlign:"left", color:"#555555", marginTop:20, maxWidth:500}}>
                            Depuis cette page, visualisez le contenu de votre devis en cours. Vous pouvez modifier les quantité d'articles directement depuis la liste, et ajouter des articles
                            en sélectionnant leur référence depuis le champ ci dessous. <br/>
                            Une fois la liste confirmée, cliquez sur VALIDER ET CONTINUER pour passer à l'étape suivante.
                        </p>
                    </div>
                    <div className={"cart-header-grid-right"}>
                        <Paper style={{ padding:12}} className={"cart-header-recap"}>
                            <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"center"}}>
                                <p style={{flex:1, textAlign:"left", color:"#555555", fontSize:14, margin:0}} className={"cart-laius"}>
                                    Informations de votre devis
                                </p>
                                <Icon style={{fontSize:18, color:"#797979"}}>keyboard_arrow_down</Icon>
                            </div>

                            <div style={{width:"100%", borderRadius:4, border:"1px solid #CCCCCC", overflow:"hidden", marginTop:12}}>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:50}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                    alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Total HT
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#0068B3", fontSize:24, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.htTotal}€
                                        </p>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:36, borderTop:"1px solid #CCCCCC"}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Total TTC
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.ttcTotal}€
                                        </p>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:36, borderTop:"1px solid #CCCCCC"}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Total taxes
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.taxesTotal}€
                                        </p>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:36, borderTop:"1px solid #CCCCCC"}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Nbr articles
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.articleAmount}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <Button variant={"contained"} style={{backgroundColor:this.state.articleAmount === 0 ? "#CCCCCC" : "#1FAF3F", color:"#FFFFFF", width:"100%", marginTop:12}}
                                    size={"medium"} disabled={this.state.articleAmount === 0} onClick={()=>{this.goToValidateCart()}}>
                                VALIDER ET CONTINUER
                                <Icon style={{color:"#FFFFFF", fontSize:18, marginLeft:5}}>check_circle</Icon>
                            </Button>

                        </Paper>

                    </div>

                </div>

                <Paper style={{width:"100%", marginTop:12, padding:12}}>

                    <ArticleList
                        articles={this.state.articles}
                        isCart={true}
                        virtualized={false}
                        // listHeight={450}
                    />

                </Paper>

            </div>
        )
    }

    goToValidateCart(){
        this.props.router.navigate("/cart/validate")
    }
}

export default withRouter(CartPage);
