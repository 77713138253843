import {Alert, Button, Grid, LinearProgress, TextareaAutosize, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {ExposedAPI} from "../../apis/ExposedAPI";

export default function ContactUsPage() {

    const [robot, setRobot] = useState({val1: 0, val2: 0});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [content, setContent] = useState("");
    const [robotAnswer, setRobotAnswer] = useState(0);

    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [robotAnswerError, setRobotAnswerError] = useState(false);
    const [mailAndPhoneMissingError, setMailAndPhoneMissingError] = useState(false);
    const [contentTooShortError, setContentTooShortError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [sendFailure, setSendFailure] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);

    useEffect(_ => {
        setRobot({val1 : randomIntFromInterval(0, 5), val2: randomIntFromInterval(0,5)})
    }, [])



    const soFrench = ["zero", "un", "deux", "trois", "quatre", "cinq"]; //For it to be quite easy


    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const Item = function() {
        return <div style={{background: "red", width: "100%", height: "100%"}}> Hello </div>
    }

    async function trySend() {
        await setSendFailure(false);
        if(validate()) {
            await setLoading(true);
            await setSendFailure(false);
            //it's sendable
            try {
                await ExposedAPI.sendContactForm({
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phone,
                    email: email,
                    company: company,
                    content: content,
                    environment : process.env.NODE_ENV
                })
                setSendSuccess(true);
            } catch (e) {
                setSendFailure(true);
            }
            await setLoading(false);
        }
    }

    function validate() {
        setPhoneError(false);
        setMailAndPhoneMissingError(false);
        setEmailError(false);
        setRobotAnswerError(false);
        setContentTooShortError(false);

        let result = true;

        if (content.length < 3) {
            setContentTooShortError(true);
            result = false;
        }
        const isPhoneValid = /^0[0-9]{9}$|^\+[0-9]{11}$|^\+[0-9]{10}$/.test(phone);

        const isEmailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());

        setPhoneError(!isPhoneValid)
        setEmailError(!isEmailValid)
        if (!isPhoneValid && !isEmailValid) {
            setMailAndPhoneMissingError(true);
            result =  false;
        }

        if (robot.val1 + robot.val2 !== +robotAnswer) {
            setRobotAnswerError(true)
            result = false;
        }

        return result;
    }

    return <div>
        <h1>Contactez-nous</h1>
        {
            (!loading && !sendSuccess) && <>
                <p>
                    Vous avez une demande particulière ? Vous pouvez nous contacter directement au travers de ce formulaire.
                </p>
                {mailAndPhoneMissingError && <Alert style={{marginTop: 10, marginBottom: 10}} severity={"warning"}>Attention ! Nous ne pouvons pas envoyer votre demande si nous n'avons pas de moyen de recontacter. Il nous faut au minimum une adresse mail ou un numéro de téléphone pour vous recontacter.</Alert>}
                {sendFailure && <Alert style={{marginTop: 10, marginBottom: 10}} severity={"error"}>Une erreur s'est produite. Si vous ne parvenez pas à nous envoyer votre message, n'hésitez pas à nous appeler directement au 01 55 58 15 15 (Du lundi au vendredi, de 8h à 19h). Toutes nos excuses pour le dérangement.</Alert>}
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            onChange={e => setLastName(e.target.value)}
                            value={lastName}
                            label="Nom" fullWidth={true}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            onChange={e => setFirstName(e.target.value)}
                            value={firstName}
                            label="Prénom" fullWidth={true}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                            error={phoneError || mailAndPhoneMissingError}
                            label="Numéro de téléphone (E-mail ou téléphone requis)" fullWidth={true}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            error={emailError || mailAndPhoneMissingError}
                            label="E-mail (E-mail ou téléphone requis)" fullWidth={true}
                            size="small"
                            type={"email"}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            onChange={e => setCompany(e.target.value)}
                            value={company}
                            label="Entreprise /Organisation" fullWidth={true}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            error={contentTooShortError}
                            onChange={e => setContent(e.target.value)}
                            value={content}
                            label={"Que pouvons nous faire pour vous ?" + (contentTooShortError ? " Votre message est un peu court !" : "")} fullWidth={true}
                            size="small"
                            variant="outlined"
                            multiline={true}
                            rows={10}
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <h3>Assurons-nous que vous n'êtes pas un robot :</h3>
                        <TextField
                            error={robotAnswerError}
                            onChange={e => setRobotAnswer(e.target.value)}
                            value={robotAnswer}
                            label={`Combien font ${robot.val1} plus ${robot.val2} ?`} fullWidth={true}
                            type={"number"}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={12} xs={12} style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
                        <Button onClick={_ => trySend()} variant={"contained"} color={"success"}>Envoyer</Button>
                    </Grid>
                </Grid>
            </>
        }
        {
            loading && <>

                <LinearProgress style={{margin: 50}} />
                <div style={{width: "100%", textAlign: "center"}}>Merci de patienter pendant l'envoi...</div>
            </>
        }
        {
            (!loading && sendSuccess) && <>
                <Alert severity={"success"} style={{margin: 50}}>Votre message a été correctement envoyé ! Vous allez recevoir une confirmation par mail également.</Alert>
            </>
        }
    </div>

}
