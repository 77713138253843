import React, { Component } from "react";
import {withRouter} from "../../withRouter";

class ScrollTop extends Component {

    constructor(props) {
        super(props);
        this.onNavigationPerformed = this.onNavigationPerformed.bind(this)
    }

    componentDidMount() {
        window.addEventListener('popstate', this.onNavigationPerformed, false)
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onNavigationPerformed, false)
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        if(nextProps.router.location.pathname !== this.props.router.location.pathname){
            this.onNavigationPerformed();
        }
    }

    onNavigationPerformed(){
        try{document.getElementById("root").scrollTop = 0}
        catch(e){console.log(e)}
    }

    render() {
        return <React.Fragment />
    }
}

export default withRouter(ScrollTop)
