import React from "react";
import './ArticleList.css';
import {Icon, IconButton, Paper, Select, MenuItem} from "@mui/material";
import ArticleRow from "../ArticleRow";
import VirtualList from 'react-tiny-virtual-list';

export default class ArticleList extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            sortBy:"NAME",
            sortDirection:true,
            articles:this.getSortedArticles(this.props.articles,"NAME",true),
        }
    }

    componentDidMount(){
    }

    componentWillReceiveProps(nextProps, nextContext) {
       this.setState({
           articles:this.getSortedArticles(nextProps.articles,this.state.sortBy,this.state.sortDirection)
       })
    }

    getSortedArticles(articles,sortBy,sortDirection){


        let featuredArticles = articles.filter((article)=>{return article.featured === "1"});
        let defaultArticles = articles.filter((article)=>{return article.featured !== "1"});

        let as = sortDirection ? -1 : 1;
        let bs = sortDirection ? 1 : -1;

        switch(sortBy){
            case "NAME":
                featuredArticles = featuredArticles.sort((a,b)=>{return a.sort_name < b.sort_name ? as : bs})
                defaultArticles = defaultArticles.sort((a,b)=>{return a.sort_name < b.sort_name ? as : bs})
                break;
            case "BRAND":
                featuredArticles = featuredArticles.sort((a,b)=>{return a.sort_brand < b.sort_brand ? as : bs})
                defaultArticles = defaultArticles.sort((a,b)=>{return a.sort_brand < b.sort_brand ? as : bs})
                break;
            case "REF":
                featuredArticles = featuredArticles.sort((a,b)=>{return a.sort_ref < b.sort_ref ? as : bs})
                defaultArticles = defaultArticles.sort((a,b)=>{return a.sort_ref < b.sort_ref ? as : bs})
                break;
            case "COND":
                featuredArticles = featuredArticles.sort((a,b)=>{return a.sort_cond < b.sort_cond ? as : bs})
                defaultArticles = defaultArticles.sort((a,b)=>{return a.sort_cond < b.sort_cond ? as : bs})
                break;
            case "HT":
                featuredArticles = featuredArticles.sort((a,b)=>{return a.sort_ht < b.sort_ht ? as : bs})
                defaultArticles = defaultArticles.sort((a,b)=>{return a.sort_ht < b.sort_ht ? as : bs})
                break;
        }

        return featuredArticles.concat(defaultArticles)
    }

    toggleSort(sortBy){

        let newSortBy = sortBy;
        let newSortDirection = this.state.sortDirection;

        if(this.state.sortBy === sortBy){
            newSortDirection = !newSortDirection
        }

        let articles = this.getSortedArticles(this.state.articles,newSortBy,newSortDirection)

        this.setState({
            sortBy:newSortBy,
            sortDirection:newSortDirection,
            articles:articles,
        })
    }

    toggleSortDirection(){
        let newSortBy = this.state.sortBy;
        let newSortDirection = !this.state.sortDirection;

        let articles = this.getSortedArticles(this.state.articles,newSortBy,newSortDirection)

        this.setState({
            sortBy:newSortBy,
            sortDirection:newSortDirection,
            articles:articles,
        })
    }

    setSortBy(sortBy){
        let newSortBy = sortBy;
        let newSortDirection = this.state.sortDirection;

        let articles = this.getSortedArticles(this.state.articles,newSortBy,newSortDirection)

        this.setState({
            sortBy:newSortBy,
            sortDirection:newSortDirection,
            articles:articles,
        })
    }

    render(){
        return(
           <div style={{width:"100%"}}>

               <Paper className={"usc-articles-card-header"} style={{width:"100%", flexDirection:"row", overflow:"hidden", border:"1px solid #DDDDDD",
               backgroundColor:"#EFEFEF", height:50, marginBottom:12, justifyContent:"flex-start", alignItems:'center', paddingLeft:8, paddingRight:8}}>

                   <span style={{fontSize:12, color:"#797979"}}>Trier par:</span>

                   <Select
                       value={this.state.sortBy}
                       onChange={(e)=>{this.setSortBy(e.target.value)}}
                       variant={"outlined"}
                       size={"small"}
                       style={{backgroundColor:"#FAFAFA", marginLeft:6}}
                   >
                       <MenuItem value={"NAME"}>Nom/description</MenuItem>
                       <MenuItem value={"BRAND"}>Marque/fabricant</MenuItem>
                       <MenuItem value={"REF"}>Référence</MenuItem>
                       <MenuItem value={"COND"}>Conditionnement</MenuItem>
                       <MenuItem value={"HT"}>Tarif HT</MenuItem>

                   </Select>

                   <div style={{flex:1}}/>
                   <IconButton style={{border:"1px solid #CCCCCC"}} onClick={()=>{this.toggleSortDirection()}}>
                       <Icon style={{color:"#222222", fontSize:16}} className={this.state.sortDirection ? "sort-by-icon sort-by-icon-desc" : "sort-by-icon"}>arrow_upward</Icon>
                   </IconButton>
               </Paper>

               <Paper style={{width:"100%",  borderRadius:4, overflow:"hidden", border:"1px solid #DDDDDD"}} className={"usc-articles-paper"}>
                   <div style={{width:"100%", height:50, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                       backgroundColor:"#EFEFEF", borderBottom:"1px solid #DDDDDD"}} className={"usc-articles-table-header"}>

                       <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"center", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               Image
                           </p>
                       </div>
                       <div style={{width:300, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               Nom/desciption
                           </p>
                           {this.renderSortButton("NAME")}
                       </div>
                       <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               Marque
                           </p>
                           {this.renderSortButton("BRAND")}
                       </div>
                       <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               Ref
                           </p>
                           {this.renderSortButton("REF")}
                       </div>
                       <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               Cond.
                           </p>
                           {this.renderSortButton("COND")}
                       </div>
                       <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               Prix HT.
                           </p>
                           {this.renderSortButton("HT")}
                       </div>
                       <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"center", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               {this.props.isCart === true ? "Quantité" : "Devis"}
                           </p>
                       </div>
                       <div style={{flex:1, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                           justifyContent:"flex-start", alignItems:"center", padding:5}}>
                           <p style={{flex:1, textAlign:"flex", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                               {this.props.isCart === true ? "Actions" : "Comp."}
                           </p>
                       </div>
                   </div>

                   {this.props.virtualized !== true &&
                       <>
                           {this.state.articles.map((article)=>{
                               return(
                                   <ArticleRow
                                       isCart={this.props.isCart}
                                       article={article}
                                       key={"article_" + article.id}/>
                               )
                           })}
                       </>
                   }

                   {this.props.virtualized === true &&
                       <>
                           <VirtualList
                               width={"100%"}
                               height={this.props.listHeight ? this.props.listHeight : 500}
                               itemCount={this.state.articles.length}
                               itemSize={window.innerWidth > 768 ? 100 : 212}
                               overscanCount={10}
                               renderItem={({index, style}) => {
                                   let article = this.state.articles[index];
                                   return(
                                       <div key={index} style={style}>
                                           <ArticleRow
                                               isCart={this.props.isCart}
                                               article={article}
                                               key={"article_" + article.id}/>
                                       </div>
                                   )
                               }}
                           />
                       </>
                   }

                   {this.state.articles.length === 0 &&
                       <div style={{width:"100%", height:50, display:"flex", justifyContent:"center", alignItems:"center", padding:12,}}>
                           <p style={{width:"100%", textAlign:"center", fontSize:14, color:"#797979", margin:0}}>
                               Aucun article à afficher
                           </p>
                       </div>
                   }

               </Paper>
           </div>
        )
    }

    renderSortButton(sortBy){

        const selected = sortBy === this.state.sortBy;
        const down = this.state.sortDirection === false;

        return(
            <IconButton style={{border:selected ? "1px solid #CCCCCC" : "1px solid transparent"}} onClick={()=>{this.toggleSort(sortBy)}}>
                <Icon style={{color:selected ? "#222222" : "#797979", fontSize:16}} className={down ? "sort-by-icon sort-by-icon-desc" : "sort-by-icon"}>arrow_upward</Icon>
            </IconButton>
        )
    }

    static prepareArticlesFromSQL(articles) {
        for(let article of articles){
            if(article.description.length > 180){article.short_description = article.description.substring(0,180) + "..."}
            else{article.short_description = article.description}

            article.sort_name = article.label.toUpperCase();
            article.sort_brand = article.brandName ? article.brandName.toUpperCase() : "";
            article.sort_ref = article.crmId.toUpperCase();
            article.sort_cond = article.conditionnement ? article.conditionnement.toUpperCase() : "";
            article.sort_ht = parseFloat(article.basePrice);
        }
        return articles;
    }
}
