import React from "react";
import './Menu.css';
import {Link} from "react-router-dom"
import {ExposedAPI} from "../../apis/ExposedAPI";
import {Button, ClickAwayListener, Icon} from "@mui/material";
import btx_logo from "../../images/logos/logo_btx_white.svg"

export const PREVENT_MENU_HOVER_EVENT_NAME = "PREVENT_MENU_HOVER";

export default class Menu extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            categories:[],
            selectedCategory:null,
            selectedSubCategory:null,
            mobileMenuDisplayed:false,
        }
    }

    preventHoverUntil: number = 0;  //Unix timestamp (with millis) until we should remove

    componentDidMount() {
        this.loadCategories();
        global.emitter.addListener(PREVENT_MENU_HOVER_EVENT_NAME, (duration) => {
            console.log("Preventing hover on menu for a duration of " + duration + "ms")
            this.preventHoverUntil = (new Date()).getTime() + duration;
        })
    }

    loadCategories(){
        ExposedAPI.getCategories()
            .then((categories)=>{
                this.setState({categories:categories})
            })
            .catch((err)=>{console.log(err)})
    }

    categoryHoveredTimeout = null;
    onCategoryHovered(category){
        if(category === null) {
            clearTimeout(this.categoryHoveredTimeout);                                  //prevent any other category show up
            this.setState({selectedCategory:null,selectedSubCategory:null});      //Always hide when requested to hide
        } else if(this.preventHoverUntil < (new Date().getTime())) {                    //Reacts only if hover was not prevented from elsewhere
            if(this.state.selectedCategory !== null) {
                this.setState({selectedCategory:category,selectedSubCategory:null})     //If the menu was already open, react immediately
            } else {
                clearTimeout(this.categoryHoveredTimeout);                                         //Clear any timeout that was pending
                this.categoryHoveredTimeout = setTimeout(_ => {
                    this.setState({selectedCategory:category,selectedSubCategory:null})     //Reacts x ms after hover if menu was not open to avoid intempestive openings
                }, 250)
            }

        }
    }

    onSubCategoryHovered(subCategory){
        this.setState({selectedSubCategory:subCategory})
    }

    toggleDisplayMobileMenu(){
        this.setState({
            mobileMenuDisplayed:!this.state.mobileMenuDisplayed
        })
    }

    hideMobileMenu(){
        this.setState({
            mobileMenuDisplayed:false
        })
    }

    clearSelectedElements(){
        this.setState({
            selectedCategory:null,
            selectedSubCategory:null,
        })
    }

    preventHoverTemporarily() {
        this.preventHoverUntil = (new Date()).getTime() + 1000;
        // console.log("Hover prevented for " + (this.preventHoverUntil - (new Date()).getTime()) + "ms");
    }

    render(){
        return(
           <ClickAwayListener onClickAway={_ => this.onCategoryHovered(null)}>
               <div className={"menu-main-wrapper"} onMouseLeave={()=>{this.onCategoryHovered(null)}}>
                   {/*TOP BAR*/}
                   <div className={"menu-top-bar"} style={{zIndex:20}}>
                       <Link style={{textDecoration:"none", height:"100%"}} to={"/home"}>
                           <div className={"menu-top-bar-hover-button"} onMouseEnter={()=>{this.onCategoryHovered(null)}}>
                               <Icon style={{fontSize:24}} className={"menu-top-bar-hover-button-icon"}>home</Icon>
                           </div>
                       </Link>
                       <div className={"menu-top-bar-categories-wrapper"}>
                           {this.state.categories.map((category)=>{
                               const selected = this.state.selectedCategory && this.state.selectedCategory.id === category.id;
                               return(
                                   <Link style={{textDecoration:"none"}} to={"/c/" + category.id} key={"category_" + category.id} onClick={()=>{this.preventHoverTemporarily();this.clearSelectedElements()}}>
                                       <div className={selected ? "menu-top-bar-hover-button menu-top-bar-hover-button-selected" : "menu-top-bar-hover-button"} onMouseEnter={()=>{this.onCategoryHovered(category)}}>
                                           <p className={"menu-top-bar-hover-button-label"}>{category.label}</p>
                                           <Icon style={{fontSize:20}} className={"menu-top-bar-hover-button-icon"}>keyboard_arrow_down</Icon>
                                       </div>
                                   </Link>
                               )
                           })}
                       </div>
                       <div style={{paddingLeft:10}} className={"small-categories-button"}>
                           <Button size={"small"} variant={"outlined"} style={{color:"#FFFFFF", borderColor:"#FFFFFF"}} onClick={()=>{this.toggleDisplayMobileMenu()}}>
                               CATÉGORIES
                               <Icon style={{marginLeft:4, fontSize:18, color:"#FFFFFF"}}>arrow_forward</Icon>
                           </Button>
                       </div>
                   </div>
                   {/*BOTTOM CONTENT*/}
                   {this.state.selectedCategory &&
                       <div className={"menu-bottom-content"}>
                           <div className={"menu-bottom-content-left-pane"}>
                               {this.state.selectedCategory.subCategories.map((subCategory)=>{
                                   const selected = this.state.selectedSubCategory && this.state.selectedSubCategory.id === subCategory.id;
                                   return(
                                       <Link style={{textDecoration:"none"}} to={"/sc/" + subCategory.id} key={"sub_category_" + subCategory.id} onClick={()=>{this.clearSelectedElements()}}>
                                           <div className={selected ? "menu-subcategory-button menu-subcategory-button-selected" : "menu-subcategory-button"} onMouseEnter={()=>{this.onSubCategoryHovered(subCategory)}}>
                                               <p className={"menu-subcategory-label"}>{subCategory.label}</p>
                                               <Icon style={{fontSize:20}} className={"menu-subcategory-icon"}>keyboard_arrow_right</Icon>
                                           </div>
                                       </Link>
                                   )
                               })}
                           </div>
                           <div className={"menu-bottom-content-right-pane"}>
                               {!this.state.selectedSubCategory &&
                                   <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                       <p style={{width:250, color:"#797979", fontSize:14, margin:0, textAlign:"center"}}>
                                           Survolez une catégorie du menu de gauche pour afficher les catégories détaillées
                                       </p>
                                   </div>
                               }
                               {this.state.selectedSubCategory && this.state.selectedSubCategory.underSubCategories.map((underSubCategory)=>{
                                   return(
                                       <Link style={{textDecoration:"none", width:"50%"}} to={"/usc/" + underSubCategory.id} key={"under_sub_category_" + underSubCategory.id}
                                             onClick={()=>{this.clearSelectedElements()}}>
                                           <div className={"menu-subcategory-button"}>
                                               <p className={"menu-subcategory-label"}>{underSubCategory.label}</p>
                                           </div>
                                       </Link>
                                   )
                               })}
                           </div>
                       </div>
                   }

                   <div className={this.state.mobileMenuDisplayed ? "mobile-menu-wrapper mobile-menu-wrapper-displayed" : "mobile-menu-wrapper"}>
                       <div className={"mobile-menu-inner-wrapper"}>
                           <div className={"mobile-menu-backdrop"} onClick={()=>{this.hideMobileMenu()}}/>
                           <div className={"mobile-menu-slider"}>
                               <div style={{width:"100%", height:100, backgroundColor:"#0068B3", display:"flex", flexDirection:"row",
                                   justifyContent:"flex-start", alignItems:"center"}} className={"shadowed-header"}>

                                   <img alt={"___"} style={{height:60, width:60, marginLeft:20}} src={btx_logo}/>

                               </div>
                               <div style={{width:"100%", height:"calc(100% - 100px)", overflowY:'auto', overflowX:"hidden"}} >
                                   {this.state.categories.length > 0 &&
                                       <CategoriesAccordion
                                           mode={"all"}
                                           categories={this.state.categories}/>
                                   }
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </ClickAwayListener>
        )
    }
}

export class CategoriesAccordion extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            categories:this.handleInitialCategory(),
        }
    }

    handleInitialCategory(){
        let categories = this.props.categories;
        if(this.props.mode && this.props.mode === "category"){
            categories[0].isOpen = true;
        }
        return categories;
    }

    toggleIsOpenCategory(cat){
        let categories = this.state.categories.slice();
        for(let category of categories){
            if(category.id === cat.id){
                category.isOpen = category.isOpen !== true;
                if(category.isOpen === false){
                    for(let subCategory of category.subCategories){
                        subCategory.isOpen = false;
                    }
                }
                break;
            }
        }
        this.setState({
            categories:categories
        })
    }

    toggleIsOpenSubCategory(cat,subCat){
        let categories = this.state.categories.slice();
        loop:for(let category of categories){
            if(category.id === cat.id){
                for(let subCategory of category.subCategories){
                    if(subCategory.id === subCat.id){
                        subCategory.isOpen = subCategory.isOpen !== true;
                        break loop;
                    }
                }
            }
        }
        this.setState({
            categories:categories
        })
    }

    render(){

        return(
            <div style={{width:"100%"}}>
                {this.state.categories.map((category)=>{
                    return(
                        <div className={"mobile-menu-category"} key={"c_row_" + category.id}>
                            <div className={"mobile-menu-category-row"}>
                                <Link style={{textDecoration:"none", flex:1, height:"100%"}} to={"/c/" + category.id}>
                                    <div style={{height:"100%", flex:1, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                                        paddingLeft:12}} className={"mobile-menu-link"}>
                                        <p style={{fontWeight:"bold", textAlign:"left", fontSize:16, margin:0, color:"#222222", width:"100%",
                                            textTransform:"uppercase"}} className={"noselect"}>
                                            {category.label}
                                        </p>
                                    </div>
                                </Link>
                                <div className={category.isOpen === true ? "toggler toggler-opened" : "toggler"}
                                     onClick={(e)=>{e.stopPropagation(); this.toggleIsOpenCategory(category)}}>
                                    <Icon style={{color:"#555555", fontSize:18}}>keyboard_arrow_right</Icon>
                                </div>
                            </div>
                            {category.isOpen === true &&
                                <div className={"mobile-menu-category-content-wrapper"}>
                                    <div className={"mobile-menu-category-content"}>
                                        {category.subCategories.map((subCategory)=>{
                                            return(
                                                <div className={"mobile-menu-sub-category"} key={"c_row_" + category.id + "sc_row_" + subCategory.id}>
                                                    <div className={"mobile-menu-sub-category-row"}>
                                                        <Link style={{textDecoration:"none", flex:1, height:"100%"}} to={"/sc/" + subCategory.id}>
                                                            <div style={{height:"100%", flex:1, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                                                                paddingLeft:12}} className={"mobile-menu-link"}>
                                                                <p style={{fontWeight:"bold", textAlign:"left", fontSize:14, margin:0, color:"#222222", width:"100%"}} className={"noselect"}>
                                                                    {subCategory.label}
                                                                </p>
                                                            </div>
                                                        </Link>
                                                        <div className={subCategory.isOpen === true ? "toggler toggler-opened" : "toggler"}
                                                             onClick={(e)=>{e.stopPropagation(); this.toggleIsOpenSubCategory(category,subCategory)}}>
                                                            <Icon style={{color:"#555555", fontSize:18}}>keyboard_arrow_right</Icon>
                                                        </div>
                                                    </div>
                                                    {subCategory.isOpen === true &&
                                                        <div className={"mobile-menu-sub-category-content-wrapper"}>
                                                            <div className={"mobile-menu-sub-category-content"}>
                                                                {subCategory.underSubCategories.map((underSubCategory)=>{
                                                                    return(
                                                                        <Link style={{textDecoration:"none", flex:1, height:"100%"}} to={"/usc/" + underSubCategory.id}>
                                                                            <div style={{height:44, flex:1, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                                                                                paddingLeft:12}} className={"mobile-menu-link"}>
                                                                                <p style={{ textAlign:"left", fontSize:14, margin:0, color:"#222222", width:"100%"}} className={"noselect"}>
                                                                                    {underSubCategory.label}
                                                                                </p>
                                                                            </div>
                                                                        </Link>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }
}
