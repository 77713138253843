import React, {useEffect, useState} from "react";
import AdminAPI from "../../../../apis/AdminAPI";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert, Button,
    Chip,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {Loader} from "../../../../components/loader/Loader";
import moment from "moment";
import {ArrowRight, ExpandMore} from "@mui/icons-material";
import ArticleList from "../../../../components/article/articleList/ArticleList";
import {RenderSalesCampaign} from "./RenderSalesCampaign";



export function SalesCampaignsPage() {

    const [loading, setLoading] = useState(true);
    const [onGoingSales, setOnGoingSales] = useState([]);
    const [pastSales, setPastSales] = useState([]);
    const [futureSales, setFutureSales] = useState([]);

    const [title, setTitle] = useState("");
    const [percentage, setPercentage] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");




    useEffect(_ => {
        loadSalesCampaigns()
        let sub = global.emitter.addListener("reload_sales_campaigns", loadSalesCampaigns);
        return _ => sub.remove();
    }, [])

    async function loadSalesCampaigns() {
        await setLoading(true);
        let res = await AdminAPI.getAllSalesCampaigns();
        for(let i = 0; i < res.length; i++) {
            res[i].articles = ArticleList.prepareArticlesFromSQL(await AdminAPI.getSalesCampaignArticles(res[i].id));
        }
        let now = moment().format('YYYY-MM-DD HH:mm:ss');
        console.log(res);
        await setOnGoingSales(res.filter(e => e.start_date_time <= now && e.end_date_time >= now))
        await setPastSales(res.filter(e => e.end_date_time < now).map(e => {e.terminated = true; return e;}))
        await setFutureSales(res.filter(e => e.start_date_time > now))
        await setLoading(false);
    }

    const createForm = [
        {label: "Titre de la nouvelle campagne", setter: setTitle, getter: title, md: 8},
        {label: "Pourcentage de remise", setter: setPercentage, getter: percentage, md: 4, type: "number"},
        {label: "Date de début de validité", setter: setStartDate, getter: startDate, md: 4, type: "date"},
        {label: "Date de fin de validité", setter: setEndDate, getter: endDate, md: 4, type: "date"},
    ]

    async function onSubmit() {
        console.log(title, percentage, startDate, endDate);
        await AdminAPI.createSalesCampaign(startDate + " 00:00:00", endDate + " 23:59:59", title, percentage);
        global.emitter.emit("showSnackBar", {severity:"success", message : "La nouvelle campagne a été enregistrée"})

        await loadSalesCampaigns();
    }

    return <div>
        {
            loading && <Loader height={320}/>
        }
        {
            !loading && <Paper style={{width: "100%", padding: 10}}>
                <Alert severity={"info"}>Pour ajouter des articles dans des campagnes existantes, faites un clic droit depuis n'importe quelle liste d'article puis sélectionnez "AJOUTER/RETIRER A UNE PROMO"</Alert>
                <br/>
                <Typography variant={"h5"}>Créer Nouvelle campagne</Typography>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <Grid container spacing={2}>
                        {
                            createForm.map((item, index) => <Grid item key={index} md={item.md||item.spacer||12} xs={12} >
                                {
                                    !item.spacer && <TextField
                                        onChange={e => item.setter(e.target.value)}
                                        value={item.getter}
                                        type={item.type||"text"}
                                        label={item.label} fullWidth={true}
                                        size="small"
                                        variant="outlined"
                                    />
                                }
                            </Grid>)
                        }
                        <Grid item md={4}>
                            <Button fullWidth variant={"contained"} color={"success"} onClick={_ => onSubmit()}>Créer</Button>
                        </Grid>
                    </Grid>
                </div>

                {
                    [["en cours", onGoingSales], ["futures", futureSales], ["terminées", pastSales]].map((item, index) => <>
                            <br/> <Typography key={index} variant={"h5"}>Campagnes de promotion {item[0]}</Typography> <br/>

                        {
                            item[1].sort((a,b) => a.start_date_time > b.start_date_time ? 1 : (a.start_date_time === b.start_date_time ? 0 : -1)).map((sc, index) => <RenderSalesCampaign key={index} sc={sc}/>)
                        }
                    </>
                    )
                }
            </Paper>
        }
    </div>

}


