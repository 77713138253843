/**
 * Basically provides endpoint URL depending on the environment
 */
export class EndPointProvider {

    static getBaseURLWithoutEndSlash() {
        if(process.env.NODE_ENV === "development") {
            return "https://btx.api.dev.hubup.cloud";
        } else {
            return "https://btx.api.prod.hubup.cloud";
        }
    }




}
