import {useEffect, useState} from "react";
import {Loader} from "../../../../components/loader/Loader";
import {
    Alert,
    Grid,
    Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import AdminAPI from "../../../../apis/AdminAPI";
import {VerifiedUserSharp} from "@mui/icons-material";
import {APITools} from "../../../../apis/APITools";

export function UsersManagementPage() {

    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [filteredUsers, setFilteredUsers] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [notifiedUsers, setNotifiedUsers] = useState();

    useEffect(_ => loadUsers(), []);

    async function loadUsers() {
        await setLoading(true);
        let u = (await AdminAPI.getUserList()).map(e => {
            e.email = e.email ? e.email : "";
            e.phone = e.phone ? e.phone : "";
            return e;
        });

        await setUsers(u);
        await setFilteredUsers(u);
        setNotifiedUsers((await AdminAPI.getNotifiedEmailsList()).mailer_btx_staff);
        await setLoading(false);
    }

    async function filterUsers(search) {
        let realSearch = search.split(" ")[0];
        await setSearch(realSearch);
        if(realSearch.length >= 2) {
            await setFilteredUsers([]);
            await setFilteredUsers(users.filter(user => {
                return user.firstName.indexOf(realSearch) !== -1 ||
                user.lastName?.indexOf(realSearch)  !== -1 ||
                user.email?.indexOf(realSearch)  !== -1 ||
                user.phone?.indexOf(realSearch) !== -1

            }));
        } else {
            setFilteredUsers(users);
        }


    }

    async function deleteUser(user_id) {
        setLoading(true);
        if(window.confirm("Êtes-vous sûr(e) de vouloir procéder à la suppression de ce compte ? Cette action est définitive.")) {
            await AdminAPI.deleteUser(user_id);
            await loadUsers();
            global.emitter.emit("showSnackBar", {severity:"success", message : "Suppression effectuée"})
        }
        setLoading(false);
    }

    const handleContextMenu = (event, user) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                    user: user
                }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    return <div>
        {
            loading && <Loader/>
        }

        {
            !loading && users && users.length && <div>
                <Paper style={{padding: 5, marginBottom: 5}}>
                    <div style={{display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between"}}>
                        <Typography variant={"h5"}> Utilisateurs enregistrés</Typography>
                        <TextField
                            value={search}
                            onChange={e => filterUsers(e.target.value)}
                            variant={"outlined"}
                            size="small"
                            style={{width: 300}}
                            label={"Rechercher un utilisateur"}
                        />

                    </div>

                    <Alert severity={"info"} style={{marginTop: 10, marginBottom: 10}}>
                        Informations complémentaires
                        <ul>
                            <li>Si vous souhaitez ajouter un compte administrateur, contactez Hubup</li>
                            <li>Voici la liste des personnes recevant les mails destinés à Bio-technofix : <strong>{notifiedUsers.join(", ")}</strong>, si vous souhaitez modifier cette lsite, contactez Hubup</li>
                        </ul>
                    </Alert>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Nom
                                </TableCell>
                                <TableCell>
                                    Prénom
                                </TableCell>
                                <TableCell>
                                    e-mail
                                </TableCell>
                                <TableCell>
                                    téléphone
                                </TableCell>
                                <TableCell>
                                    Mail vérifié
                                </TableCell>
                                <TableCell>
                                   Voir les devis
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredUsers && filteredUsers.map((user, key) => <TableRow key={key} onContextMenu={event => handleContextMenu(event, user)}  >
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell><a href={'mailto:' + user.email}>{user.email}</a></TableCell>
                                    <TableCell>{user.phone}</TableCell>
                                    <TableCell>{user.activated === "0" ? "Non" : "Oui"}</TableCell>
                                    <TableCell>{user.verified}</TableCell>
                                </TableRow>)
                            }
                        </TableBody>

                    </Table>
                </Paper>
            </div>
        }
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem onClick={_ => deleteUser(contextMenu?.user.id)} >Supprimer {contextMenu?.user.email}</MenuItem>
        </Menu>
    </div>


}
