import {
    Badge,
    Breadcrumbs,
    Divider,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Typography
} from "@mui/material";
import {
    ArrowCircleUp,
    AttachMoney,
    Dashboard,
    Engineering,
    FiberNew,
    History,
    ImportExport, Info, Logout,
    People,
} from "@mui/icons-material";
import {Outlet, useNavigate, Link as RouterLink} from "react-router-dom";
import UserAPI from "../../apis/UserAPI";
import {useEffect} from "react";
import "./MyAccountPage.css"

export default function MyAccountPage() {

    let navigate = useNavigate();

    useEffect(_ => {
        if(!UserAPI.isUserLoggedIn()) {
            navigate("/login");
        }
        if(window.location.pathname === "/my/account") navigate("/my/account/infos")
        // if(window.location.pathname === "/my/account/quotes") navigate("/my/account/quotes")
    })


    async function disconnect() {
        if(window.confirm("Êtes-vous sûr de vouloir vous déconnecter ?")) {
            await UserAPI.logout();
            global.emitter.emit("showSnackBar", {severity:"success", message : "Déconnexion réussie."})
            navigate("/");
        }
    }

    function isMatching(subURL) {
        return window.location.pathname === ("/my/account" + subURL);
    }



    return <div>
        <div>
            <Breadcrumbs aria-label="breadcrumb" style={{marginTop: 20, marginBottom: 20}}>
                <Link underline="hover" color="inherit" href="/my/account">
                    Mon compte
                </Link>
                <Typography color="text.primary">Mes informations personnelles</Typography>
            </Breadcrumbs>
        </div>
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems:"flex-start", justifyContent: "flex-start"}}
        className={"my-account-content-wrapper"}>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <MenuList>
                    <MenuItem disabled={true}>
                        <ListItemText>Mes informations</ListItemText>
                    </MenuItem>
                    <RouterLink to={"/my/account/infos"} style={{textDecoration:"none"}}>
                        <MenuItem selected={isMatching("/infos")}>
                            <ListItemIcon>
                                <Info fontSize="small" />
                            </ListItemIcon>
                            <ListItemText style={{color:"#212121"}}>Mes informations personnelles</ListItemText>
                        </MenuItem>
                    </RouterLink>
                    <MenuItem onClick={_ => disconnect()}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Déconnexion</ListItemText>
                    </MenuItem>
                    <Divider/>
                    <MenuItem disabled={true}>
                        <ListItemText>Mes devis</ListItemText>
                    </MenuItem>
                    <RouterLink to={"/my/account/quotes"} style={{textDecoration:"none", color:"none"}}>
                        <MenuItem selected={isMatching("/quotes")}>
                            <ListItemIcon>
                                <History fontSize="small" />
                            </ListItemIcon>
                            <ListItemText style={{color:"#212121"}}>Historique de mes devis</ListItemText>
                        </MenuItem>
                    </RouterLink>
                </MenuList>
            </Paper>

            <div style={{paddingLeft:12, flex: 1}}  className={"my-account-outlet-wrapper"}>
                <Outlet />
            </div>
        </div>
    </div>
}
