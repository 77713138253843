import React from "react";
import "./ArticlePage.css";
import {withRouter} from "../../withRouter";
import {ExposedAPI} from "../../apis/ExposedAPI";
import {Button, Icon, LinearProgress, Paper, ButtonBase, Tooltip, InputBase, IconButton, Alert} from "@mui/material";
import {Link} from "react-router-dom";
import moment from "moment";

import discount from "../../images/pennants/discount.svg";
import featured from "../../images/pennants/featured.svg";
import CartAPI from "../../apis/CartAPI";
import AdminAPI from "../../apis/AdminAPI";
import UserAPI from "../../apis/UserAPI";

class ArticlePage extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            breadcrumbs:[],
            article:null,
            amountToAdd:1,
            selectedPicture:null,
            detailedPicture:null,

            youtubeLinkToEdit:"",
        }
    }

    componentDidMount(){
        this.loadArticle(this.props.router.params.article);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadArticle(nextProps.router.params.article);
    }

    loadArticle(articleId){

        this.setState({
            breadcrumbs:[],
            article:null,
            youtubeLinkToEdit:"",
        })

        let promises = [
            ExposedAPI.getDistantOrStoredCategories(),
            ExposedAPI.getArticle(articleId),
        ]

        Promise.all(promises)
            .then((results)=>{

                let article = results[1].article[0];
                article.pictures = results[1].attachments.filter((a)=>{return a.is_picture === "1"});
                article.attachments = results[1].attachments.filter((a)=>{return a.is_picture !== "1"});
                article.sales_campaigns = [];

                let selectedPicture = null;
                if(article.pictures){
                    let pictureMatch = article.pictures.find((p)=>{return p.firstAttachment === "1"});
                    if(pictureMatch){
                        selectedPicture = pictureMatch
                    }
                }

                this.setState({
                    breadcrumbs:this.getArticleBreadcrumbs(results[0],article),
                    article:article,
                    youtubeLinkToEdit:article.youtubeLink ? article.youtubeLink : "",
                    selectedPicture:selectedPicture,
                })
            })
            .catch((e)=>{console.log(e)})
    }

    onAmountToAddChanged(value){
        if(!value || !CartAPI.isInteger(value)){return}
        this.setState({amountToAdd:value})
    }

    addToCart(){
        let message = "";
        if(this.state.amountToAdd > 1){message = this.state.amountToAdd + " articles ajoutés au devis"}
        else{message = "1 article ajouté au devis"}
        CartAPI.addArticleToCart(this.state.article,this.state.amountToAdd)
            .then((res)=>{
                global.emitter.emit("showSnackBar",{
                    severity:"success",
                    message:message
                })
                this.setState({
                    amountToAdd:1,
                })
            })
            .catch((err)=>{
                console.log(err)
            })
    }

    getArticleBreadcrumbs(categories, article){

        let matchCategory = null;
        let matchSubCategories = null;
        let matchUnderSubCategories = null;
        let matchArticle = article;

        loop:for(let category of categories){
            for(let subCategory of category.subCategories){
                for(let underSubCategory of subCategory.underSubCategories){
                    if(underSubCategory.id + "" === article.under_sub_category_id + ""){
                        matchCategory = category;
                        matchSubCategories = subCategory;
                        matchUnderSubCategories = underSubCategory;
                        break loop;
                    }
                }
            }
        }

        return [
            {
                id:"c_" + matchCategory.id,
                label:matchCategory.label,
                link:"/c/" + matchCategory.id
            },
            {
                id:"sc_" + matchSubCategories.id,
                label:matchSubCategories.label,
                link:"/sc/" + matchSubCategories.id
            },
            {
                id:"usc_" + matchUnderSubCategories.id,
                label:matchUnderSubCategories.label,
                link:"/usc/" + matchUnderSubCategories.id
            } ,
            {
                id:"a_" + matchArticle.id,
                label:matchArticle.label,
                link:"/a/" + matchArticle.id
            }
        ]
    }

    detailPicture(){
        let height = +this.state.selectedPicture.px_height;
        let width = +this.state.selectedPicture.px_width;

        let maxDimension = window.innerWidth > window.innerHeight ? window.innerHeight - 40 : window.innerHeight - 40;

        let imageHeight = maxDimension;
        let imageWidth = maxDimension;

        if(height >= width){
            if(height > maxDimension){
                imageHeight = maxDimension;
                imageWidth = maxDimension*(width/height);
            }
            else{
                imageHeight = height;
                imageWidth = width;
            }
        }
        else{
            if(width > maxDimension){
                imageHeight = maxDimension*(height/width);
                imageWidth = maxDimension;
            }
            else{
                imageHeight = height;
                imageWidth = width;
            }
        }

        let detailedPicture = this.state.selectedPicture;

        detailedPicture.displayHeight = imageHeight;
        detailedPicture.displayWidth = imageWidth;

        this.setState({
            detailedPicture:detailedPicture,
        })

    }

    cancelDetailPicture(){
        this.setState({
            detailedPicture:null,
        })
    }

    render(){

        const article = this.state.article;
        const isAdmin = UserAPI.isUserLoggedInAndAdmin();

        return(
            <div style={{width:"100%", position:"relative"}}>
                {this.state.breadcrumbs.length === 0 && <LinearProgress style={{width:"100%", position:"absolute", left:0, top:0, height:5, color:"#0068B3"}}/>}
                <div style={{width:"100%", minHeight:54, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", flexWrap:"wrap",
                    paddingLeft:6, paddingRight:12, paddingTop:6, paddingBottom:6}}>
                    {this.state.breadcrumbs.map((bc,index)=>{
                        return(
                            <div key={"bc_" + bc.id} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", marginLeft:6}}>

                                <Link style={{textDecoration:"none", paddingBottom:2}} to={bc.link}>
                                    <span style={{color:"#222222", fontSize:14}} className={"breadcrumb-link"}>{bc.label}</span>
                                </Link>
                                {index !== this.state.breadcrumbs.length - 1 &&
                                    <Icon style={{fontSize:18, marginLeft:6}}>{"keyboard_arrow_right"}</Icon>
                                }
                            </div>
                        )
                    })}
                </div>

                {article &&
                    <Paper style={{width:"100%", padding:12}}>

                        <p style={{width:"100%", maxWidth:500, color:"#222222", textAlign:"left", margin:0, fontSize:20, fontWeight:"bold"}}>
                            {article.label}
                        </p>

                        <div className={"article-detail-first-row"} style={{marginTop:12}}>
                            <div className={"article-detail-first-row-cell-1"}>

                                <p style={{width:"100%", color:"#555555", textAlign:"left", margin:0, fontSize:14, paddingRight:25}}>
                                    {article.description}
                                </p>

                                <div style={{height:25}}/>

                                {article.activeSaleCampaign &&
                                    <div style={{width:"100%", paddingRight:12}}>
                                        <Alert severity="success">
                                           <strong>{article.activeSaleCampaign.title}  -{article.activeSaleCampaign.percentage}%</strong> <br/>
                                            Cet article est en promotion jusqu'au {moment(article.activeSaleCampaign.end_date_time).format("DD/MM/YY")}
                                        </Alert>
                                    </div>
                                }

                                <div style={{height:15}}/>

                                {this.state.selectedPicture &&
                                    <>
                                        <div style={{width:350, height:350, borderRadius:4, border:"1px solid #EFEFEF", overflow:"hidden", position:"relative",
                                        backgroundImage:"url(" + ExposedAPI.getFullSizeAttachmentURL(this.state.selectedPicture.id) + ")"}}
                                        className={"bg-image-squared bg-image-squared-large"}>

                                            {article.activeSaleCampaign && article.featured !== "1" && <div className={"article-detail-pennant"} style={{backgroundImage:"url(" + discount + ")"}}/>}
                                            {article.featured === "1" && <div className={"article-detail-pennant"} style={{backgroundImage:"url(" + featured + ")"}}/>}

                                            {article.activeSaleCampaign &&
                                                <p style={{position:"absolute", bottom:2, right:2, padding:4, backgroundColor:"#2CBE4E", color:"#FFFFFF", margin:0, zIndex:4,
                                                    fontWeight:"bold", borderRadius:4, border:"1px solid #FFFFFF", fontSize:24}}>
                                                    -{article.activeSaleCampaign.percentage}%
                                                </p>
                                            }

                                            <div style={{height:"100%", width:"100%", cursor:"pointer"}} className={"enlarge-image-hover"}
                                                 onClick={()=>{this.detailPicture()}}>
                                                <p style={{width:"100%", padding:50, textAlign:"center", color:"#FFFFFF",margin:0, fontSize:12}}>
                                                    Cliquez pour agrandir l'image
                                                </p>
                                            </div>
                                        </div>

                                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", marginTop:8}}>

                                            {this.state.article.pictures.map((picture)=>{
                                                const selected = this.state.selectedPicture.id === picture.id;
                                                return(
                                                    <div key={"pict_" + picture.id} style={{height:60, width:60, marginRight:8}}>
                                                        <div style={{width:"100%", height:"100%", borderRadius:4, overflow:"hidden", border:selected ? "2px solid #0068B3" : "1px solid #EFEFEF",
                                                            cursor:"pointer", backgroundImage:"url(" + ExposedAPI.getSpecificAttachmentThumbnailURL(picture.id) + ")"}}
                                                             className={"bg-image-squared"}
                                                        onClick={()=>{this.setState({selectedPicture:picture})}}>

                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </>
                                }

                            </div>
                            <div className={"article-detail-first-row-cell-2"}>

                                <div style={{width:"100%", borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden", backgroundColor:"#FAFAFA"}}>

                                    <div style={{width:"100%", display:"flex", flexDirection:"row", height:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Fabricant
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", color:"#222222", fontSize:14}}>
                                                {article.brandName}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"row", height:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Référence
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", color:"#222222", fontSize:14}}>
                                                {article.crmId}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"row", height:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Ref.comp
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", color:"#222222", fontSize:14}}>
                                                {article.crm_secondary_id}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"row", height:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Catégorie
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", color:"#222222", fontSize:14}}>
                                                {this.state.breadcrumbs[2].label}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"row", height:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Lien video
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", color:"#222222", fontSize:14}}>
                                                {article.youtubeLink &&
                                                    <a href={article.youtubeLink} rel={"nofollow noreferrer"} target={"_blank"}>
                                                        <p style={{width:"100%", textAlign:"left", fontSize:12, margin:0}}>
                                                            {article.youtubeLink}
                                                        </p>
                                                    </a>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"row", minHeight:35}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Documentation
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>

                                            {article.attachments.map((att)=>{
                                                return(
                                                    <div style={{width:"100%", height:35, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                                    alignItems:"center"}} key={"att_" + att.id}>

                                                        <a href={ExposedAPI.getFullSizeAttachmentURL(att.id)} rel={"nofollow noreferrer"} target={"_blank"} download>
                                                            <div style={{width:"100%", height:35, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                                                alignItems:"center"}}>
                                                                {/*<Icon style={{color:"blue", fontSize:16, marginRight:4}}>file_copy</Icon>*/}
                                                                <p style={{flex:1,textAlign:"left", fontSize:12, margin:0}}>
                                                                    {att.original_file_name}
                                                                </p>
                                                            </div>

                                                        </a>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>


                                </div>

                                <div style={{width:"100%", borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden", backgroundColor:"#FAFAFA", marginTop:12}}>

                                    <div style={{width:"100%", display:"flex", flexDirection:"row", minHeight:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Prix HT
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center",}}>

                                            <div style={{width:"100%",  paddingLeft:8, paddingRight:8, height:35, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",}}>
                                                <p style={{width:"100%", margin:0, textAlign:"left", color:"#0068B3", fontSize:14, fontWeight:"bold", textDecoration:article.activeSaleCampaign ? "line-through" : "none"}}>
                                                    {article.basePrice + "€"}
                                                </p>
                                            </div>
                                            {article.activeSaleCampaign &&
                                                <div style={{width:"100%",  paddingLeft:8, paddingRight:8, height:35, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                                    alignItems:"center", marginTop:-12}}>
                                                    <p style={{color:"#2CBE4E", fontSize:16, width:"100%", margin:0, textAlign:"left", fontWeight:"bold"}}>
                                                        {(parseFloat(article.basePrice)*(1-parseFloat(article.activeSaleCampaign.percentage)/100)).toFixed(2)} €
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"row", height:35, borderBottom:"1px solid #DDDDDD"}}>
                                        <div style={{width:"40%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            borderRight:"1px solid #DDDDDD", paddingLeft:8}}>
                                            <span style={{color:"#222222", fontSize:14, textAlign:"left", fontWeight:"bold"}}>
                                                Conditionnement
                                            </span>
                                        </div>
                                        <div style={{width:"60%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            paddingLeft:8, paddingRight:8}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", color:"#222222", fontSize:14}}>
                                                {article.conditionnement}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", display:"flex", flexDirection:"column", padding:8}}>

                                        <input type="number" min="0" value={this.state.amountToAdd} style={{width:"100%", textAlign:"center", padding:4,
                                        fontSize:14}}
                                               onChange={(event)=>{this.onAmountToAddChanged(event.target.value)}}/>

                                        <Button variant={"contained"} size={"normal"} style={{backgroundColor:"#1faf3f", color:"#FFFFFF",
                                            marginTop:8}} onClick={(e)=>{e.stopPropagation(); this.addToCart()}}>
                                            AJOUTER AU DEVIS
                                        </Button>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </Paper>
                }

                {this.state.detailedPicture &&
                    <div style={{width:"100vw", height:"100vh", position:"fixed", zIndex:1000, left:0, top:0, bottom:0, right:0, display:"flex",
                    justifyContent:"center", alignItems:"center", backgroundColor:"rgba(0,0,0,0.6)"}} onClick={()=>{this.cancelDetailPicture()}}>

                        <img style={{height:this.state.detailedPicture.displayHeight,width:this.state.detailedPicture.displayWidth,
                        borderRadius:4, overflow:"hidden"}} alt={"detailed_picture"}
                        src={ExposedAPI.getFullSizeAttachmentURL(this.state.detailedPicture.id)}/>

                    </div>
                }

                {article && isAdmin &&
                    <Paper style={{width:"100%", padding:12, marginTop:12}}>
                        <span style={{color:"#0068B3", fontWeight:"bold", fontSize:20}}>Modification de l'article (admin)</span>

                        <p style={{width:"100%", margin:0, marginBottom:8, marginTop:30, fontSize:16, color:"#222222", fontWeight:"bold"}}>
                            {"Gestion des images"}
                        </p>
                        <div style={{width:"100%", borderRadius:4, border:"1px solid #DDDDDD", height:160, display:"flex", flexDirection:"row",
                        justifyContent:"flex-start",alignItems:"center", paddingLeft:8}}>

                            {article.pictures.map((picture)=>{

                                const isFirst = picture.firstAttachment === "1";

                                return(
                                    <div style={{width:110, height:140, border:"1px solid #DDDDDD", borderRadius:4, overflow:"hidden", display:"flex",
                                        flexDirection:"column", marginRight:8}} key={"pict_" + picture.id}>

                                        <div style={{width:110, height:110, borderBottom:"1px solid #DDDDDD", padding:4}}>

                                            <div style={{width:102, height:102, backgroundImage:"url(" + ExposedAPI.getFullSizeAttachmentURL(picture.id) + ")",
                                            borderRadius:4, border:"1px solid #EFEFEF"}}
                                                 className={"bg-image-squared"}/>

                                        </div>
                                        <div style={{width:"100%", flex:1, display:"flex", flexDirection:"row", backgroundColor:"#FAFAFA"}}>
                                            <Tooltip placement={"bottom"} title={"Définir comme image par défaut"}>
                                                <ButtonBase style={{width:"50%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center",
                                                backgroundColor:isFirst ? "#0068B3" : "auto"}}
                                                            onClick={()=>{this.setPictureFirst(picture)}}>
                                                    <Icon style={{color:isFirst ? "#FFFFFF" : "#555555", fontSize:18}}>
                                                        star
                                                    </Icon>
                                                </ButtonBase>
                                            </Tooltip>
                                            <div style={{height:"100%", width:1, backgroundColor:"#DDDDDD"}}/>
                                            <Tooltip placement={"bottom"} title={"Supprimer cette image"}>
                                                <ButtonBase style={{width:"50%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                                            onClick={()=>{this.deleteAttachment(picture)}}>
                                                    <Icon style={{color:"#555555", fontSize:18}}>
                                                        delete
                                                    </Icon>
                                                </ButtonBase>
                                            </Tooltip>
                                        </div>

                                    </div>
                                )
                            })}

                            {article.pictures.length < 3 &&
                                <>
                                    <div style={{width:110, height:140, border:"1px solid #EFEFEF", borderRadius:4, overflow:"hidden", display:"flex",
                                        flexDirection:"column", marginRight:8, backgroundColor:"#FAFAFA"}}>

                                        <ButtonBase style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center",
                                            flexDirection:"column"}} onClick={()=>{this.startAddPicture()}}>


                                            <p style={{width:"100%", color:"#797979", fontSize:14, paddingLeft:20, margin:0, paddingRight:20, textAlign:"center"}}>
                                                Ajouter une nouvelle image
                                            </p>
                                            <Icon style={{color:"#797979", fontSize:30, marginTop:4}}>add_circle_outline</Icon>

                                        </ButtonBase>

                                    </div>

                                    <div style={{width:110, height:140, border:"1px solid #EFEFEF", borderRadius:4, overflow:"hidden", display:"flex",
                                        flexDirection:"column", marginRight:8, backgroundColor:"#FAFAFA"}}>

                                        <ButtonBase style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center",
                                            flexDirection:"column"}} onClick={()=>{this.startQuickAddPicture()}}>


                                            <p style={{width:"100%", color:"#797979", fontSize:14, paddingLeft:20, margin:0, paddingRight:20, textAlign:"center"}}>
                                                Ajout rapide d'image par url
                                            </p>
                                            <Icon style={{color:"#797979", fontSize:30, marginTop:4}}>add_link</Icon>

                                        </ButtonBase>

                                    </div>
                                </>
                            }

                        </div>

                        <p style={{width:"100%", margin:0, marginBottom:8, marginTop:30, fontSize:16, color:"#222222", fontWeight:"bold"}}>
                            {"Gestion des documents"}
                        </p>
                        <div style={{width:"100%", borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden"}}>

                            {article.attachments.map((att)=>{
                                return(
                                    <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                    alignItems:"center", borderBottom:"1px solid #DDDDDD", paddingRight:8}} key={"att_" + att.id}>
                                        <Icon style={{color:"#555555", fontSize:16, margin:8}}>insert_drive_file</Icon>

                                        <a href={ExposedAPI.getFullSizeAttachmentURL(att.id)} rel={"nofollow noreferrer"} target={"_blank"} download>
                                            <div style={{width:"100%", height:35, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                                alignItems:"center"}}>
                                                {/*<Icon style={{color:"blue", fontSize:16, marginRight:4}}>file_copy</Icon>*/}
                                                <p style={{flex:1,textAlign:"left", fontSize:12, margin:0}}>
                                                    {att.original_file_name}
                                                </p>
                                            </div>

                                        </a>

                                        <div style={{flex:1}}/>

                                        <IconButton onClick={()=>{this.deleteAttachment(att)}}>
                                            <Icon style={{color:"#555555", fontSize:18}}>delete</Icon>
                                        </IconButton>
                                    </div>
                                )
                            })}


                            <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", backgroundColor:"#FAFAFA"}}>
                                <ButtonBase style={{width:"50%", height:40, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                                borderRight:"1px solid #DDDDDD"}}
                                            onClick={()=>{this.startAddDocument()}}>
                                    <p style={{color:"#797979", fontSize:14,  margin:0, paddingRight:4, textAlign:"center"}}>
                                        Ajouter un document
                                    </p>
                                    <Icon style={{color:"#797979", fontSize:24, marginLeft:4}}>add_circle_outline</Icon>
                                </ButtonBase>
                                <ButtonBase style={{width:"50%", height:40, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}
                                            onClick={()=>{this.startQuickAddDocument()}}>
                                    <p style={{color:"#797979", fontSize:14,  margin:0, paddingRight:4, textAlign:"center"}}>
                                        Ajout rapide par url
                                    </p>
                                    <Icon style={{color:"#797979", fontSize:24, marginLeft:4}}>add_link</Icon>
                                </ButtonBase>
                            </div>

                        </div>

                        <p style={{width:"100%", margin:0, marginBottom:8, marginTop:30, fontSize:16, color:"#222222", fontWeight:"bold"}}>
                            {"Mise en avant"}
                        </p>
                        <div style={{width:"100%", borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden"}}>

                            <div style={{width:"100%", height:52, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                            paddingLeft:8}}>
                                <p style={{color:"#222222", margin:0, textAlign:"left", fontSize:14}}>
                                    Cet article {article.featured === "1" ? "est " : "n'est "}
                                    {article.featured === "1" &&
                                        <span style={{display:"inline-block", padding:2, borderRadius:4, paddingLeft:6, paddingRight:6, color:"#FFFFFF",
                                        backgroundColor:"#0068B3", fontWeight:"bold", marginRight:4}}>
                                            MIS EN AVANT
                                        </span>
                                    }
                                    {article.featured === "0" &&
                                        <span style={{display:"inline-block", padding:4, borderRadius:4, paddingLeft:6, paddingRight:6, color:"#797979",
                                            backgroundColor:"#EFEFEF",  fontWeight:"bold", marginRight:4}}>
                                            PAS MIS EN AVANT
                                        </span>
                                    }
                                    Cliquez sur le bouton à droite pour changer le comportement
                                </p>

                                <Button variant={"outlined"} size={"small"} style={{color:"#0068B3", borderColor:"#0068B3", marginLeft:8}}
                                        onClick={()=>{this.toggleFeatured()}}>
                                    CHANGER
                                </Button>

                            </div>

                        </div>

                        <p style={{width:"100%", margin:0, marginBottom:8, marginTop:30, fontSize:16, color:"#222222", fontWeight:"bold"}}>
                            {"Lien Video"}
                        </p>
                        <div style={{width:"100%", borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden"}}>

                            <div style={{width:"100%", height:52, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                paddingLeft:8, paddingRight:8}}>

                                <InputBase style={{width:"100%", height:36, borderRadius:4, border:"1px solid #CCCCCC", overflow:"hidden", backgroundColor:"#FAFAFA",
                                    paddingLeft:8, paddingRight:8, position:"relative"}} placeholder={"Lien youtube actuel (aucun)"}
                                           onChange={(e)=>{this.setState({youtubeLinkToEdit:e.target.value})}}
                                           value={this.state.youtubeLinkToEdit}>
                                </InputBase>

                                <Button variant={"outlined"} size={"small"} style={{color:this.state.youtubeLinkToEdit.length < 10 ? "#DDDDDD" : "#0068B3",
                                    borderColor:this.state.youtubeLinkToEdit.length < 10 ? "#DDDDDD" : "#0068B3", marginLeft:8}}
                                        onClick={()=>{this.setYoutubeLink()}} disabled={this.state.youtubeLinkToEdit.length < 10}>
                                    VALIDER
                                </Button>

                                {article.youtubeLink && article.youtubeLink.length > 0 &&
                                    <IconButton onClick={()=>{this.removeYoutubeLink()}} style={{marginLeft:8}}>
                                        <Icon style={{color:"#555555", fontSize:18}}>clear</Icon>
                                    </IconButton>
                                }

                            </div>

                        </div>

                    </Paper>
                }

            </div>
        )
    }

    setPictureFirst(picture){
        AdminAPI.resetFirstAttachment(picture.id,this.state.article.id)
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    deleteAttachment(attachment){
        AdminAPI.deleteAttachment(attachment.id)
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    startAddPicture(){
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = "image/*";

        input.onchange = e => {
            var file = e.target.files[0];
            this.addFileToArticle(file);
        }

        input.click();
    }

    startQuickAddPicture(){
        navigator.clipboard.readText()
            .then((text)=>{
                return  AdminAPI.quickAddPicture(this.state.article.id, text)
            })
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })

    }

    startQuickAddDocument(){
        navigator.clipboard.readText()
            .then((text)=>{
                return  AdminAPI.quickAddPDF(this.state.article.id, text)
            })
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })

    }

    startAddDocument(){
        var input = document.createElement('input');
        input.accept = "application/pdf, application/vnd.ms-excel"
        input.type = 'file';

        input.onchange = e => {
            var file = e.target.files[0];
            this.addFileToArticle(file);
        }

        input.click();
    }

    toggleFeatured(){
        AdminAPI.toggleFeatured(this.state.article.id)
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    addFileToArticle(file:File){
        AdminAPI.addFileToArticle(this.state.article.id,file)
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    setYoutubeLink(){
        AdminAPI.putYoutubeLink(this.state.article.id,this.state.youtubeLinkToEdit)
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    removeYoutubeLink(){
        AdminAPI.putYoutubeLink(this.state.article.id,"")
            .then(()=>{
                this.loadArticle(this.state.article.id);
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

}

export default withRouter(ArticlePage);
