import {EndPointProvider} from "./EndPointProvider";
import axios from "axios";
import {db} from "../db";
import CartAPI from "./CartAPI";
import moment from "moment";

export const LOGIN_STATUS_CHANGED_EVENT_NAME = "LOGIN_STATUS_CHANGED"

export default class UserAPI {

    /**
     * throw an exception with the code in case of failure.
     * In case of success, it will store the jwt and store the user info in indexed db, then populate the global variables
     *
     * In case of successful login, this will raise a LOGIN_STATUS_CHANGED event, after everything is up to date and reachable from globals
     * @param email
     * @param password
     * @returns {Promise<void>}
     */
    static async login(email, password) {
        let payload = {
            "email": email,
            "password": password
        }
        try {
            await db.jwt.put({id: 1, jwt : (await UserAPI._post("/api/login_check", payload)).token}); //Insert or replace
            await db.storedUser.put({id: 1, user : (await UserAPI._getSecured("/api/protected/self/info"))}); //Insert or replace
            await UserAPI._loadLogStatusInGlobal();
            global.emitter.emit(LOGIN_STATUS_CHANGED_EVENT_NAME, null);
        } catch (e) {
            throw e;
        }
    }

    static async getSelf() {
        return await UserAPI._getSecured("/api/protected/self/info")
    }


    /**
     * Logs out the logged user.
     * Clears the Cart
     * @returns {Promise<void>}
     */
    static async logout() {
        await db.jwt.delete(1);
        await db.storedUser.delete(1);
        await CartAPI.resetCart();
        await UserAPI._loadLogStatusInGlobal();
        global.emitter.emit(LOGIN_STATUS_CHANGED_EVENT_NAME, null);
    }

    /**
     * Checks if a user is logged (works only after booting)
     * @returns {boolean}
     */
    static isUserLoggedIn() {
        return global.user !== undefined;
    }

    /**
     * Checks if a user is logged and is an admin (works only after booting)
     * @returns {boolean}
     */
    static isUserLoggedInAndAdmin() {
        return UserAPI.isUserLoggedIn() && JSON.parse(global.user.roles).indexOf("ROLE_ADMIN") !== -1
    }

    /**
     * This function should be loaded before anything on the app's setup.
     * @returns {Promise<void>}
     */
    static async bootUser() {
        let token = (await db.jwt.get(1))?.jwt;
        if(token === undefined) {
            await UserAPI._loadLogStatusInGlobal();  // global.jwt and global.user will be undefined
            return;
        }
        let payload = UserAPI._parseJwt(token);
        let nowUTC = Math.floor(Date.now() / 1000);
        let exp = payload.exp;
        let delta = exp - nowUTC;
        if(delta/60 < 120) {
            //Refreshing JWT since it is now outdated or will be outdated in less than 2 hours
            try {
                await db.jwt.put({id: 1, jwt : (await UserAPI._getSecured("/exposed/renew/jwt")).token}); //Insert or replace (Yes it's in /exposed and yet it needs the current JWT)
                await db.storedUser.put({id: 1, user : (await UserAPI._getSecured("/api/protected/self/info", payload))}); //Insert or replace
                await UserAPI._loadLogStatusInGlobal();
                // global.emitter.emit(LOGIN_STATUS_CHANGED_EVENT_NAME, null);
            } catch (e) {
                if(e === 403 || e === 401) {
                    await UserAPI.logout(); //We have this user logged out because its jwt is not renewable
                } else {
                    console.log(e);
                }
            }
        }
        await UserAPI._loadLogStatusInGlobal();
    }

    static async sendResetPasswordLink(username) {

    }


    static async _loadLogStatusInGlobal() {
        global.user = (await db.storedUser.get(1))?.user;
        global.jwt = (await db.storedUser.get(1))?.jwt;
    }

    static _parseJwt (token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    /**
     *
     * infos must be structured this way : {firstName: string, lastName: string, IBAN: string,
     *  billingAddress: {street: string, additionalStreet: string, city: string, zipCode: string, country: string},
     *  deliveryAddress: {street: string, additionalStreet: string, city: string, zipCode: string, country: string}}
     *
     *  addresses are used to fill the purchase order.
     * @returns {Promise<void>}
     */
    static async putPersonalInfo(infos) {
        return await UserAPI._put(`/api/protected/put/self/personal/info`, infos);
    }

    /**
     * In case of error : returns the error code
     * In case of success : return the json of the result
     * @param uri
     * @param payload
     * @param secured
     * @returns {Promise<any>}
     */
    static async _post(uri: string, payload:any, secured = false) {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(secured) {
            headers["Authorization"] = "Bearer " + (await db.jwt.get(1))?.jwt
        }

        let config = {
            method: 'post',
            url: EndPointProvider.getBaseURLWithoutEndSlash() + uri,
            headers: headers,
            data : JSON.stringify(payload)
        };

        try {
            return (await axios(config)).data
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }


    static async getMyQuotes() {
        let result = await UserAPI._getSecured(`/api/protected/my/quotes`);
        // let mapped = result.quotes.map(quote => {
        //     quote.lines = result.lines.filter(line => line.quote_id === quote.id).map(line => {
        //         console.log("here")
        //         line.brandName = line.title.split(" - ")[0];
        //         line.articleTitle = line.title.split(" - ").slice(1)?.join(" - ");
        //         return line;
        //     })
        //     return quote;
        // });
        // console.log(mapped);
        return result;
    }

    static mapQuotesResponse(res){
        let quotes = res.quotes;

        for(let quote of quotes){
            quote.lines = res.lines.filter((line)=>{return line.quote_id === quote.id + ""})
        }

        for(let quote of quotes){
            let totalQuantity = 0;
            let totalHTPrice = 0;

            for(let line of quote.lines){
                totalQuantity = totalQuantity + parseInt(line.quantity);
                totalHTPrice = totalHTPrice + parseFloat(line.unit_price)*parseInt(line.quantity);

                line.brandName = line.title.split(" - ")[0];
                line.articleTitle = line.title.split(" - ").slice(1)?.join(" - ").substring(0,130)
            }

            quote.pretty_creation_date_time = moment(quote.creation_date_time).format("DD/MM/YYYY")
            quote.total_quantity = totalQuantity;
            quote.total_ht_price = totalHTPrice.toFixed(2);

            quote.sort_number = moment(quote.creation_date_time).format("YYYYMM-")+quote.id;

            switch(quote.pipe_status){
                case "PENDING":{
                    quote.pretty_pipe_status = "EN ATTENTE";
                    quote.pretty_pipe_status_color = "#CCCCCC";
                    quote.sort_status = 1;
                    break;
                }
                case "ON_GOING":{
                    quote.pretty_pipe_status = "EN COURS";
                    quote.pretty_pipe_status_color = "#0068B3";
                    quote.sort_status = 2;
                    break;
                }
                case "ARCHIVED":{
                    quote.pretty_pipe_status = "ARCHIVÉ";
                    quote.pretty_pipe_status_color = "#2CBE4E";
                    quote.sort_status = 3;
                    break;
                }
            }
        }

        return quotes;
    }

    /**
     * Get what's at uri.
     * Returns json content in case of success
     * throw error code in case of failure
     * @param uri
     * @returns {Promise<any>}
     */
    static async _getSecured(uri) {
        let config = {
            method: 'get',
            url: EndPointProvider.getBaseURLWithoutEndSlash() + uri,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await db.jwt.get(1))?.jwt
            }
        };
        try {
            return (await axios(config)).data
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }

    /**
     * Get what's at uri.
     * Returns json content in case of success
     * throw error code in case of failure
     * @param uri
     * @returns {Promise<any>}
     */
    static async _delete(uri) {
        let config = {
            method: 'delete',
            url: EndPointProvider.getBaseURLWithoutEndSlash() + uri,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await db.jwt.get(1))?.jwt
            }
        };
        try {
            return (await axios(config)).data
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }

    /**
     * Get what's at uri.
     * Returns json content in case of success
     * throw error code in case of failure
     * @param uri
     * @returns {Promise<any>}
     */
    static async _put(uri, payload) {
        let config = {
            method: 'put',
            url: EndPointProvider.getBaseURLWithoutEndSlash() + uri,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await db.jwt.get(1))?.jwt
            },
            data : JSON.stringify(payload)
        };
        try {
            return (await axios(config)).data
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }



}
