import React from "react";
import './PhoneNumber.css';
import {Icon} from "@mui/material";

export default class PhoneNumber extends React.PureComponent{

    render(){
        return (
            <a href={"tel:+33155581515"} rel={"noreferrer nofollow"} style={{textDecoration:"none"}}>
                <div style={{display:"flex", flexDirection:"row",  borderRadius:24, backgroundColor:"#FFFFFF", border:"1px solid #FF8900",
                    justifyContent:"center", alignItems:"center"}}>
                    <div style={{width:24, height:24, backgroundColor:"#FF8900", display:"flex", justifyContent:"center",
                        alignItems:"center", borderRadius:"50%"}}>
                        <Icon style={{fontSize:18, color:"#FFFFFF"}}>phone</Icon>
                    </div>
                    <p style={{color:"#FF8900", fontSize:16, textAlign:"left", margin:0, paddingLeft:8, paddingRight:10, fontWeight:"bold"}}>
                        +33(0)1 55 58 15 15
                    </p>
                </div>
            </a>

        );
    }

}

