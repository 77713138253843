import React from "react";
import './MyQuotesPage.css';
import {withRouter} from "../../../withRouter";
import {LinearProgress, Paper} from "@mui/material";
import QuoteList from "../../../components/quote/QuoteList/QuoteList";
import UserAPI from "../../../apis/UserAPI";
import moment from "moment";

class MyQuotesPage extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            quotes:[],
            loadingQuotes:false,
        }
    }

    componentDidMount() {
        this.getMyQuotes();
    }

    getMyQuotes(){
        this.setState({loadingQuotes:true})
        UserAPI.getMyQuotes()
            .then((res)=>{

                let quotes = UserAPI.mapQuotesResponse(res);

                this.setState({
                    loadingQuotes:false,
                    quotes:quotes,
                })
            })
            .catch((err)=>{
                console.log(err)
                this.setState({
                    loadingQuotes:false
                })
            })
    }

    render(){
        return(
            <div style={{width:"100%", position:"relative"}}>
                {this.state.loadingQuotes && <LinearProgress style={{position:"absolute", height:5, color:"#0068B3", width:"100%", left:0, top:0, right:0}}/>}
                <QuoteList
                    quotes={this.state.quotes}
                    virtualized={true}
                    listHeight={this.state.quotes.length >= 10 ? 1000 : this.state.quotes.length*100}
                />
            </div>
        )
    }
}

export default withRouter(MyQuotesPage);
