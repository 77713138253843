import React from "react";
import './MyCartButton.css';
import {Link} from "react-router-dom"
import {Badge, Button} from "@mui/material";
import {FiberNew, ShoppingCart} from "@mui/icons-material";
import CartAPI from "../../../apis/CartAPI";

export default class MyCartButton extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            cartSummary:null,
        }
    }

    _CART_UPDATESubscription;

    componentDidMount(){
        this.getCartSummary();
        this._CART_UPDATESubscription = global.emitter.addListener("CART_UPDATE",()=>{
            this.getCartSummary();
        })
    }

    componentWillUnmount() {
        if(this._CART_UPDATESubscription){this._CART_UPDATESubscription.remove()}
    }

    getCartSummary(){
        CartAPI.getCartSummary()
            .then((cartSummary)=>{
                this.setState({
                    cartSummary:cartSummary,
                })
            })
            .catch((err)=>{
                console.log(err)
            })
    }

    render(){
        return(
            <Link to={"/cart"} style={{textDecoration:"none"}}>
                <Button size={"medium"} variant={"outlined"} style={{color:"#FFFFFF", border:"1px solid #FFFFFF", backgroundColor:"rgba(255,255,255,0)",
                width:190}}>
                    <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                           overlap={"rectangular"}
                           invisible={!this.state.cartSummary ||  this.state.cartSummary.distinct_items_count === 0}
                           badgeContent={this.state.cartSummary ? this.state.cartSummary.distinct_items_count : 0}
                           color="success" sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 } }}>
                        <ShoppingCart style={{color:"#FFFFFF", fontSize:18}}/>
                    </Badge>
                    <div style={{marginLeft: 20}}>Mon devis</div>
                </Button>
            </Link>
        )
    }
}
