import React from "react";
import "./UnderSubCategoryPage.css";
import {withRouter} from "../../withRouter";
import {ExposedAPI} from "../../apis/ExposedAPI";
import {Link} from "react-router-dom";
import {Icon, LinearProgress} from "@mui/material";
import ArticleList from "../../components/article/articleList/ArticleList";

class UnderSubCategoryPage extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            breadcrumbs:[],
            articles:[],
        }
    }

    componentDidMount(){
        this.loadArticles(this.props.router.params.undersubcategory);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadArticles(nextProps.router.params.undersubcategory);
    }

    loadArticles(underSubCategoryId){

        this.setState({
            breadcrumbs:[],
            articles:[],
        })

        let promises = [
            ExposedAPI.getDistantOrStoredCategories(),
            ExposedAPI.getUnderSubCategoryArticles(underSubCategoryId),
        ]

        Promise.all(promises)
            .then((results)=>{

                let articles = results[1];

                for(let article of articles){
                    if(article.description.length > 180){article.short_description = article.description.substring(0,180) + "..."}
                    else{article.short_description = article.description}

                    article.sort_name = article.label.toUpperCase();
                    article.sort_brand = article.brandName ? article.brandName.toUpperCase() : "";
                    article.sort_ref = article.crmId.toUpperCase();
                    article.sort_cond = article.conditionnement ? article.conditionnement.toUpperCase() : "";
                    article.sort_ht = parseFloat(article.basePrice);
                }

                this.setState({
                    breadcrumbs:this.getUnderSubCategoryBreadcrumbs(results[0],underSubCategoryId),
                    articles:articles,
                })
            })
            .catch((e)=>{console.log(e)})
    }

    getUnderSubCategoryBreadcrumbs(categories, uscId){

        let matchCategory = null;
        let matchSubCategories = null;
        let matchUnderSubCategories = null;

        loop:for(let category of categories){
            for(let subCategory of category.subCategories){
                for(let underSubCategory of subCategory.underSubCategories){
                    if(underSubCategory.id + "" === uscId + ""){
                        matchCategory = category;
                        matchSubCategories = subCategory;
                        matchUnderSubCategories = underSubCategory;
                        break loop;
                    }
                }
            }
        }

        return [
            {
                id:"c_" + matchCategory.id,
                label:matchCategory.label,
                link:"/c/" + matchCategory.id
            },
            {
                id:"sc_" + matchSubCategories.id,
                label:matchSubCategories.label,
                link:"/sc/" + matchSubCategories.id
            },
            {
                id:"usc_" + matchUnderSubCategories.id,
                label:matchUnderSubCategories.label,
                link:"/usc/" + matchUnderSubCategories.id
            }
        ]
    }

    render(){
        return(
            <div style={{width:"100%", position:"relative"}}>
                {this.state.breadcrumbs.length === 0 && <LinearProgress style={{width:"100%", position:"absolute", left:0, top:0, height:5, color:"#0068B3"}}/>}
                <div style={{width:"100%", minHeight:54, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", flexWrap:"wrap",
                    paddingLeft:6, paddingRight:12}}>
                    {this.state.breadcrumbs.map((bc,index)=>{
                        return(
                            <div key={"bc_" + bc.id} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", marginLeft:6}}>

                                <Link style={{textDecoration:"none", paddingBottom:2}} to={bc.link}>
                                    <span style={{color:"#222222", fontSize:14}} className={"breadcrumb-link"}>{bc.label}</span>
                                </Link>
                                {index !== this.state.breadcrumbs.length - 1 &&
                                    <Icon style={{fontSize:18, marginLeft:6}}>{"keyboard_arrow_right"}</Icon>
                                }
                            </div>
                        )
                    })}
                </div>
                <ArticleList articles={this.state.articles}/>
            </div>
        )
    }
}

export default withRouter(UnderSubCategoryPage)
