import React from "react";
import "./SubCategoryPage.css";
import {ExposedAPI} from "../../apis/ExposedAPI";
import {withRouter} from "../../withRouter";
import {Icon, LinearProgress,Paper} from "@mui/material";
import {Link} from "react-router-dom";
import ArticleList from "../../components/article/articleList/ArticleList";

class SubCategoryPage extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            breadcrumbs:[],
            underSubCategories:[],
            articles:[],
        }
    }

    componentDidMount(){
        this.loadArticles(this.props.router.params.subcategory);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadArticles(nextProps.router.params.subcategory);
    }

    loadArticles(subCategoryId){

        this.setState({
            breadcrumbs:[],
            articles:[],
            underSubCategories:[],
        })

        let promises = [
            ExposedAPI.getDistantOrStoredCategories(),
            ExposedAPI.getSubCategoryArticles(subCategoryId),
        ]

        Promise.all(promises)
            .then((results)=>{

                let articles = results[1];
                let underSubCategories = [];

                loop:for(let c of results[0]){
                    for(let sc of c.subCategories){
                        if(sc.id + "" === subCategoryId + ""){
                            underSubCategories = sc.underSubCategories;
                            break loop;
                        }
                    }
                }

                for(let article of articles){
                    if(article.description.length > 180){article.short_description = article.description.substring(0,180) + "..."}
                    else{article.short_description = article.description}

                    article.sort_name = article.label.toUpperCase();
                    article.sort_brand = article.brandName ? article.brandName.toUpperCase() : "";
                    article.sort_ref = article.crmId.toUpperCase();
                    article.sort_cond = article.conditionnement ? article.conditionnement.toUpperCase() : "";
                    article.sort_ht = parseFloat(article.basePrice);
                }

                this.setState({
                    breadcrumbs:this.getSubCategoryBreadcrumbs(results[0],subCategoryId),
                    underSubCategories:underSubCategories.sort((a,b)=>{return a.label < b.label ? -1 : 1}),
                    articles:articles,
                })
            })
            .catch((e)=>{console.log(e)})
    }

    getSubCategoryBreadcrumbs(categories, scId){

        let matchCategory = null;
        let matchSubCategories = null;

        loop:for(let category of categories){
            for(let subCategory of category.subCategories){
                if(subCategory.id + "" === scId + ""){
                    matchCategory = category;
                    matchSubCategories = subCategory;
                    break loop;
                }
            }
        }

        return [
            {
                id:"c_" + matchCategory.id,
                label:matchCategory.label,
                link:"/c/" + matchCategory.id
            },
            {
                id:"sc_" + matchSubCategories.id,
                label:matchSubCategories.label,
                link:"/sc/" + matchSubCategories.id
            },
        ]
    }

    render(){
        return(
            <div style={{width:"100%", position:"relative"}}>
                {this.state.breadcrumbs.length === 0 && <LinearProgress style={{width:"100%", position:"absolute", left:0, top:0, height:5, color:"#0068B3"}}/>}
                <div style={{width:"100%", minHeight:54, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", flexWrap:"wrap",
                    paddingLeft:6, paddingRight:12}}>
                    {this.state.breadcrumbs.map((bc,index)=>{
                        return(
                            <div key={"bc_" + bc.id} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", marginLeft:6}}>

                                <Link style={{textDecoration:"none", paddingBottom:2}} to={bc.link}>
                                    <span style={{color:"#222222", fontSize:14}} className={"breadcrumb-link"}>{bc.label}</span>
                                </Link>
                                {index !== this.state.breadcrumbs.length - 1 &&
                                    <Icon style={{fontSize:18, marginLeft:6}}>{"keyboard_arrow_right"}</Icon>
                                }
                            </div>
                        )
                    })}
                </div>

                {this.state.underSubCategories.length > 0 &&
                    <Paper style={{width:"100%", marginBottom:12, minHeight:44, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                        flexWrap:"wrap"}}>
                        {this.state.underSubCategories.map((usc)=>{
                            return(
                                <Link to={"/usc/" + usc.id} style={{textDecoration:"none"}}>
                                    <div key={"usc_" + usc.id} style={{height:44, paddingLeft:12, paddingRight:12, display:"flex", flexDirection:"row", justifyContent:"center",
                                        alignItems:"center"}} className={"usc-hover-link"}>
                                        <p style={{color:"#222222", fontSize:14, margin:0, textAlign:"center"}}>
                                            {usc.label}
                                        </p>
                                    </div>
                                </Link>
                            )
                        })}
                    </Paper>
                }


                <ArticleList articles={this.state.articles}/>
            </div>
        )
    }

}

export default withRouter(SubCategoryPage)
