import React from "react";
import './Footer.css';
import {Link} from "react-router-dom"
import moment from "moment";
import btx_square from "../../images/logos/logo_btx_small.jpg";
import fortitude from "../../images/logos/4titude_france.png";
import PhoneNumber from "../shared/phoneNumber/PhoneNumber";

export default class Footerr extends React.Component{

    constructor(props) {
        super(props);
        this.state={

        }
    }

    render(){
        return (
            <div className={"footer-wrapper"}>

                <div className={"centerer-wrapper"}>
                    <div className={"centerer"}>
                        <div className={"footer-logos-wrapper"}>
                            <img alt={"___"} style={{height:60, marginRight:20, borderRadius:4}} src={btx_square}/>
                            <img alt={"___"} style={{height:60, borderRadius:4}} src={fortitude}/>
                        </div>

                        <div className={"footer-sitemap-wrapper"}>

                            {/*FIRST*/}
                            <div className={"footer-sitemap-column"}>
                                <span style={{color:"#EFEFEF", fontSize:16, textAlign:"left", marginBottom:15}}>
                                    Sur le site
                                </span>
                                <Link className={"footer-link"} to={"/home"}>
                                    <span style={{color:"#CCCCCC", fontSize:14, textAlign:"left", display:"inline-block", marginBottom:10}}>
                                        Accueil
                                    </span>
                                </Link>
                                <Link className={"footer-link"} to={"/my/account"}>
                                    <span style={{color:"#CCCCCC", fontSize:14, textAlign:"left", display:"inline-block", marginBottom:10}}>
                                        Espace client
                                    </span>
                                </Link>
                                <Link className={"footer-link"} to={"/quote"}>
                                    <span style={{color:"#CCCCCC", fontSize:14, textAlign:"left", display:"inline-block"}}>
                                        Ma demande de devis
                                    </span>
                                </Link>
                            </div>

                            {/*SECOND*/}
                            <div className={"footer-sitemap-column"}>
                                <span style={{color:"#EFEFEF", fontSize:16, textAlign:"left", marginBottom:15}}>
                                    Autour de Bio-Technofix
                                </span>
                                <Link className={"footer-link"} to={"/about"}>
                                    <span style={{color:"#CCCCCC", fontSize:14, textAlign:"left", display:"inline-block", marginBottom:10}}>
                                        Qui sommes-nous ?
                                    </span>
                                </Link>
                                <Link className={"footer-link"} to={"/terms"}>
                                    <span style={{color:"#CCCCCC", fontSize:14, textAlign:"left", display:"inline-block"}}>
                                        Conditions de vente
                                    </span>
                                </Link>
                            </div>

                            {/*LAST*/}
                            <div className={"footer-sitemap-column"}>
                                <span style={{color:"#EFEFEF", fontSize:16, textAlign:"left", marginBottom:15}}>
                                    Assistance
                                </span>
                                <Link className={"footer-link"} to={"/contact/us"}>
                                    <span style={{color:"#CCCCCC", fontSize:14, textAlign:"left", display:"inline-block", marginBottom:15}}>
                                        Contact
                                    </span>
                                </Link>

                                <PhoneNumber/>
                            </div>

                        </div>

                        <p className={"footer-copyright"}>Bio-Technofix {moment().format("YYYY")}. Tous droits réservés.</p>

                    </div>
                </div>


            </div>
        );
    }

}

