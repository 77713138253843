// db.js
import Dexie from 'dexie';

export const db = new Dexie('btx_cart_' + process.env.NODE_ENV);

db.version(3).stores({
    articleInCart: '++id', // id, under_sub_category_id, discount_id, coupon_code_id, crmId, label, conditionnement, description, brandName, youtubeLink, basePrice, ttcPrice, ecoTax, VAT, featured at least
    summary: '++id',
    appliedCoupon: '++id',
    storedUser: '++id',
    jwt: "++id"
});

// export const
