/**
 * This API uses Dexie to provide a cart and manage things when getting loggued
 *  How to use the cart :
 *   - add an article to the cart : addArticleToCart(article, amount) (Other functions : removeArticleFromCart(article_id), setArticleQuantity(article_id, amount))
 *   - get a summary of what the cart has in it : getCartSummary()
 *   - get the articles of the cart : getCartContent()
 *   - reset the Cart : resetCart()
 *
 *
 */
import {db} from "../db";


export default class CartAPI {

    /**
     * Adds an article to a cart. If item is already present, amount will be added with previous amount value.
     * @param article
     * @param amount
     * @returns {Promise<void>}
     */
    static async addArticleToCart(article, amount) {
        if(amount < 1) {
            throw new Error("Cannot add a negative or null amount of articles in a cart. Use setArticleQuantity or removeArticleFromCart functions instead.");
        }
        if(!CartAPI.isInteger(amount)){
            throw new Error("Amount must be an integer");
        }
        let previous = await CartAPI.getArticleInCartOrUndefined(article.id);
        if(previous === undefined) {
            await db.articleInCart.add({id: article.id, article: article, quantity: amount});
        } else {
            previous.quantity = previous.quantity + amount;
            await db.articleInCart.update(article.id, previous)
        }
        await CartAPI._reComputeCartMetaAndNotify();
    }

    static async removeArticleFromCart(article_id) {
        await db.articleInCart.delete(article_id);
        await CartAPI._reComputeCartMetaAndNotify();
    }

    static async setArticleQuantity(article_id, amount) {
        if(amount < 1) {
            throw new Error("Cannot set a negative or null amount of articles in a cart. Use removeArticleFromCart function instead.");
        }
        let previous = await CartAPI.getArticleInCartOrUndefined(article_id);
        if(previous === undefined) {
            throw new Error("Article is not present in cart.");
        }
        previous.quantity = amount;
        await db.articleInCart.update(article_id, previous)
        await CartAPI._reComputeCartMetaAndNotify();
    }

    /**
     * {
     *     price: number, # without VAT
     *     distinct_items_count: number,
     *     total_item_count: number,
     *     last_validation: last_validation,
     * }
     * @returns {Promise<void>}
     */
    static async getCartSummary() {
        await CartAPI.removeSoftDeletedArticlesFromCart();
        await CartAPI._instantiateCartSummaryIfMissing();
        let result = await db.summary.get(1);
        return result
    }
    /**
     * {
     *     Get the Cart content
     * }
     * @returns {Promise<void>}
     */
    static async getCartContent() {
        await CartAPI.removeSoftDeletedArticlesFromCart();
        await CartAPI._instantiateCartSummaryIfMissing();
        let result = await db.articleInCart.toArray();
        return result
    }

    /**
     * Removes the soft deleted articles from cart content (if any)
     * @return {Promise<void>}
     */
    static async removeSoftDeletedArticlesFromCart(){
        let cartContent = await db.articleInCart.toArray();
        for(let item of cartContent){
            if(item.article.soft_deleted === "1"){
                await CartAPI.removeArticleFromCart(item.article.id)
            }
        }
    }

    /**
     * Will empty the cart if needed
     * @returns {Promise<void>}
     */
    static async resetCart() {
        await db.articleInCart.clear();
        await db.summary.clear();
        await db.appliedCoupon.clear();
        await CartAPI._notifyUpdate();
    }

    /**
     * Distant sync when a user logs in, empty cart when user logs out
     * @returns {Promise<void>}
     */
    static async onLoginStatusChanged() {
        console.log("CartAPI: onLoginStatusChanged triggered");
        if(global.jwt === undefined) {
            await CartAPI.resetCart();
        }
    }


    /**
     * Returns a stored article or null if empty
     * @param article_id
     * @returns {Promise<*|null>}
     */
    static async getArticleInCartOrUndefined(article_id) {
        return await db.articleInCart.get(article_id);
    }

    static async _instantiateCartSummaryIfMissing() {
        let summary = await db.summary.where("id").equals(1).toArray();
        if(summary.length === 0) {
            db.summary.add({id: 1, total_price: 0, distinct_items_count: 0, total_items_count: 0, last_validation: 0, install_id: "device_" + Math.random().toString(16).slice(2)})
        }
    }

    static async _notifyUpdate() {
        global.emitter.emit("CART_UPDATE", null);
    }

    static async _reComputeCartMetaAndNotify() {
        await CartAPI._instantiateCartSummaryIfMissing();
        let articles = await db.articleInCart.toArray();
        let totalPrice = 0;
        let distinctItemsCount = 0;
        let totalItemsCount = 0;
        let lastValidation = 0;
        for(let item of articles) {
            totalPrice = totalPrice + (+item.article.basePrice)* (+item.quantity);
            distinctItemsCount++;
            totalItemsCount = totalItemsCount + item.quantity;
        }

        await db.summary.update(1, {total_price: totalPrice, distinct_items_count: distinctItemsCount, total_items_count: totalItemsCount, last_validation: lastValidation});
        await CartAPI._notifyUpdate();
    }

    static isInteger(number) {
        return (/^\+?(0|[1-9]\d*)$/.test(number+"") && number <= 9999)
    }

}

