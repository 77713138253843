import React, {useEffect} from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate, useHistory, useNavigate, useLocation} from "react-router-dom";
import Header from "./components/header/Header";
import Footerr from "./components/footer/Footerr";
import {ExposedAPI} from "./apis/ExposedAPI";
import Menu from "./components/menu/Menu";
import {EventEmitter} from "fbemitter";
import CartAPI from "./apis/CartAPI";
import UserAPI from "./apis/UserAPI";
import HomePage from "./pages/home/HomePage";
import TermsPage from "./pages/terms/TermsPage";
import ArticlePage from "./pages/article/ArticlePage";
import SubCategoryPage from "./pages/subCategory/SubCategoryPage";
import CategoryPage from "./pages/category/CategoryPage";
import UnderSubCategoryPage from "./pages/underSubCategory/UnderSubCategoryPage";
import ContactUsPage from "./pages/contactUs/ContactUsPage";
import {AdministrationMenuPage} from "./pages/administration/AdministrationMenuPage";
import AboutPage from "./pages/about/AboutPage";
import SnackBar from "./components/snackbar/SnackBar";
import CartPage from "./pages/cart/CartPage";
import {LoginPage} from "./pages/login/LoginPage";
import MyAccountPage from "./pages/myAccount/MyAccountPage";
import ArticleEditPopup from "./components/article/editPopup/ArticleEditPopup";
import {AccountInfoPage} from "./pages/myAccount/accountInfo/AccountInfoPage";
import ScrollTop from "./components/scrollTop/ScrollTop";
import {SalesCampaignsPage} from "./pages/administration/overview/salesCampaigns/SalesCampaignsPage";
import {AdminDashboardPage} from "./pages/administration/overview/dashboard/AdminDashboardPage";
import {UsersManagementPage} from "./pages/administration/overview/usersManagement/UsersManagementPage";
import {OxygenePage} from "./pages/administration/overview/oxygene/OxygenePage";
import CartValidatePage from "./pages/cartValidate/CartValidatePage";
import MyQuotesPage from "./pages/myAccount/quotes/MyQuotesPage";
import {RecoverPasswordPage} from "./pages/login/RecoverPasswordPage";
import QuotesPendingPage from "./pages/administration/overview/quotes/pending/QuotesPendingPage";
import QuotesOpenedPage from "./pages/administration/overview/quotes/opened/QuotesOpenedPage";
import QuotesHistoryPage from "./pages/administration/overview/quotes/history/QuotesHistoryPage";

function LocationWatcher() {

    // const loc = useLocation();

    useEffect(_ => {
        // console.log(loc);
    })


    return <div></div>;

}

export default class App extends React.Component{

    constructor(props) {
        super(props);


        this.state={

        }
        this.init();
    }

    init() {
        global.emitter = new EventEmitter();

        global.onArticleRightClicked = function(browserEvent,article){
            browserEvent.preventDefault();
            if(UserAPI.isUserLoggedInAndAdmin()){
                global.emitter.emit("displayArticleEditPopup",{
                    browserEvent:browserEvent,
                    article:article,
                })
            }
        }
    }

    async componentDidMount() {
        await this.ericTests();
    }



    render(){
        return (
            <Router>
                <LocationWatcher/>
                <div className={"app"}>
                    <header className={"app-header-wrapper"}>
                        <Header/>
                    </header>
                    <div className={"app-content-wrapper"}>
                        <div className={"centerer-wrapper"}>
                            <div className={"centerer"}>
                                <Menu/>
                                <Routes>
                                    <Route index path={"/home"} element={ <HomePage/>}/>

                                    <Route path={"/c/:category"} element={ <CategoryPage/>}/>
                                    <Route path={"/sc/:subcategory"} element={ <SubCategoryPage/>}/>
                                    <Route path={"/usc/:undersubcategory"} element={ <UnderSubCategoryPage/>}/>
                                    <Route path={"/a/:article"} element={ <ArticlePage/>}/>

                                    <Route path={"/terms"} element={ <TermsPage/>}/>
                                    <Route path={"/about"} element={ <AboutPage/>}/>
                                    <Route path={"/cart"} element={ <CartPage/>}/>
                                    <Route path={"/cart/validate"} element={ <CartValidatePage/>}/>

                                    <Route path={"/login"} exact={true} element={<LoginPage/>}/>
                                    <Route path={"/credentials/recover/:recoverToken/:userId"} element={<RecoverPasswordPage/>}/>

                                    <Route path={"/admin"} element={ <AdministrationMenuPage/>}>
                                        <Route path={"quotes"}>
                                            <Route path={"pending"} element={<QuotesPendingPage/>}/>
                                            <Route path={"ongoing"} element={<QuotesOpenedPage/>}/>
                                            <Route path={"archived"} element={<QuotesHistoryPage/>}/>
                                        </Route>
                                        <Route path={"sales/campaigns"} element={ <SalesCampaignsPage/>}/>
                                        <Route path={"users/management"} element={ <UsersManagementPage/>}/>
                                        <Route path={"oxygene"} element={ <OxygenePage/>}/>

                                    </Route>
                                    <Route path={"/my/account"} element={<MyAccountPage/>}>
                                        <Route path={"infos"} element={<AccountInfoPage/>}/>
                                        <Route path={"quotes"} element={<MyQuotesPage/>}/>
                                    </Route>
                                    <Route index path={"/contact/us"} element={ <ContactUsPage/>}/>
                                    <Route path={"/"} element={<Navigate replace to={"/home"}/>}/>
                                </Routes>
                            </div>
                        </div>
                    </div>
                    <footer className={"app-footer-wrapper"}>
                        <Footerr/>
                    </footer>
                    <SnackBar/>
                    <ArticleEditPopup/>
                    <ScrollTop/>
                </div>
            </Router>
        );
    }


    async ericTests() {
        // console.log(await UserAPI.login("test@test.com", "test"));       //This make the whole logging process
        // console.log(await UserAPI.logout());
        // console.log(await UserAPI.refreshTokenIfOutdated());

        // console.log(await ExposedAPI.getFeaturedArticles());
        // console.log(await ExposedAPI.getUnderSubCategoryArticles(1));
        // console.log(await CartAPI.getCartSummary());


        // await CartAPI.resetCart();
        // let articles = await ExposedAPI.getUnderSubCategoryArticles(1);
        // for(let item of articles.slice(0,10)) {
        //     await CartAPI.addArticleToCart(item, 2);
        // }
        // console.log(await CartAPI.getCartSummary());


        // await CartAPI.addArticleToCart({id: 10, label: })
    }
}

