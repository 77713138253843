import React from "react";
import "./CategoryPage.css";
import {ExposedAPI} from "../../apis/ExposedAPI";
import {Icon, LinearProgress, Paper} from "@mui/material"
import {withRouter} from "../../withRouter";
import {Link} from "react-router-dom";
import {CategoriesAccordion} from "../../components/menu/Menu";

import fortitude from "../../images/sectionHeaders/4titude.jpg";
import appareillage from "../../images/sectionHeaders/appareillage.jpg";
import hygsec from "../../images/sectionHeaders/hygiene_securite.jpg";
import mesures from "../../images/sectionHeaders/mesures_physiques.jpg";
import mobilier from "../../images/sectionHeaders/mobilier.jpg";
import verrerie from "../../images/sectionHeaders/verrerie.jpg";
import consommables from "../../images/sectionHeaders/consommables.jpg";
import glassware from "../../images/sectionHeaders/glassware_reduced.jpg";

class CategoryPage extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            breadcrumbs:[],
            category:null,
        }
    }

    componentDidMount(){
        this.loadCategories(this.props.router.params.category);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadCategories(nextProps.router.params.category);
    }

    loadCategories(categoryId){
        this.setState({breadcrumbs:[],category:null})
        ExposedAPI.getDistantOrStoredCategories()
            .then((categories)=>{
                let category = categories.find((cat)=>{return cat.id + "" === categoryId});
                let breadCrumbs = this.getCategoryBreadcrumbs(categories,categoryId);
                this.setState({
                    category:category,
                    breadcrumbs:breadCrumbs,
                })
            })
            .catch((err)=>{console.log(err)})
    }

    getCategoryBreadcrumbs(categories, categoryId){
        let category = categories.find((cat)=>{return cat.id + "" === categoryId})
        return [{
            id:"c_" + category.id,
            label:category.label,
            link:"/c/" + category.id
        }]
    }

    render(){
        return(
            <div style={{width:"100%", position:"relative"}}>
                {this.state.breadcrumbs.length === 0 && <LinearProgress style={{width:"100%", position:"absolute", left:0, top:0, height:5, color:"#0068B3"}}/>}
                <div style={{width:"100%", minHeight:54, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", flexWrap:"wrap",
                paddingLeft:6, paddingRight:12}}>
                    {this.state.breadcrumbs.map((bc,index)=>{
                        return(
                            <div key={"bc_" + bc.id} style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", marginLeft:6}}>

                                <Link style={{textDecoration:"none", paddingBottom:2}} to={bc.link}>
                                    <span style={{color:"#222222", fontSize:14}} className={"breadcrumb-link"}>{bc.label}</span>
                                </Link>

                                {index !== this.state.breadcrumbs.length - 1 &&
                                    <Icon style={{fontSize:18, marginLeft:6}}>{"keyboard_arrow_right"}</Icon>
                                }

                            </div>
                        )
                    })}
                </div>
                {this.state.category &&
                    <div className={"category-content-wrapper"}>
                        <Paper className={"category-content-paper"}>
                            <CategoriesAccordion
                                mode={"category"}
                                categories={[this.state.category]}/>
                        </Paper>
                        <Paper className={"category-content-image"} style={{backgroundImage:"url(" + this.getImage() + ")"}}/>
                    </div>
                }
            </div>
        )
    }

    getImage(){
        switch(this.state.category.id){
            case "2": return fortitude;
            case "3": return appareillage;
            case "4": return  hygsec;
            case "5": return  verrerie;
            case "6": return  mesures;
            case "7": return  mobilier;
            case "8": return  glassware;
            case "9": return  consommables;
        }
    }
}

export default withRouter(CategoryPage)
