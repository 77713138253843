import React from "react";
import './QuoteList.css';
import {Icon, IconButton, Paper, Select, MenuItem} from "@mui/material";
import VirtualList from 'react-tiny-virtual-list';
import ArticleRow from "../../article/ArticleRow";
import QuoteRow from "../QuoteRow/QuoteRow";

export default class QuoteList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortBy: "NUMBER",
            sortDirection: false,
            quotes: this.getSortedQuotes(this.props.quotes, "NAME", true),
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            quotes: this.getSortedQuotes(nextProps.quotes, this.state.sortBy, this.state.sortDirection)
        })
    }

    getSortedQuotes(quotes,sortBy,sortDirection){

        let as = sortDirection ? -1 : 1;
        let bs = sortDirection ? 1 : -1;

        switch(sortBy){
            case "NUMBER":
                quotes = quotes.sort((a,b)=>{return a.sort_number < b.sort_number ? as : bs})
                break;
            case "DATE":
                quotes = quotes.sort((a,b)=>{return a.creation_date_time < b.creation_date_time ? as : bs})
                break;
            case "STATUS":
                quotes = quotes.sort((a,b)=>{return a.sort_status < b.sort_status ? as : bs})
                break;
            case "ARTICLES":
                quotes = quotes.sort((a,b)=>{return a.total_quantity < b.total_quantity ? as : bs})
                break;
            case "HT":
                quotes = quotes.sort((a,b)=>{return parseFloat(a.total_ht_price) < parseFloat(b.total_ht_price) ? as : bs})
                break;
        }

        return quotes
    }

    toggleSortDirection(){
        let newSortBy = this.state.sortBy;
        let newSortDirection = !this.state.sortDirection;

        let quotes = this.getSortedQuotes(this.state.quotes,newSortBy,newSortDirection)

        this.setState({
            sortBy:newSortBy,
            sortDirection:newSortDirection,
            quotes:quotes,
        })
    }

    setSortBy(sortBy){
        let newSortBy = sortBy;
        let newSortDirection = this.state.sortDirection;

        let quotes = this.getSortedQuotes(this.state.quotes,newSortBy,newSortDirection)

        this.setState({
            sortBy:newSortBy,
            sortDirection:newSortDirection,
            quotes:quotes,
        })
    }

    toggleSort(sortBy){

        let newSortBy = sortBy;
        let newSortDirection = this.state.sortDirection;

        if(this.state.sortBy === sortBy){
            newSortDirection = !newSortDirection
        }

        let quotes = this.getSortedQuotes(this.state.quotes,newSortBy,newSortDirection)

        this.setState({
            sortBy:newSortBy,
            sortDirection:newSortDirection,
            quotes:quotes,
        })
    }

    render(){
        return(
            <div style={{width:"100%"}}>

                <Paper className={"quotes-card-header"} style={{width:"100%", flexDirection:"row", overflow:"hidden", border:"1px solid #DDDDDD",
                    backgroundColor:"#EFEFEF", height:50, marginBottom:12, justifyContent:"flex-start", alignItems:'center', paddingLeft:8, paddingRight:8}}>

                    <span style={{fontSize:12, color:"#797979"}}>Trier par:</span>

                    <Select
                        value={this.state.sortBy}
                        onChange={(e)=>{this.setSortBy(e.target.value)}}
                        variant={"outlined"}
                        size={"small"}
                        style={{backgroundColor:"#FAFAFA", marginLeft:6}}
                    >
                        <MenuItem value={"NUMBER"}>Numéro</MenuItem>
                        <MenuItem value={"DATE"}>Date</MenuItem>
                        <MenuItem value={"STATUS"}>État</MenuItem>
                        <MenuItem value={"ARTICLES"}>Nbr articles</MenuItem>
                        <MenuItem value={"HT"}>Total HT</MenuItem>

                    </Select>

                    <div style={{flex:1}}/>
                    <IconButton style={{border:"1px solid #CCCCCC"}} onClick={()=>{this.toggleSortDirection()}}>
                        <Icon style={{color:"#222222", fontSize:16}} className={this.state.sortDirection ? "sort-by-icon sort-by-icon-desc" : "sort-by-icon"}>arrow_upward</Icon>
                    </IconButton>
                </Paper>

                <Paper style={{width:"100%",  borderRadius:4, overflow:"hidden", border:"1px solid #DDDDDD"}} className={"quotes-paper"}>
                    <div style={{width:"100%", height:50, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                        backgroundColor:"#EFEFEF", borderBottom:"1px solid #DDDDDD"}} className={"quotes-table-header"}>

                        <div style={{width:100, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                            justifyContent:"flex-start", alignItems:"center", padding:5}}>
                            <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                                Numéro
                            </p>
                            {this.renderSortButton("NUMBER")}
                        </div>
                        <div style={{width:120, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                            justifyContent:"flex-start", alignItems:"center", padding:5}}>
                            <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                                Date
                            </p>
                            {this.renderSortButton("DATE")}
                        </div>
                        <div style={{width:120, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                            justifyContent:"flex-start", alignItems:"center", padding:5}}>
                            <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                                État
                            </p>
                            {this.renderSortButton("STATUS")}
                        </div>
                        <div style={{width:80, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                            justifyContent:"flex-start", alignItems:"center", padding:5}}>
                            <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                                Articles
                            </p>
                            {this.renderSortButton("ARTICLES")}
                        </div>
                        <div style={{width:150, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                            justifyContent:"flex-start", alignItems:"center", padding:5}}>
                            <p style={{flex:1, textAlign:"left", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>
                                Total HT.
                            </p>
                            {this.renderSortButton("HT")}
                        </div>
                        <div style={{flex:1, height:"100%", borderRight:"1px solid #DDDDDD", display:"flex", flexDirection:"row",
                            justifyContent:"flex-start", alignItems:"center", padding:5}}>
                            {/*<p style={{flex:1, textAlign:"flex", margin:0, fontSize:12, color:"#222222", fontWeight:"bold"}}>*/}
                            {/*    Actions*/}
                            {/*</p>*/}
                        </div>
                    </div>


                    {this.props.virtualized !== true &&
                        <>
                            {this.state.quotes.map((quote)=>{
                                return(
                                    <QuoteRow
                                        quote={quote}
                                        key={"quote_" + quote.id}/>
                                )
                            })}
                        </>
                    }

                    {this.props.virtualized === true &&
                        <>
                            <VirtualList
                                width={"100%"}
                                height={this.props.listHeight ? this.props.listHeight : 500}
                                itemCount={this.state.quotes.length}
                                itemSize={window.innerWidth > 768 ? 100 : 212}
                                overscanCount={10}
                                renderItem={({index, style}) => {
                                    let quote = this.state.quotes[index];
                                    return(
                                        <div key={index} style={style}>
                                            <QuoteRow
                                                quote={quote}
                                                key={"quote_" + quote.id}/>
                                        </div>
                                    )
                                }}
                            />
                        </>
                    }

                </Paper>
            </div>
        )
    }

    renderSortButton(sortBy){

        const selected = sortBy === this.state.sortBy;
        const down = this.state.sortDirection === false;

        return(
            <IconButton style={{border:selected ? "1px solid #CCCCCC" : "1px solid transparent"}} onClick={()=>{this.toggleSort(sortBy)}}>
                <Icon style={{color:selected ? "#222222" : "#797979", fontSize:16}} className={down ? "sort-by-icon sort-by-icon-desc" : "sort-by-icon"}>arrow_upward</Icon>
            </IconButton>
        )
    }
}
