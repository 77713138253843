import {EndPointProvider} from "./EndPointProvider";
import axios from "axios";
import {db} from "../db";
import UserAPI from "./UserAPI";

export class ExposedAPI {


    /**
     * All the categories, this way : [{id: x, label : "category 1", subCategories: [{id: x, label: "a subcategory of category 1", underSubCategories: [{id: x, label: "an underSubCategory of this subcategory"}]]}...]}...]
     * @returns {Promise<{id: *, label: *, subCategories: *}[]>}
     */
    static async getCategories() {
        let raw = await ExposedAPI.get("/exposed/categories/flat");
        let result = raw.categories.map(cat => {
            return {
                id: cat.id,
                label: cat.label,
                subCategories : raw.subCategories.filter(e => e.category_id === cat.id).map(sub => {
                    return {
                        id: sub.id,
                        label: sub.label,
                        underSubCategories: raw.underSubCategories.filter(e => e.sub_category_id === sub.id).map(under => {
                            return {
                                id: under.id,
                                label: under.label
                            }
                        }).sort((a,b)=>{return a.label < b.label ? -1 : 1})
                    }
                }).sort((a,b)=>{return a.label < b.label ? -1 : 1})
            }
        })
        return result;
    }

    /**
     * a list of  id, under_sub_category_id, discount_id, coupon_code_id, crmId, label, conditionnement, description, brandName, youtubeLink, basePrice, ttcPrice, ecoTax, VAT, featured
     * for the featured products
     * @returns {Promise<any|undefined>}
     */
    static async getFeaturedArticles() {
        return ExposedAPI._mapSalesCampaign(await ExposedAPI.get("/exposed/featured/articles"));
    }

    /**
     * a list of  id, under_sub_category_id, discount_id, coupon_code_id, crmId, label, conditionnement, description, brandName, youtubeLink, basePrice, ttcPrice, ecoTax, VAT, featured, usc_label
     * for a given under sub category
     * @returns {Promise<any|undefined>}
     */
    static async getUnderSubCategoryArticles(usc_id) {
        let result = await ExposedAPI.get(`/exposed/under/sub/category/${usc_id}/articles`);

        return result.map(e => {
            e.basePrice = (+e.basePrice).toFixed(2);
            return ExposedAPI._mapSalesCampaign(e);
        })
    }

    /**
     * a list of  id, under_sub_category_id, discount_id, coupon_code_id, crmId, label, conditionnement, description, brandName, youtubeLink, basePrice, ttcPrice, ecoTax, VAT, featured, sc_label, usc_label
     * for a given sub category
     * @returns {Promise<any|undefined>}
     */
    static async getSubCategoryArticles(sc_id) {
        let result = await ExposedAPI.get(`/exposed/sub/category/${sc_id}/articles`);
        return result.map(e => {
            e.basePrice = (+e.basePrice).toFixed(2);
            return ExposedAPI._mapSalesCampaign(e);
        })

    }


    /**
     * Returns two lists of articles :
     * { match_intersects: Article[], match_full_sequence: Article[] }
     *  Intersect : in the intersection of all keywords (separated)
     *  Full : match the full sequence provided
     * @param query
     * @returns {Promise<any|undefined>}
     */
    static async searchArticles(query) {
        let result = await ExposedAPI.post(`/exposed/search/articles`, {query : query});
        result.match_full_sequence = result.match_full_sequence.filter((a)=>{return a.soft_deleted !== "1"}).map(e => ExposedAPI._mapSalesCampaign(e));
        result.match_intersects = result.match_intersects.filter((a)=>{return a.soft_deleted !== "1"}).map(e => ExposedAPI._mapSalesCampaign(e));
        return result;
    }

    /**
     * Incorporate this directly into the img src <img src={ExposedAPI.getThumbnailURL(article_id)} />
     * @param article_id
     * @returns {string}
     */
    static getThumbnailURL(article_id) {
        return EndPointProvider.getBaseURLWithoutEndSlash() + '/exposed/article/' + article_id + '/get/thumbnail';
    }

    /**
     * Incorporate this directly into the img src <img src={ExposedAPI.getThumbnailURL(article_id)} />
     * @param attachment_id the specific attachment ID
     * @returns {string}
     */
    static getSpecificAttachmentThumbnailURL(attachment_id) {
        return EndPointProvider.getBaseURLWithoutEndSlash() + `/exposed/attachment/${attachment_id}/get/thumbnail`
    }

    /**
     * Incorporate this directly into the img src <img src={ExposedAPI.getThumbnailURL(article_id)} />
     * @param attachment_id the specific attachment ID
     * @returns {string}
     */
    static getFullSizeAttachmentURL(attachment_id) {
        return EndPointProvider.getBaseURLWithoutEndSlash() + `/exposed/attachment/${attachment_id}/get/full/size`
    }

    /**
     * Returns a detailed article
     * @param article_id
     * @returns {Promise<any|undefined>}
     */
    static async getArticle(article_id) {
        let result = await ExposedAPI.get(`/exposed/detailed/${article_id}/article`);
        ExposedAPI._mapSalesCampaign(result.article[0])
        return result;
    }

    static async retrievePassword(email) {
        return await ExposedAPI.post(`/exposed/password/reset/request`, {"userEmail": email, env : process.env.NODE_ENV});
    }

    /**
     * Return a list of articles matching this reference
     * @param reference
     * @returns {Promise<any|undefined>}
     */
    static async getArticleByReference(reference) {
        let result = await ExposedAPI.get(`/exposed/articles/by/${reference}/reference`);
        return ExposedAPI._mapSalesCampaign(result);
    }

    static _mapSalesCampaign(article) {
        article.activeSaleCampaign = ExposedAPI._retrieveSalesCampaign(article.active_sales_campaign_id) || null;
        return article;
    }

    /**
     * A list of the sales campaigns. Returns all active sales campaigns
     * @returns {Promise<any|undefined>}
     */
    static async getActiveSalesCampaigns() {
        return await ExposedAPI.get(`/exposed/active/sales/campaigns`);
    }


    /**
     * Store sales campaign into global
     * @returns {Promise<void>}
     */
    static async bootSalesCampaigns() {
        global.salesCampaigns = await ExposedAPI.getActiveSalesCampaigns();
    }

    /**
     * Returns a sales campaign loaded during boot
     * @param id
     * @returns {*}
     * @private
     */
    static _retrieveSalesCampaign(id) {
        return global.salesCampaigns?.find(e => e.id === id);
    }

    /**
     * {
     *  first_name: string,
     *  last_name: string,
     *  phone_number: string,
     *  email: string,
     *  company: string,
     *  content: string
     * }
     * @param contact_form
     * @returns {Promise<*>}
     */
    static async sendContactForm(contact_form) {
        return await ExposedAPI.post(`/post/contact/form`, contact_form);
    }


    static async get(url) {
        try {
            let httpResponse = await axios({
                method: 'get',
                url:  EndPointProvider.getBaseURLWithoutEndSlash() + url,
                headers: { }
            });
            return await httpResponse.data;
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }

    static async post(url, payload) {
        try {
            let httpResponse = await axios({
                method: 'post',
                url:  EndPointProvider.getBaseURLWithoutEndSlash() + url,
                headers: { 'Content-Type': 'application/json'},
                data : JSON.stringify(payload)
            });
            return await httpResponse.data;
        } catch (e) {
            console.warn(e);
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }

    /**
     * Requested properties :
     * {
     *     billingStreet: string
     *     billingStreetComplement: string
     *     billingCity: string
     *     billingCityZipCode: string
     *     billingCountry: string
     *
     *     deliveryStreet: string
     *     deliveryStreetComplement: string
     *     deliveryCity: string
     *     deliveryCityZipCode: string
     *     deliveryCountry: string
     *
     *     email: string,
     *     phone: string,
     *     comment: string,
     *
     *     lines: {article_id: number, quantity: number}[]
     * }
     *
     * It returns {created_quote: number} the ID of the created quote
     *
     */
    static async postQuote(quote) {
        quote.env = process.env.NODE_ENV;
        return await UserAPI._post(`/exposed/post/quote`, quote, true);   //The selected API is the user API for an exposed route; it is normal since it will inject the bearer if any and will work without anyway
    }






    static getDistantOrStoredCategories(){
        return new Promise((resolve,reject)=>{
            if(global._JSONCategories && global._JSONCategories.length > 0){
                resolve(JSON.parse(global._JSONCategories))
            }
            else{
                ExposedAPI.getCategories()
                    .then((categories)=>{
                        global._JSONCategories = JSON.stringify(categories)
                        resolve(categories)
                    })
                    .catch((err)=>{reject(err)})
            }
        })
    }


    /**
     * expect payload to be {resetToken:string, password: string}
     * @param user_id
     * @param payload
     * @returns {Promise<any|undefined>}
     */
    static async resetPassword(user_id, payload) {
        return await ExposedAPI.post(`/exposed/password/${user_id}/user/reset`, payload)
    }


    /***
     * Expected payload: {firstName: string, lastName: string, email: string, password: string, phone: string}
     * @param payload
     * @returns {Promise<void>}
     */
    static async createAccount(payload) {
        return await ExposedAPI.post(`/exposed/create/account`, payload);
    }

}
