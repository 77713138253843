import React from "react";
import './SearchBar.css';
import {Paper,Icon,IconButton, Divider,InputBase,Button, CircularProgress} from "@mui/material"
import {ExposedAPI} from "../../apis/ExposedAPI";
import ArticleList from "../article/articleList/ArticleList";

export default class SearchBar extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state={
            searchText:"",
            searching:false,
            results:[],
            noResults:false,
        }

        this.wrapperRef = React.createRef();
        this.wrapperRef2 = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    _articleNavigationTriggeredSubscription;

    componentDidMount(){
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("keydown", this.handleKeyDown);
        document.getElementById("root").addEventListener("scroll",this.handleScroll)

        this._articleNavigationTriggeredSubscription = global.emitter.addListener("articleNavigationTriggered",()=>{
            this.setState({
                searchText:"",
                searching:false,
                results:[],
                noResults:false,
            })
        })
    }

    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("keydown", this.handleKeyDown);
        document.getElementById("root").addEventListener("scroll",this.handleScroll)
        if(this._articleNavigationTriggeredSubscription){this._articleNavigationTriggeredSubscription.remove()}
    }

    handleClickOutside(event){
        if (this.wrapperRef && this.wrapperRef.current && (!this.wrapperRef.current.contains(event.target) && !this.wrapperRef2.current.contains(event.target))) {
            this.setState({
                searchText:"",
                results:[],
            })
        }
    }

    handleScroll(){
        if(document.getElementById("root").scrollTop > 150){
            document.getElementById("search-bar").classList.add("search-bar-fixed")
        }
        else{
            document.getElementById("search-bar").classList.remove("search-bar-fixed")
        }
    }

    handleKeyDown(event){
        if(event.keyCode === 13){
            this.search();
        }
    }

    onSearchTextChange(query){
        this.setState({
            searchText:query,
        },()=>{
            if(query.length >= 3){

            }
            else{
                this.setState({
                    results:[],
                    noResults:false,
                })
            }
        })
    }

    search(){
        if(this.state.searchText.length < 3){return}

        this.setState({searching:true})

        ExposedAPI.searchArticles(this.state.searchText)
            .then((searchResults)=>{
                let articles = [];

                if(searchResults.match_intersects.length > 0){articles = searchResults.match_intersects}
                else{articles = searchResults.match_full_sequence}

                for(let article of articles){
                    if(article.description.length > 180){article.short_description = article.description.substring(0,180) + "..."}
                    else{article.short_description = article.description}

                    article.sort_name = article.label.toUpperCase();
                    article.sort_brand = article.brandName ? article.brandName.toUpperCase() : "";
                    article.sort_ref = article.crmId.toUpperCase();
                    article.sort_cond = article.conditionnement ? article.conditionnement.toUpperCase() : "";
                    article.sort_ht = parseFloat(article.basePrice);
                }

                this.setState({
                    searching:false,
                    results:articles,
                    noResults:articles.length === 0,
                })
            })
            .catch((err)=>{
                console.log(err)
                this.setState({
                    searching:false,
                    results:[],
                    noResults:true,
                })
            })
    }

    render(){

        const searchDisabled = this.state.searchText.length < 3 || this.state.searching;

        return(
            <div className={"search-bar-wrapper"} id={"search-bar"}>
                <div className={"search-bar-content-wrapper"} >
                    <div className={"centerer-wrapper"}>
                        <div className={"centerer"}>

                            <Paper style={{width:"100%", height:40, borderRadius:6, display:"flex", flexDirection:"row", justifyContent:"flex-start",
                            overflow:"hidden", border:"1px solid #DDDDDD", alignItems:"center", paddingRight:4}} ref={this.wrapperRef2}>
                                <InputBase
                                    style={{height:40, flex:1, paddingLeft:8, paddingRight:8, position:"relative"}}
                                    value={this.state.searchText}
                                    placeholder={"Rechercher par référence article, désignation, mot clé"}
                                    onChange={(e)=>{this.onSearchTextChange(e.target.value)}}>
                                </InputBase>
                                {this.state.searching &&
                                    <div style={{width:40, height:40, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <CircularProgress style={{ color:"#0068B3"}} size={24}/>
                                    </div>
                                }

                                <Button size={"small"} variant={"outlined"} disabled={searchDisabled}
                                        style={{color:!searchDisabled ? "#0068B3" : "#CCCCCC",
                                            borderColor:!searchDisabled ? "#0068B3" : "#CCCCCC", marginTop:1,
                                        backgroundColor:searchDisabled ? "#EFEFEF" : "#D9EDF7"}}
                                onClick={()=>{this.search()}}>
                                    RECHERCHER
                                    <Icon style={{color:!searchDisabled ? "#0068B3" : "#CCCCCC", fontSize:18, marginLeft:4}}>search</Icon>
                                </Button>

                                <div style={{paddingTop:6, paddingBottom:6, marginLeft:4, marginRight:4, height:"100%"}}>
                                    <Divider orientation={"vertical"}/>
                                </div>
                                <IconButton style={{width:36, height:36}} onClick={()=>{this.onSearchTextChange("")}}>
                                    <Icon style={{fontSize:18, color:"#555555"}}>clear</Icon>
                                </IconButton>

                            </Paper>

                        </div>
                    </div>
                    {(this.state.results.length > 0 || this.state.noResults === true) &&
                        <div className={"search-bar-results-wrapper"}>
                            <div className={"centerer-wrapper"}>
                                <div className={"centerer"} style={{paddingLeft:12, paddingRight:12}}>
                                    <Paper style={{width:"100%", borderRadius:6, overflow:"hidden", border:"1px solid #DDDDDD",
                                        marginTop:4,  pointerEvents: "all"}} ref={this.wrapperRef}>
                                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            flexWrap:"wrap", padding:5}}>
                                            {this.state.noResults !== true &&
                                                <>
                                                    <p style={{width:"100%", textAlign:"left", fontSize:14, margin:0, marginBottom:5, color:"#555555"}}>
                                                        <strong style={{color:"#0068B3"}}>{this.state.results.length} résultats</strong> ont été trouvés pour votre recherche
                                                    </p>
                                                    <ArticleList
                                                        articles={this.state.results}
                                                        virtualized={true}
                                                        listHeight={window.innerHeight - 340}
                                                    />
                                                </>
                                            }
                                            {this.state.noResults === true &&
                                                <div style={{width:"100%", height:100, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <p style={{width:350, color:"#797979", fontSize:12, margin:0, textAlign:"center"}}>
                                                        Aucun article n'a été trouvé pour votre recherche
                                                    </p>
                                                </div>
                                            }

                                        </div>
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }

}
