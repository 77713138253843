import React from "react";
import './Header.css';
import PhoneNumber from "../shared/phoneNumber/PhoneNumber";
import {Button, ButtonGroup, Icon, Tooltip} from "@mui/material"
import {Link} from "react-router-dom"
import btx_logo from "../../images/logos/logo_btx_white.svg"
import UserAPI, {LOGIN_STATUS_CHANGED_EVENT_NAME} from "../../apis/UserAPI";
import {withRouter} from "../../withRouter";
import {PREVENT_MENU_HOVER_EVENT_NAME} from "../menu/Menu";
import SearchBar from "../searchbar/SearchBar";
import {AccountCircle, AdminPanelSettings, AdminPanelSettingsOutlined, ShoppingCart} from "@mui/icons-material";
import MyCartButton from "./MyCartButton/MyCartButton";

class Header extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            user: global.user
        }
    }

    async componentDidMount() {
        global.emitter.addListener(LOGIN_STATUS_CHANGED_EVENT_NAME, _ => this.setState({user: global.user}));   //When this event is raised, the global user is refreshed
    }

    render(){

        const adminLogged = UserAPI.isUserLoggedInAndAdmin();

        const btnStyles = {color:"#FFFFFF", borderColor:"#FFFFFF"};
        return (
            <div className={"header-wrapper"}>
                <div className={"header-top-wrapper"}>
                    <div className={"centerer-wrapper"}>
                        <div className={"centerer"}>
                            <div className={"header-content-wrapper"}>


                                <Link style={{textDecoration:"none"}} to={"/home"}>
                                    <img alt={"___"} style={{height:100, width:100}} src={btx_logo}/>
                                </Link>

                                <div className={"header-center-wrapper"}>
                                    <p style={{color:"#EFEFEF", fontSize:12, texAlign:"left", margin:0, maxWidth:300, lineHeight:1.4,
                                    marginBottom:10}}>
                                        <strong style={{color:"#FFFFFF", fontSize:14}}>
                                            Un renseignement technique,<br/> une question ?
                                        </strong>
                                        <br/>
                                        Nous sommes à votre écoute du lundi au vendredi de 8h à 19h (appel non-surtaxé)
                                    </p>

                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                        <PhoneNumber/>
                                        <span style={{display:"inline-block", color:"#EFEFEF", margin:10}}>ou</span>
                                        <Button onClick={_ => {
                                            global.emitter.emit(PREVENT_MENU_HOVER_EVENT_NAME, 3000);
                                            this.props.router.navigate("/contact/us")
                                        }} size={"small"} variant={"outlined"} style={{color:"#FFFFFF", borderColor:"#FFFFFF"}}>
                                            <Icon style={{color:"#FFFFFF", fontSize:18, marginRight:5}}>chat</Icon>
                                            Contactez-nous
                                        </Button>
                                    </div>
                                </div>

                                <div className={"header-right-wrapper"}>

                                    <div style={{display:"flex", flexDirection:"row", marginBottom:12}}>

                                        {adminLogged &&
                                            <Tooltip title={"Accéder au menu d'administration"} placement={"bottom"}>
                                                <Link to={"/admin"} style={{textDecoration:"none", height:36}}>
                                                    <Button variant={"outlined"} size={"medium"} style={{borderColor:"#FFFFFF", color:"#FFFFFF", backgroundColor:"#FFFFFF",
                                                        borderTopRightRadius:0, borderBottomRightRadius:0, borderRight:"none", width:38, maxWidth:38, minWidth:38, height:37,
                                                        paddingLeft:5, paddingRight:5}}>
                                                        <AdminPanelSettings fontSize={"small"} style={{color:"#0068B3"}}/>
                                                    </Button>
                                                </Link>
                                            </Tooltip>
                                        }

                                        <Button variant={"outlined"} size={"medium"} style={{borderColor:"#FFFFFF", color:"#FFFFFF", width:190,
                                            borderBottomLeftRadius:adminLogged ? 0 : 6, borderTopLeftRadius:adminLogged ? 0 : 6}}
                                            onClick={_ => {
                                                UserAPI.isUserLoggedIn() ? this.props.router.navigate("/my/account") : this.props.router.navigate("/login")
                                            }}

                                        >
                                            {!this.state.user && <Icon style={{color:"#FFFFFF", fontSize:18, marginRight:8}}>login</Icon>}
                                            {this.state.user &&  <Icon style={{color:"#FFFFFF", fontSize:18, marginRight:8}}>account_circle</Icon>}
                                            {this.state.user ? "MON COMPTE" : "ME CONNECTER"}

                                        </Button>
                                    </div>

                                    <MyCartButton/>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className={"header-search-wrapper"}>
                    <SearchBar/>
                </div>
            </div>
        );
    }

}

export default withRouter(Header);

