import React from "react";
import './ArticleEditPopup.css';
import {Button, Icon, ButtonBase, Popover, DialogContent, DialogActions, DialogTitle, Dialog, DialogContentText, Paper, LinearProgress, Divider} from "@mui/material";
import {ExposedAPI} from "../../../apis/ExposedAPI";
import {Link} from "react-router-dom"
import AdminAPI from "../../../apis/AdminAPI";
import {withRouter} from "../../../withRouter";
import moment from "moment";

class ArticleEditPopup extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            displayed:false,
            anchorEl:null,
            article:null,

            discountDialogDisplayed:false,
            loadingDiscounts:false,
            availableDiscounts:[],
            articleDiscounts:[],
            discountError:null,
        }
    }

    _displayArticleEditPopupSubscription:any;

    componentDidMount(){
        this._displayArticleEditPopupSubscription = global.emitter.addListener("displayArticleEditPopup",(data)=>{
            this.setState({
                displayed:true,
                anchorEl:data.browserEvent.currentTarget,
                article:data.article,
            })
        })
    }

    componentWillUnmount(){
        if(this._displayArticleEditPopupSubscription){this._displayArticleEditPopupSubscription.remove()}
    }

    onClose(){
        this.setState({
            displayed:false,
            anchorEl:null,
        })
    }

    render(){

        const article = this.state.article;

        return(
            <Popover
                open={this.state.displayed}
                anchorEl={this.state.anchorEl}
                onClose={()=>{this.onClose()}}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >

                {article &&
                    <div style={{padding:8}}>
                        <div style={{width:300, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <img alt={"___"} src={ExposedAPI.getThumbnailURL(article.id)} style={{height:45, width:45, borderRadius:4, border:"1px solid #DDDDDD"}}/>
                            <p style={{flex:1, color:"#222222", margin:0, paddingLeft:8, textAlign:"left", fontSize:14, textTransform:"uppercase", fontWeight:"bold"}}>
                                {article.label}
                            </p>
                        </div>
                        <p style={{width:"100%", margin:0, marginTop:8, marginBottom:8, color:"#0068B3", textAlign:"left", fontSize:12, fontWeight:"bold"}}>
                            Options d'administration
                        </p>
                        <div style={{width:"100%", minHeight:40, border:"1px solid #DDDDDD", borderRadius:4}}>
                            <Link style={{textDecoration:"none"}} to={"/a/" + article.id}>
                                <ButtonBase style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:'center', color:"#222222"}}
                                onClick={()=>{this.onClose()}}>
                                    MODIFIER L'ARTICLE <Icon style={{color:"#555555", fontSize:14, marginLeft:8}}>edit</Icon>
                                </ButtonBase>
                            </Link>
                            <Divider/>
                            <ButtonBase style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:'center', color:"#222222"}}
                            onClick={()=>{this.displayDiscountDialog()}}>
                                AJOUTER/RETIRER À UNE PROMO <Icon style={{color:"#555555", fontSize:14, marginLeft:8}}>monetization_on</Icon>
                            </ButtonBase>
                            <Divider/>
                            <ButtonBase style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:'center', color:"#222222"}}
                            onClick={()=>{this.toggleFeatured()}}>

                                {article.featured === "1" ? "NE PLUS METTRE EN AVANT": "METTRE EN AVANT"} <Icon style={{color:"#555555", fontSize:14, marginLeft:8}}>favorite</Icon>
                            </ButtonBase>
                        </div>
                    </div>
                }

                {this.renderdiscountDialog()}
            </Popover>
        )
    }

    toggleFeatured(){
        AdminAPI.toggleFeatured(this.state.article.id)
            .then(()=>{
                this.setState({
                    displayed:false,
                    anchorEl:null,
                },()=>{
                    this.props.router.navigate(this.props.router.location.pathname);
                })
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    displayDiscountDialog(){
        this.setState({
            discountDialogDisplayed:true,
            loadingDiscounts:true,
        })
        this.loadSalesCampaigns();
    }

    hideDiscountDialog(){
        this.setState({
            discountDialogDisplayed:false,
            loadingDiscounts:false,
        })
    }

    loadSalesCampaigns(){
        this.setState({loadingDiscounts:true})
        let promises = [
            ExposedAPI.getArticle(this.state.article.id),
            AdminAPI.getAllSalesCampaigns(),
        ]
        Promise.all(promises)
            .then((results)=>{

                console.log(results);

                let articleDiscounts = results[0].sales_campaign ? results[0].sales_campaign : [];
                let availableDiscounts = results[1];

                let alreadyUsedIds = articleDiscounts.map((sc)=>{return sc.id + ""})

                if(articleDiscounts.length > 0){
                    availableDiscounts = availableDiscounts.filter((sc)=>{return alreadyUsedIds.indexOf(sc.id) === -1})
                }

                this.setState({
                    loadingDiscounts:false,
                    availableDiscounts:availableDiscounts,
                    articleDiscounts:articleDiscounts,
                })
            })
            .catch((err)=>{
                this.setState({loadingDiscounts:false})
                console.log(err);
            })
    }

    renderdiscountDialog(){
        if(this.state.discountDialogDisplayed === false){return null}

        return(
            <Dialog open={true} onClose={()=>{this.hideDiscountDialog()}} maxWidth={false}>
                <DialogTitle>
                    Gérer les promotions
                </DialogTitle>
                <DialogContent>

                    <div style={{width:700}}>
                        <DialogContentText>
                            Depuis ce menu, déterminez dans quelle campagne de promotions cet article doit être inclus.
                            Cliquez sur une promotion pour l'ajouter / la retirer de la liste des propotions de l'article:
                        </DialogContentText>

                        <Paper style={{width:"100%", marginTop:12, border:"1px solid #EFEFEF", padding:8, display:"flex", position:"relative",
                            flexDirection:"row"}}>
                            {this.state.loadingDiscounts === true &&
                                <LinearProgress style={{position:"absolute", left:0, top:0, color:"#0068B3", height:5, right:0, width:"100%", borderTopLeftRadius:4,
                                    borderTopRightRadius:4}}/>
                            }

                            <div style={{flex:1, height:340, borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden"}}>

                                <div style={{width:"100%", height:30, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                                borderBottom:"1px solid #DDDDDD", backgroundColor:"#FAFAFA"}}>
                                    <span style={{color:"#555555", fontSize:12}}>Promotions disponibles</span>
                                </div>
                                <div style={{width:"100%", height:"calc(100% - 30px)", overflow:"auto"}}>

                                    {this.state.loadingDiscounts === false && this.state.availableDiscounts.length === 0 &&
                                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", padding:50}}>
                                            <p style={{color:"#797979", fontSize:12, textAlign:"center", margin:0}}>
                                                Aucune campagne de promotion disponible
                                            </p>
                                        </div>
                                    }

                                    {this.state.availableDiscounts.map((saleCampaign)=>{
                                        return(
                                            <div style={{width:"100%", height:40, borderBottom:"1px solid #EFEFEF", display:"flex", flexDirection:"row",
                                            justifyContent:"flex-start", alignItems:"center", paddingLeft:8, paddingRight:8}}
                                            className={"clickable-sc"} onClick={()=>{this.addArticleToSalesCampaign(saleCampaign)}}
                                                 key={"sc_" + saleCampaign.id}>
                                                <p style={{color:"#222222", margin:0, textAlign:"left", fontSize:14, flex:1}}>
                                                    {saleCampaign.title}<br/>
                                                    <span style={{color:"#1079FC", fontSize:12}}>
                                                        {moment(saleCampaign.start_date_time).format("DD/MM/YY")} ⟶ {moment(saleCampaign.end_date_time).format("DD/MM/YY")}
                                                    </span>
                                                </p>
                                                <p style={{padding:4, backgroundColor:"#2CBE4E", color:"#FFFFFF", margin:0, zIndex:4,
                                                    fontWeight:"bold", borderRadius:4, border:"1px solid #FFFFFF", fontSize:14}}>
                                                    -{saleCampaign.percentage}%
                                                </p>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>

                            <div style={{width:30, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                <Icon style={{color:"#797979", fontSize:18, margin:2}}>arrow_forward</Icon>
                                <Icon style={{color:"#797979", fontSize:18, margin:2}}>arrow_backward</Icon>
                            </div>

                            <div style={{flex:1, height:340, borderRadius:4, border:"1px solid #DDDDDD", overflow:"hidden"}}>
                                <div style={{width:"100%", height:30, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                                    borderBottom:"1px solid #DDDDDD", backgroundColor:"#FAFAFA"}}>
                                    <span style={{color:"#555555", fontSize:12}}>Promotion liée à l'article</span>
                                </div>
                                <div style={{width:"100%", height:"calc(100% - 30px)", overflow:"auto"}}>

                                    {this.state.loadingDiscounts === false && this.state.articleDiscounts.length === 0 &&
                                        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", padding:50}}>
                                            <p style={{color:"#797979", fontSize:12, textAlign:"center", margin:0}}>
                                                Aucune campagne de promotion liée à cet article
                                            </p>
                                        </div>
                                    }

                                    {this.state.articleDiscounts.map((saleCampaign)=>{
                                        return(
                                            <div style={{width:"100%", height:40, borderBottom:"1px solid #EFEFEF", display:"flex", flexDirection:"row",
                                                justifyContent:"flex-start", alignItems:"center", paddingLeft:8, paddingRight:8}}
                                                 className={"clickable-sc"} onClick={()=>{this.removeArticleFromSaleCampaign(saleCampaign)}}
                                                 key={"sc_" + saleCampaign.id}>
                                                <p style={{color:"#222222", margin:0, textAlign:"left", fontSize:14, flex:1}}>
                                                    {saleCampaign.title}<br/>
                                                    <span style={{color:"#1079FC", fontSize:12}}>
                                                        {moment(saleCampaign.start_date_time).format("DD/MM/YY")} ⟶ {moment(saleCampaign.end_date_time).format("DD/MM/YY")}
                                                    </span>
                                                </p>
                                                <p style={{padding:4, backgroundColor:"#2CBE4E", color:"#FFFFFF", margin:0, zIndex:4,
                                                    fontWeight:"bold", borderRadius:4, border:"1px solid #FFFFFF", fontSize:14}}>
                                                    -{saleCampaign.percentage}%
                                                </p>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                            {this.state.discountError &&
                                <div style={{position:"absolute", left:0, top:0, borderRadius:4, backgroundColor:"rgba(0,0,0,0.45)", height:"100%", width:"100%",
                                    display:"flex", justifyContent:"center", alignItems:"center", zIndex:50}} onClick={()=>{this.clearError()}}>

                                    <Paper style={{width:400, padding:12}}>
                                        <DialogContentText>
                                            {this.state.discountError}
                                        </DialogContentText>
                                        <Button variant={"outlined"} size={"small"} style={{color:"#0068B3", borderColor:"#0068B3", marginTop:12}}
                                                onClick={()=>{this.clearError()}}>
                                            FERMER
                                        </Button>
                                    </Paper>

                                </div>
                            }


                        </Paper>

                    </div>


                </DialogContent>
                <DialogActions>
                    <Button style={{color:"#0068B3"}} onClick={()=>{this.hideDiscountDialog()}}>
                        ANNULER
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    clearError(){
        this.setState({
            discountError:null
        })
    }

    addArticleToSalesCampaign(saleCampaign){
        AdminAPI.addArticleToSalesCampaign(saleCampaign.id,this.state.article.id)
            .then((res)=>{
                console.log(res);

                if(res && res.overlapping_sales_campaign){
                    this.setState({
                        discountError:"Action impossible: cette campagne de promotion recouperait la campagne de promotion courante de cet article (" + res.overlapping_sales_campaign.title + "). " +
                            "Veuillez d'abord retirer l'article de sa campagne de promotion courante.",
                    })
                }
                else{
                    this.setState({
                        displayed:false,
                        anchorEl:null,
                        article:null,
                        discountDialogDisplayed:false,
                    },()=>{
                        this.props.router.navigate(this.props.router.location.pathname);
                    })
                }
            })
            .catch((err)=>{
                console.log(err);
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })
    }

    removeArticleFromSaleCampaign(saleCampaign) {
        AdminAPI.removeArticleFromSalesCampaign(this.state.article.id, saleCampaign.id)
            .then(() => {
                this.setState({
                    displayed: false,
                    anchorEl: null,
                    article: null,
                    discountDialogDisplayed: false,
                }, () => {
                    this.props.router.navigate(this.props.router.location.pathname);
                })
            })
            .catch((err) => {
                console.log(err);
                global.emitter.emit("showSnackBar", {severity: "error", message: "Une erreur est survenue"})
            })
    }
}

export default withRouter(ArticleEditPopup)
