import React, {useEffect, useState} from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from "react-router-dom";
import Header from "./components/header/Header";
import Footerr from "./components/footer/Footerr";
import {ExposedAPI} from "./apis/ExposedAPI";
import Menu from "./components/menu/Menu";
import {EventEmitter} from "fbemitter";
import CartAPI from "./apis/CartAPI";
import UserAPI, {LOGIN_STATUS_CHANGED_EVENT_NAME} from "./apis/UserAPI";
import HomePage from "./pages/home/HomePage";
import TermsPage from "./pages/terms/TermsPage";
import ArticlePage from "./pages/article/ArticlePage";
import SubCategoryPage from "./pages/subCategory/SubCategoryPage";
import CategoryPage from "./pages/category/CategoryPage";
import UnderSubCategoryPage from "./pages/underSubCategory/UnderSubCategoryPage";
import App from "./App";

/**
 * This function wraps the entire app.js in order to instanciate what's required in a clear way.
 * @constructor
 */
export function Boot() {

    const [bootFinished, setBootFinished] = useState(false);


    useEffect(_ => {
        makeBoot();
    }, []);

    async function makeBoot() {


        global.emitter = new EventEmitter();    //Creates the global event emitter for broad usage
        await UserAPI.bootUser();               //Handle the whole user backup if required
        await ExposedAPI.bootSalesCampaigns();               //Handle the whole user backup if required

        global.emitter.addListener(LOGIN_STATUS_CHANGED_EVENT_NAME, CartAPI.onLoginStatusChanged)    //Allow the Cart API to watch for login changed

        //@Quentinor : Add custom code below if you need

        await setBootFinished(true);


    }

    return bootFinished ? <App/> : <></>;
}

