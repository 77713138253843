import {
    Badge,
    Breadcrumbs,
    Divider,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Typography
} from "@mui/material";
import {
    ArrowCircleUp,
    AttachMoney,
    ContentCut,
    Dashboard,
    Engineering,
    FiberNew,
    History, ImportExport,
    People
} from "@mui/icons-material";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import UserAPI from "../../apis/UserAPI";
import "./AdministrationMenuPage.css";

export function AdministrationMenuPage() {
    let navigate = useNavigate();

    useEffect(_ => {
        if(!UserAPI.isUserLoggedInAndAdmin()) {
            navigate("/");  //Run, you fool.
        }
        if(window.location.pathname === "/admin") navigate("/admin/quotes/pending")
    })

    const [menus, setMenus] = useState([
        {spacer: true, t: "Suivi des devis"},
        {t: "Nouveaux devis", l: "/admin/quotes/pending", i: <FiberNew fontSize={"small"}/>},
        {t: "Devis en cours", l: "/admin/quotes/ongoing", i: <Engineering fontSize={"small"}/>},
        {t: "Historique", l: "/admin/quotes/archived", i: <History fontSize={"small"}/>},
        {spacer: true, t: "Réglages"},
        {t: "Gestion des utilisateurs", l: "/admin/users/management", i: <People fontSize={"small"}/>},
        {t: "Gestion des promotions", l: "/admin/sales/campaigns", i: <AttachMoney fontSize={"small"}/>},
        {t: "Import oxygène", l: "/admin/oxygene", i: <ImportExport fontSize={"small"}/>},
    ]);

    return <div>
        <div>
            <Breadcrumbs aria-label="breadcrumb" style={{marginTop: 20, marginBottom: 20}}>
                <Link underline="hover" color="inherit" href="/admin">
                    Administration
                </Link>
                <Typography color="text.primary">{menus.find(e => e.l === window.location.pathname)?.t||""}</Typography>
            </Breadcrumbs>
        </div>
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems:"flex-start", justifyContent: "flex-start"}}
        className={"admin-content-wrapper"}>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <MenuList>
                {
                    menus.map((item, index) => <MenuItem key={index} selected={window.location.pathname === item.l } disabled={!!item.spacer} onClick={_ => navigate(item.l||"/admin")}>
                        {item.spacer ? <></> : <ListItemIcon>{item.i}</ListItemIcon>}
                        <ListItemText>
                            {item.t}
                        </ListItemText>
                    </MenuItem> )
                }
                </MenuList>
            </Paper>

            <div style={{flex: 1}} className={"admin-right-content"}>
                <Outlet />
            </div>
        </div>
    </div>


    // <ListItemIcon>
    //     <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}} badgeContent={4} color="success">
    //         <FiberNew fontSize="small" />
    //     </Badge>
    // </ListItemIcon>

}
