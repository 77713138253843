import {Alert, Button, Paper, Typography} from "@mui/material";
import {useState} from "react";
import AdminAPI from "../../../../apis/AdminAPI";
import {Loader} from "../../../../components/loader/Loader";

export function OxygenePage() {

    const [file, setFile] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState();
    const [error, setError] = useState(false);

    async function submit() {
        await setLoading(true);
        try {
            await setLogs(await AdminAPI.performOxygeneImport(file));
        } catch (e) {
            await setError(true);
        }
        await setLoading(false);
    }

    return <div>

        <Typography variant={"h5"}>Mises à jour du référentiel Oxygène</Typography><br/>
        {
            !loading && !logs && !error && <>
                <Typography>Sélectionnez un fichier de référentiel oxygène pour faire la mise à jour.</Typography><br/>
                <input
                    accept="text/csv"
                    id="contained-button-file"
                    type="file"
                    onChange={e => setFile(e.target.files[0])}
                />
                <Button disabled={file === undefined} variant="contained" component="span" onClick={_ => submit()}>
                    Mettre à jour
                </Button>
            </>
        }
        { loading && <Loader/> }
        {
            logs && !loading && !error && <div>
                <Alert severity={"info"}>
                    <Typography variant={"body1"}>L'import est terminé. Voici les retours du serveurs sur le détail de l'import. </Typography>
                    <div style={{display: "flex", flexDirection: "column-reverse", paddingLeft: 10, borderLeft: "3px solid rgb(1, 67, 97)"}}>
                        {
                            logs.map(log => <Typography variant={"subtitle2"}>{log}</Typography>)
                        }
                    </div>
                </Alert>
            </div>
        }
        {
            !loading && error && <div>
                <Alert severity={"error"}>
                    Une erreur s'est produite, et le fichier n'a pas pu être téléchargé.
                    <ul>
                        <li>Assurez vous bien que le fichier correspond à l'export Oxygène NON MODIFIE MANUELLEMENT</li>
                        <li>Si vous n'arrivez pas à faire l'import malgré la proposition précédente, contactez nous</li>
                    </ul>
                </Alert>
            </div>
        }
    </div>



}
