import {Alert, Button, Paper, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import logobtx from "../../images/logos/logo_btx_small.jpg";
import {Loader} from "../../components/loader/Loader";
import {Link, useParams} from "react-router-dom";
import {ExposedAPI} from "../../apis/ExposedAPI";
import UserAPI from "../../apis/UserAPI";

export function RecoverPasswordPage() {

    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    let urlParams = useParams();

    useEffect(_ => {
        UserAPI.logout();   //If you're there, you have no reason to be logged in.
    }, [])

    async function submit() {
        await setLoading(true);
        try {
            let res = await ExposedAPI.resetPassword(urlParams.userId, { resetToken : urlParams.recoverToken, password: newPass});
            await setSuccess(res["updated_user"]);
            await setLoading(false);
        } catch (e) {
            await setError(true);
            setLoading(false);
        }
    }


    return <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}>
        <Paper style={{width: 400, padding: 20, margin: 30, display: "flex", flexDirection: "column", alignItems: "center"}}>
            {
                !loading && ! error && !success && <>
                    <Typography variant={"h5"} style={{textAlign: "center"}}>Récupération du mot de passe</Typography>
                    <img style={{display: "block", margin: 30}} src={logobtx} height={90} width={90}/>
                    <TextField
                        label={"Nouveau mot de passe"}
                        value={newPass}
                        onChange={e => setNewPass(e.target.value)}
                        variant={"outlined"}
                        fullWidth
                        type={"password"}
                        style={{margin: 40}}
                    />
                    <TextField
                        label={"Confirmer le mot de passe"}
                        value={confirmNewPass}
                        onChange={e => setConfirmNewPass(e.target.value)}
                        variant={"outlined"}
                        fullWidth
                        type={"password"}
                        style={{margin: 40, marginTop: 0, marginBottom: 20}}
                    />
                    {
                        ((confirmNewPass.length && confirmNewPass !== newPass) || (confirmNewPass.length && newPass.length && newPass.length <= 5)) ? <Alert severity={"warning"}>
                            {confirmNewPass.length && confirmNewPass !== newPass && <span>Les deux mots de passe ne correspondent pas</span>}
                            {confirmNewPass.length && newPass.length && newPass.length <= 5 && <span>Votre mot de passe doit contenir au moins 6 caractères</span>}
                        </Alert> : null
                    }
                    <Button onClick={submit} disabled={!newPass.length || !confirmNewPass.length || newPass !== confirmNewPass || (newPass.length && newPass.length < 6)}  style={{margin: 40}} variant={"contained"}>Confirmer</Button>
                </>
            }
            {
                loading && <Loader/>
            }
            {
                !loading && error && <Alert severity={"error"}>Une erreur s'est produite, nous n'avons pas été en mesure de rétablir l'accès à votre compte. Veuillez réessayer la procédure de récupération de mot de passe en cliquant <Link to={"/login"}>ici</Link></Alert>
            }
            {
                !loading && success && <Alert severity={"success"}>Votre mot de passe de votre compte {success} a été correctement redéfini. Cliquez <Link to={"/login"}>ici</Link> pour vous connecter avec ce nouveau mot de passe</Alert>
            }
        </Paper>
    </div>
}
