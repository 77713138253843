import {Alert, Button, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {TaskAlt} from "@mui/icons-material";
import {APITools} from "../../../apis/APITools";
import UserAPI from "../../../apis/UserAPI";
import {Link} from "react-router-dom";

export function AccountInfoPage() {

    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [dStreetName, setDStreetName] = useState("");
    const [dStreetNameComp, setDStreetNameComp] = useState("");
    const [dCity, setDCity] = useState("");
    const [dZipCode, setDZipCode] = useState("");
    const [dCountry, setDCountry] = useState("");

    const [bStreetName, setBStreetName] = useState("");
    const [bStreetNameComp, setBStreetNameComp] = useState("");
    const [bCity, setBCity] = useState("");
    const [bZipCode, setBZipCode] = useState("");
    const [bCountry, setBCountry] = useState("");


    useEffect(_ => {
        loadUser();

    }, [])

    async function loadUser() {
        let mySelf = await UserAPI.getSelf();
        await setFirstName(mySelf.firstName||"");
        await setLastName(mySelf.lastName||"");
        await setEmail(mySelf.email||"");
        if(mySelf.default_billing_address) {
            console.log(mySelf.default_billing_address);
            let adr: {street: string, additionalStreet: string, city: string, zipCode: string, country: string } = mySelf.default_billing_address;
            await setBStreetName(adr.street||"");
            await setBStreetNameComp(adr.additionalStreet||"");
            await setBCity(adr.city||"");
            await setBZipCode(adr.zipCode||"");
            await setBCountry(adr.country||"");
        }

        if(mySelf.default_delivery_address) {
            let adr: {street: string, additionalStreet: string, city: string, zipCode: string, country: string } = mySelf.default_delivery_address;
            await setDStreetName(adr.street||"");
            await setDStreetNameComp(adr.additionalStreet||"");
            await setDCity(adr.city||"");
            await setDZipCode(adr.zipCode||"");
            await setDCountry(adr.country||"");
        }
        await setLoading(false);
    }

    async function updateUser() {
        await setLoading(true);
        await APITools.sleep(500);
        await UserAPI.putPersonalInfo({
            firstName: firstName,
            lastName: lastName,
            IBAN: "",
            billingAddress: {
                street: bStreetName,
                additionalStreet: bStreetNameComp,
                city: bCity,
                zipCode: bZipCode,
                country: bCountry
            },
            deliveryAddress: {
                street: dStreetName,
                additionalStreet: dStreetNameComp,
                city: dCity,
                zipCode: dZipCode,
                country: dCountry
            }
        })
        await UserAPI.bootUser();
        await setLoading(false);
        // UserAPI
        await loadUser();
    }

    const fields = [
            {spacer: "Informations générales"},
            {g: firstName, s: setFirstName, l: "Prénom"},
            {g: lastName, s: setLastName, l: "Nom de famille"},
            {g: email, s: setEmail, l: "Email", d: true},
            {spacer: "Adresse de livraison préférée"},
            {g: dStreetName, s: setDStreetName, l: "Nom et adresse", md:12},
            {g: dStreetNameComp, s: setDStreetNameComp, l: "Complément d'adresse", md: 12},
            {g: dZipCode, s: setDZipCode, l: "Code postal", md: 3},
            {g: dCity, s: setDCity, l: "Ville", md: 5},
            {g: dCountry, s: setDCountry, l: "Pays", md: 4},
            {spacer: "Adresse de facturation préférée"},
            {g: bStreetName, s: setBStreetName, l: "Nom et adresse", md:12},
            {g: bStreetNameComp, s: setBStreetNameComp, l: "Complément d'adresse", md: 12},
            {g: bZipCode, s: setBZipCode, l: "Code postal", md: 3},
            {g: bCity, s: setBCity, l: "Ville", md: 5},
            {g: bCountry, s: setBCountry, l: "Pays", md: 4}
    ]

    return <div style={{width: "100%"}}>
        <Alert severity={"info"} style={{marginBottom: 10}}>Votre addresse e-mail ne peut pas être mise à jour automatiquement, si vous souhaitez changer votre addresse e-mail, <Link to={"/contact/us"}> contactez-nous</Link></Alert>
        <Grid container spacing={2}>
            {
                fields.map((field, index) => field.spacer ? <Grid key={index} item xs={12}><Typography variant={"h4"}/>{field.spacer}</Grid> : <Grid  key={index} item md={field.md || 6} xs={12}>
                    <TextField
                        onChange={e => field.s(e.target.value)}
                        value={field.g}
                        disabled={field.d !== undefined || loading}
                        label={field.l} fullWidth={true}
                        size="small"
                        variant="outlined"
                    />
                </Grid>)
            }

            <Grid item xs={12} style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
                <Button onClick={_ => updateUser()} variant={"contained"} type={"submit"} color={"success"} startIcon={<TaskAlt/>} disabled={loading}>Valider</Button>
            </Grid>
        </Grid>
    </div>
}
