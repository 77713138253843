import React from "react";
import './QuoteRow.css';
import {Button, Icon, Paper, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, Select, MenuItem, CircularProgress} from "@mui/material";
import {ExposedAPI} from "../../../apis/ExposedAPI";
import discount from "../../../images/pennants/discount.svg";
import {withRouter} from "../../../withRouter";
import UserAPI from "../../../apis/UserAPI";
import AdminAPI from "../../../apis/AdminAPI";

class QuoteRow extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            detailed:false,
            statusChangeDisplayed:false,
            changingStatus:false,
        }
    }

    render() {

        const quote = this.props.quote;

        return (
            <div style={{width: "100%"}} key={"quote" + quote.id} className={"quote-wrapper"}>

                <div style={{width:"100%", height:100, borderBottom:"1px solid #EEEEEE"}} className={"quote-row-wrapper"}
                     onClick={()=>{this.showQuoteDetail()}}
                >
                    <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center", fontWeight:"bold"}}>{quote.sort_number}</p>
                    </div>
                    <div style={{width:120, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{quote.pretty_creation_date_time}</p>
                    </div>
                    <div style={{width:120, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <div style={{height:16, width:16, borderRadius:20, border:"1px solid #555555", backgroundColor:quote.pretty_pipe_status_color}}/>
                        <p style={{color:"#222222", fontSize:14, flex:1, margin:0, textAlign:"center"}}>{quote.pretty_pipe_status}</p>
                    </div>
                    <div style={{width:80, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{quote.total_quantity}</p>
                    </div>
                    <div style={{width:150, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center", fontWeight:"bold"}}>
                            {quote.total_ht_price + "€"}
                        </p>
                    </div>
                </div>

                <Paper style={{width:"100%", height:200, border:"1px solid #DDDDDD", borderRadius:6, marginBottom:12}} className={"quote-row-card"} onClick={()=>{this.showQuoteDetail()}}>
                    <div style={{width:"100%", height:100, borderBottom:"1px solid #EFEFEF", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center", fontWeight:"bold"}}>{quote.id}</p>
                        </div>
                        <div style={{width:120, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{quote.pretty_creation_date_time}</p>
                        </div>
                        <div style={{width:120, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <div style={{height:16, width:16, borderRadius:20, border:"1px solid #555555", backgroundColor:quote.pretty_pipe_status_color}}/>
                            <p style={{color:"#222222", fontSize:14, flex:1, margin:0, textAlign:"center"}}>{quote.pretty_pipe_status}</p>
                        </div>
                    </div>
                    <div style={{width:"100%", height:100, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{quote.total_quantity}</p>
                        </div>
                        <div style={{width:150, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center", fontWeight:"bold"}}>
                                {quote.total_ht_price + "€"}
                            </p>
                        </div>
                    </div>
                </Paper>

                {this.renderQuoteDetail()}
            </div>
        )
    }

    showQuoteDetail(){
        this.setState({
            detailed:true
        })
    }

    hideQuoteDetail(){
        this.setState({
            detailed:false,
        })
    }

    displayChangeStatus(){
        this.setState({statusChangeDisplayed:true})
    }

    hideChangeStatus(){
        this.setState({statusChangeDisplayed:false})
    }


    onStatusChanged(newStatus){

        this.setState({changingStatus:true})

        AdminAPI.updateQuoteStatus(this.props.quote.id,newStatus)
            .then(()=>{
                this.setState({
                    detailed:false,
                    statusChangeDisplayed:false,
                    changingStatus:false,
                },()=>{
                    this.props.router.navigate(this.props.router.location.pathname);
                })
            })
            .catch((err)=>{
                console.log(err)
                this.setState({changingStatus:false})
                global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue"})
            })


    }

    renderQuoteDetail(){
        if(this.state.detailed === false){return null}

        const quote = this.props.quote;
        const isAdmin = UserAPI.isUserLoggedInAndAdmin();

        return(
            <Dialog open={true} maxWidth={false} onClose={()=>{this.hideQuoteDetail()}}>
                <DialogTitle>
                    Devis n° {quote.sort_number}
                </DialogTitle>
                <DialogContent style={{borderTop:"1px solid #EFEFEF", borderBottom:"1px solid #EFEFEF", backgroundColor:"#FAFAFA",
                position:"relative", padding:0}}>

                    {this.state.statusChangeDisplayed &&
                        <div style={{width:"100%", height:"100%", zIndex:40, position:"absolute", backgroundColor:"rgba(0,0,0,0.2)", display:"flex",
                            justifyContent:"center", alignItems:"center"}}>
                            <Paper style={{width:350}}>
                                <p style={{padding:20, margin:0, textAlign:"left", fontWeight:"bold", fontSize:16}}>
                                    Modifier l'état du devis
                                </p>
                                <div style={{width:"100%", padding:12, paddingTop:0}}>
                                    {this.state.changingStatus !== true &&
                                        <Select
                                            value={quote.pipe_status}
                                            onChange={(e)=>{this.onStatusChanged(e.target.value)}}
                                            variant={"outlined"}
                                            size={"small"}
                                            style={{backgroundColor:"#FAFAFA", marginLeft:6}}
                                        >
                                            <MenuItem value={"PENDING"}>EN ATTENTE</MenuItem>
                                            <MenuItem value={"ON_GOING"}>EN COURS</MenuItem>
                                            <MenuItem value={"ARCHIVED"}>ARCHIVÉ</MenuItem>

                                        </Select>
                                    }

                                    {this.state.changingStatus === true &&
                                        <div style={{width:"100%", height:50, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center', borderRadius:6,
                                            border:"1px solid #FFFFFF"}}>
                                            <div style={{height:50, width:40, marginRight:10, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                <CircularProgress size={24} style={{color:"#0068B3"}}/>
                                            </div>
                                            <p style={{flex:1, paddingLeft:8, paddingRight:8, textAlign:"left", color:"#797979", fontSize:16, margin:0}}>
                                                Modification en cours, veuillez patienter ...
                                            </p>
                                        </div>
                                    }
                                </div>
                                {this.state.changingStatus !== true &&
                                    <div style={{width:"100%", padding:12, paddingTop:0, display:"flex", flexDirection:"row",
                                        justifyContent:"flex-end",alignItems:"center"}}>
                                        <Button size={"small"} style={{color:"#0068B3"}} onClick={()=>{this.hideChangeStatus()}}>
                                            ANNULER
                                        </Button>
                                    </div>
                                }
                            </Paper>
                        </div>
                    }

                    <div style={{width:800, maxWidth:"100%", height:"50vh", minHeight:700, minWidth:620, padding:8}}>

                        <p style={{margin:0, marginBottom:4, color:"#555555", fontSize:12, fontStyle:"italic", marginTop:8}}>
                            Informations générales
                        </p>

                        <div className={"general-information-table quote-table"} style={{overflow:"visible"}}>
                            <div className={"quote-table-row"}>
                                <div className={"quote-table-row-left"}>
                                    <p className={"quote-table-left-text"}>Numéro devis</p>
                                </div>
                                <div className={"quote-table-row-right"}>
                                    <p className={"quote-table-right-text"}>{quote.sort_number}</p>
                                </div>
                            </div>
                            <div className={"quote-table-row"}>
                                <div className={"quote-table-row-left"}>
                                    <p className={"quote-table-left-text"}>Créé le</p>
                                </div>
                                <div className={"quote-table-row-right"}>
                                    <p className={"quote-table-right-text"}>{quote.pretty_creation_date_time}</p>
                                </div>
                            </div>
                            <div className={"quote-table-row"} style={{position:"relative"}}>
                                <div className={"quote-table-row-left"}>
                                    <p className={"quote-table-left-text"}>Traitement</p>
                                </div>
                                <div className={"quote-table-row-right"} style={{position:"relative"}}>
                                    <div style={{height:16, width:16, borderRadius:20, border:"1px solid #555555", backgroundColor:quote.pretty_pipe_status_color, marginRight:4}}/>
                                    <p style={{color:"#222222", fontSize:12, flex:1, margin:0, textAlign:"left"}}>{quote.pretty_pipe_status}</p>
                                </div>

                                {isAdmin &&
                                    <Button size={"small"} variant={"outlined"} style={{color:"#0068B3", borderColor:"#0068B3", position:"absolute",
                                    right:-145, top:-1, backgroundColor:"#FFFFFF"}}
                                    onClick={()=>{this.displayChangeStatus()}}>
                                        MODIFIER [ADMIN]
                                    </Button>
                                }
                            </div>
                        </div>

                        <p style={{margin:0, marginBottom:4, color:"#555555", fontSize:12, fontStyle:"italic", marginTop:12}}>
                            Livraison et facturation
                        </p>

                        <div className={'billing-tables-wrapper'}>

                            <div className={"delivery-information-table quote-table"}>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Adresse de livraison</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.delivery_street}</p>
                                    </div>
                                </div>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Compl. adresse</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.delivery_street_complement}</p>
                                    </div>
                                </div>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Ville</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.delivery_zip_code + " " + quote.delivery_city}</p>
                                    </div>
                                </div>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Pays</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.delivery_country}</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{height:10, width:10}}/>

                            <div className={"billing-information-table quote-table"}>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Adresse de facturation</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.billing_street}</p>
                                    </div>
                                </div>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Compl. adresse</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.billing_street_complement}</p>
                                    </div>
                                </div>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Ville</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.billing_zip_code + " " + quote.billing_city}</p>
                                    </div>
                                </div>
                                <div className={"quote-table-row"}>
                                    <div className={"quote-table-row-left"}>
                                        <p className={"quote-table-left-text"}>Pays</p>
                                    </div>
                                    <div className={"quote-table-row-right"}>
                                        <p className={"quote-table-right-text"}>{quote.billing_country}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <p style={{margin:0, marginBottom:4, color:"#555555", fontSize:12, fontStyle:"italic", marginTop:12}}>
                            Articles
                        </p>

                        <div style={{width:"100%", height:40, border:"1px solid #DDDDDD", borderBottom:"none", backgroundColor:"#EFEFEF",
                        display:"flex", flexDirection:"row", borderTopLeftRadius:4, borderTopRightRadius:4}}>
                            <div className={"quote-line-wrapper-cell"} style={{width:80}}/>
                            <div className={"quote-line-wrapper-cell"} style={{width:190, flexDirection:"column"}}>
                                <p className={"quote-line-value"} style={{fontWeight:"bold"}}>{"Article"}</p>
                            </div>
                            <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                <p className={"quote-line-value"} style={{fontWeight:"bold"}}>{"Cond."}</p>
                            </div>
                            <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                <p className={"quote-line-value"} style={{fontWeight:"bold"}}>{"Pu H.T"}</p>
                            </div>
                            <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                <p className={"quote-line-value"} style={{fontWeight:"bold"}}>{"Quantité"}</p>
                            </div>
                            <div className={"quote-line-wrapper-cell"} style={{flex:1}}>
                                <p className={"quote-line-value"} style={{textAlign:"right", paddingRight:12, fontWeight:"bold"}}>{"Total H.T"}</p>
                            </div>
                        </div>

                        <div style={{width:"100%", border:"1px solid #DDDDDD", backgroundColor:"#FFFFFF"}}>
                            {quote.lines.map((line)=>{
                                return(
                                    <div key={"line_" + line.id} className={"quote-line-wrapper"}>

                                        <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                            <div className={"quote-line-image-wrapper"} style={{position:"relative"}}>
                                                <div className={"quote-image"} style={{backgroundImage:"url(" + ExposedAPI.getThumbnailURL(line.article_id) + ")"}}>
                                                    {line.sale_percentage !== "0" && <div className={"article-pennant"} style={{backgroundImage:"url(" + discount + ")"}}/>}

                                                    {line.sale_percentage  !== "0" &&
                                                        <p style={{position:"absolute", bottom:1, right:1, padding:2, backgroundColor:"#2CBE4E", color:"#FFFFFF", margin:0, zIndex:22,
                                                            fontWeight:"bold", borderRadius:4, border:"1px solid #FFFFFF", fontSize:12}}>
                                                            -{line.sale_percentage}%
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"quote-line-wrapper-cell"} style={{width:190, flexDirection:"column"}}>
                                            <p className={"quote-line-value"} style={{fontWeight:"bold"}}>{line.articleTitle}</p>
                                        </div>
                                        <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                            <p className={"quote-line-value"}>{line.packing}</p>
                                        </div>
                                        <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                            <p className={"quote-line-value"}>{parseFloat(line.unit_price).toFixed(2)}€</p>
                                        </div>
                                        <div className={"quote-line-wrapper-cell"} style={{width:80}}>
                                            <p className={"quote-line-value"}>{line.quantity}</p>
                                        </div>
                                        <div className={"quote-line-wrapper-cell"} style={{flex:1}}>
                                            <p className={"quote-line-value"} style={{textAlign:"right", paddingRight:12, fontWeight:"bold"}}>{(parseFloat(line.unit_price)*parseFloat(line.quantity)).toFixed(2)}€</p>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                        <div style={{width:"100%", height:40, border:"1px solid #DDDDDD", borderTop:"none", backgroundColor:"#EFEFEF", justifyContent:"flex-start",
                            alignItems:"center", display:"flex", flexDirection:"row", borderBottomLeftRadius:4, borderBottomRightRadius:4, marginBottom:25}}>
                            <p className={"quote-line-value"} style={{textAlign:"right", paddingRight:12, fontWeight:"bold", fontSize:14, color:"#555555"}}>
                                Total général H.T: <strong style={{color:"#0068B3",fontSize:18}}>{quote.total_ht_price}€</strong>
                            </p>
                        </div>

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.hideQuoteDetail()}} style={{color:"#0068B3"}}>
                        FERMER
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withRouter(QuoteRow);
