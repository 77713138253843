import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, TextField, Typography} from "@mui/material";
import {ArrowRight, ExpandMore} from "@mui/icons-material";
import moment from "moment";
import ArticleList from "../../../../components/article/articleList/ArticleList";
import AdminAPI from "../../../../apis/AdminAPI";

export function RenderSalesCampaign(props) {

    const [sc, setSc] = useState(props.sc)
    const [newTitle, setNewTitle] = useState(props.sc.title)
    const [newPer, setNewPer] = useState(props.sc.percentage)

    async function close() {
        if(window.confirm("Confirmez-vous la clôture de cette campagne ? Sa date de fin de validité sera donc aujourd'hui")) {
            await AdminAPI.closeSalesCampaign(sc.id);
            global.emitter.emit("reload_sales_campaigns");
            global.emitter.emit("showSnackBar", {severity:"success", message : "clôture effectuée"})
        }
    }

    async function remove() {
        if(window.confirm("Confirmez-vous la clôture de cette campagne ? Sa date de fin de validité sera donc aujourd'hui")) {
            await AdminAPI.deleteSalesCampaign(sc.id);
            global.emitter.emit("reload_sales_campaigns");
            global.emitter.emit("showSnackBar", {severity:"success", message : "Suppression effectuée"})
        }
    }

    async function update() {
        console.log("here");
        if(newPer > 0 && newPer < 99) {
            console.log("there");
            await AdminAPI.updateSalesCampaign(sc.id, {title: newTitle, percentage: newPer});
            global.emitter.emit("reload_sales_campaigns");
            global.emitter.emit("showSnackBar", {severity:"success", message : "Modifications effectuées"})
        }
    }

    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMore />}
        >
            <Grid container spacing={0}>
                <Grid item xs={8} style={{display: "flex"}}>
                    <Chip label={sc.percentage + "%"}  color="success" size={"small"} style={{marginRight: 10}}/>
                    <Typography title={"Clic droit pour éditer"}>{sc.title} ({sc.articlesCnt} article(s))</Typography>
                </Grid>
                <Grid item xs={4} style={{display: "flex", justifyContent:"flex-start", alignItems:"center"}}>
                    <Typography sx={{ color: 'text.secondary' }}>{moment(sc.start_date_time).format("DD/MM/YYYY")}</Typography>
                    <ArrowRight/>
                    <Typography sx={{ color: 'text.secondary' }}>{moment(sc.start_date_time).format("DD/MM/YYYY")}</Typography>
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12} style={{marginBottom: 20}}>
                    <TextField
                        onChange={e => setNewTitle(e.target.value)}
                        value={newTitle}
                        label="Renommer la promotion" fullWidth={true}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={2} xs={12}>

                    <TextField
                        onChange={e => setNewPer(e.target.value)}
                        value={newPer}
                        label="Changer la remise (%)" fullWidth={true}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={2} xs={12}>

                    <Button variant={"contained"} onClick={update} disabled={newTitle.length === 0 || newPer <= 0 || newPer >= 99}>Valider</Button>
                </Grid>

                <Grid item sm={1} xs={12}>

                </Grid>
                <Grid item sm={3} xs={12}>
                    <Button fullWidth disabled={sc.terminated||false} variant={"contained"} onClick={close} color={"error"}>Clôturer</Button>
                    <br/>
                    <br/>
                    <Button fullWidth variant={"contained"} color={"error"} onClick={remove}>Supprimer</Button>
                    <br/>
                    <br/>

                </Grid>
            </Grid>

            {
                sc.articles && <ArticleList
                    articles={sc.articles}
                    isCart={false}
                    virtualized={false}
                    listHeight={sc.articles.length > 10 ? 1000 : 100* sc.articles.length}
                />
            }
        </AccordionDetails>
    </Accordion>
}
