import React from "react";
import './TermsPage.css';

export default class TermsPage extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            terms:this.getTerms()
        }
    }

    componentDidMount(){
    }

    render(){
        return(
            <div style={{width:"100%"}}>
                {this.state.terms.map((term)=>{
                    return(
                        <div style={{width:"100%"}} key={"term_" + term.title}>
                            <p style={{color:"#222222", fontWeight:"bold", fontSize:18, margin:0, marginTop:30}}>
                                {term.title}
                            </p>
                            {term.content.map((content)=>{
                                return(
                                    <div style={{width:"100%"}} key={"content_" + content.body}>
                                        {content.subtitle &&
                                            <p style={{color:"#222222", fontWeight:"bold", fontSize:14, margin:0, marginTop:20, textAlign:"left"}}>
                                                {content.subtitle}
                                            </p>
                                        }
                                        <p style={{color:"#555555", fontSize:14, margin:0, marginTop:12, textAlign:"left"}}>
                                            {content.body}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    getTerms(){
        return([
            {
                title:"GÉNÉRALITÉS",
                content:[{
                    subtitle:null,
                    body:"Tout devis adressée à la société BIO-TECHNOFIX implique l’acceptation entière et sans réserve de l’acheteur aux présentes conditions générales de vente et de facturation qui prévalent sur toute autre condition d’achat, même celle figurant sur les documents ou correspondances du client. Toute clause contraire doit faire l’objet d’un accord préalable écrit de la Société BIO-TECHNOFIX. Ces conditions générales de ventes sont susceptibles de modification sans préavis."
                }]
            },
            {
                title:"COMMANDES",
                content:[
                    {
                        subtitle:"a) Acceptation de commande",
                        body:"Toute commande n’est considérée comme définitive que lorsqu’elle est formulée par écrit. Si la commande n’a pas été précédée d’un devis de la part de la Société BIO-TECHNOFIX, elle ne sera considérée comme effective qu’après acceptation de la Société BIO-TECHNOFIX. La commande est irrévocable et non modifiable pour le client dès sa réception par la Société BIO-TECHNOFIX."
                    },
                    {
                        subtitle:"b) Passation de commande",
                        body:"La commande doit obligatoire mentionner un numéro de référence, les adresses de livraison et de facturation, la référence des articles et la dénomination complète de chaque produit ou services ainsi que le numéro de devis éventuel.BIO-TECHNOFIX décline toute responsabilité pour toute erreur d’expédition occasionnée par une erreur de rédaction de la commande par le client."
                    }
                ]
            },
            {
                title:"PRIX",
                content:[{
                    subtitle:null,
                    body:"Nos prix sont exprimés en Euro horsTVA et franco de port pour toute commande supérieure à 350 €HT. dans le cas de commande d’un montant inférieur à 350 €HT la participation aux frais de port et d’emballage sera de 29,50 €HT. Les prix figurants sur nos tarifs, catalogues ou autres documents sont donnés à titre indicatif et ne constituent aucun engagement de notre part. Nos devis ont une validité de 30 jours sauf accord préalable écrit entre le client et la société BIO-TECHNOFIX."
                }]
            },
            {
                title:"LIVRAISONS ET DÉLAIS DE LIVRAISON",
                content:[{
                    subtitle:null,
                    body:"Les délais de livraison sont indiqués sur l’accusé de réception de la commande. Ils sont déterminés de la manière la plus précise possible mais n’en constituent pas pour autant un engagement de la Société BIO-TECHNOFIX. Sauf contre indication portée sur le bon de commande, la Société BIO-TECHNOFIX se réserve le droit d’effectuer des livraisons partielles. Le client a l’obligation de préciser sur sa commande les instructions indispensables à la livraison. Les marchandises voyagent toujours aux risques et périls du destinataire, toutefois ce dernier pourra demander par écrit une assurance transport. Aucune réclamation ne sera admise si elle n’est pas formulée par écrit dans les 48 heures qui suivent l’arrivée des marchandises. Les retards de livraison ne justifient en aucun cas l’annulation de la commande. En cas de difficulté majeure et imprévisible, nous nous réservons le droit de résilier en totalité ou en partie nos engagements sans versement d’indemnité."
                }]
            },
            {
                title:"RETOURS",
                content:[{
                    subtitle:null,
                    body:"Tout retour de marchandise doit faire l’objet d’un accord préalable de la société BIO-TECHNOFIX.Tout retour effectué sans cet accord serait tenu à la disposition de l’acquéreur et ne donnerait pas lieu à l’établissement d’un avoir. Tout retour accepté par la Société BIO-TECHNOFIX donnera lieu à un avoir au profit de l’acquéreur, sous déduction d’un forfait de 15% du prix initial."
                }]
            },
            {
                title:"GARANTIES",
                content:[{
                    subtitle:null,
                    body:"Toute pièce reconnue par nous comme défectueuse ou non conforme, sera remplacée sans que cette reconnaissance puisse donner droit à des dommages et intérêts. La durée de garantie des appareils est de douze mois à partir de la date de livraison. Cette garantie ne couvre pas les consommables, les erreurs de manipulation, les carences extérieures et les utilisations anormales. Pour certaines pièces il y a des restrictions de celle-ci comme par exemple les électrodes, lampes, thermomètres, résistances chauffantes, piles ou batteries…pour lesquels il n’y a aucune garantie. Sauf stipulation contraire, les pièces détachées fournies par la Société BIO-TECHNOFIX sont garanties 3 mois."
                }]
            },
            {
                title:"PAIEMENT",
                content:[{
                    subtitle:null,
                    body:"Nos factures sont payables, sans conditions, à notre siège, par chèque, traite ou virement bancaire à 30 jours, date de facture. Un acompte sera exigé pour des commandes supérieures à 3 000.00 €HT. Le paiement ne peut être retardé sous quelque prétexte que ce soit, même litigieux. Tout retard de paiement, outre les conséquences légales, entraînera l’exigibilité de pénalités de retard dont le taux est égal à une fois et demie le taux d’intérêt légal de l’année en cours sans autre mise en demeure (conformément à la loi NRE 2001-419 du 15 mai 2001). Toute détérioration du crédit de l’acheteur pourra justifier l’exigence de garanties ou d’un règlement comptant, avant l’exécution des commandes reçues. Il en va de même pour des articles “sur mesure”. Les produits livrés demeurent la propriété de BIO-TECHNOFIX jusqu’à complet règlement. Le règlement de toute livraison partielle devient exigible à la date d’échéance portée sur la facture et non pas lors de la livraison d’un éventuel reliquat."
                }]
            },
            {
                title:"ACCORD DE FACTURATION DE SERVICE",
                content:[{
                    subtitle:null,
                    body:"Toute demande de service, hors contrat d’entretien, implique l’expédition d’un bon de commande à notre siège, l’acceptation de facturation ainsi que le paiement de la part du client. Le client doit s’assurer que les membres de son service qui demandent une intervention sont dûment autorisés à le faire. Lorsque des procédures particulières doivent être respectées chez le client, ce dernier doit, sous sa propre responsabilité, s’assurer que le personnel de BIO-TECHNOFIX est informé des procédures exigées. Toute intervention hors contrat d’entretien sera facturée au tarif en vigueur à la date de l’intervention. Cette intervention comprendra : - les frais fixes, couvrant les frais administratifs - la main d’œuvre et le déplacement - l’échange standard et les pièces détachées. Si une intervention est interrompue à la demande du client, ce dernier devra supporter les frais engagés. Toute heure entamée est due."
                }]
            },
            {
                title:"DESCRIPTIONS, FIGURES ET PHOTOS",
                content:[{
                    subtitle:null,
                    body:"Les descriptions, schémas, illustrations, spécifications contenues dans nos catalogues, documentations, tarifs, protocoles ou notices ne sont donnés qu’à titre indicatif, sans engagement de la Société BIO-TECHNOFIX et restent sujets à des variations qu’imposeraient par exemple une évolution des techniques. Ils restent la propriété de la Société BIO-TECHNOFIX et ne peuvent en aucun cas être reproduits ou communiqués à des tiers sans notre consentement."
                }]
            },
            {
                title:"UTILISATION",
                content:[{
                    subtitle:null,
                    body:"Les appareils, consommables et réactifs que nous commercialisons sont exclusivement destinés à des usages de laboratoire, leur utilisation engage la seule responsabilité de l’utilisateur qui veillera par conséquent à ce que leur usage soit conforme aux différentes règlementations légales en vigueur. L’absence de signalisation sur le produit n’écarte pas le risque de danger potentiel. En conséquence, le produit doit être exclusivement manipulé et utilisé par du personnel qualifié, connaissant les risques liés à l’utilisation du produit et les précautions à prendre."
                }]
            },
            {
                title:"DÉCHETS D’EQUIPEMENTS ELECTRIQUES ET ELECTRONIQUES (DEEE)",
                content:[{
                    subtitle:null,
                    body:"BIO-TECHNOFIX assure la gestion de l’enlèvement et du traitement des déchets issus des équipements électriques et électroniques professionnels mis sur le marché par notre société après le 13 août 2005 conformément à l’article 18 du décret 2005-829. L’utilisateur final informera BIO-TECHNOFIX dès que les dits équipements auront atteint leur fin de vie ou lorsqu’il souhaitera pourvoir à leur remplacement. La prestation d’enlèvement et de traitement des équipements sera facturée à l’utilisateur final."
                }]
            },
            {
                title:"CLAUSE DE RÉSERVE DE PROPRIÉTÉ",
                content:[{
                    subtitle:null,
                    body:"Les marchandises livrées à l’acheteur restent la propriété de la société BIO-TECHNOFIX jusqu’au paiement intégral du prix de vente. En cas de non paiement des marchandises aux échéances convenues BIO-TECHNOFIX se réserve le droit d’en reprendre possession sans procédure. Les frais de retour seront facturés à l’acheteur."
                }]
            },
            {
                title:"JURIDICTION",
                content:[{
                    subtitle:null,
                    body:"Pour toute contestation, les tribunaux du lieu de notre siège sont seuls compétents."
                }]
            },
        ])
    }
}
