import React from "react";
import UserAPI from "../../../../../apis/UserAPI";
import {LinearProgress} from "@mui/material";
import QuoteList from "../../../../../components/quote/QuoteList/QuoteList";
import AdminAPI from "../../../../../apis/AdminAPI";

export default class QuotesPendingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quotes: [],
            loadingQuotes:false,
        }
    }

    componentDidMount() {
        this.getQuotes();
    }

    getQuotes(){
        this.setState({loadingQuotes:true})
        AdminAPI.getPendingQuotes()
            .then((res)=>{
                let quotes = UserAPI.mapQuotesResponse(res);
                this.setState({
                    loadingQuotes:false,
                    quotes:quotes,
                })
            })
            .catch((err)=>{
                console.log(err)
                this.setState({
                    loadingQuotes:false
                })
            })
    }

    render(){
        return(
            <div style={{width:"100%", position:"relative"}}>
                {this.state.loadingQuotes === true && <LinearProgress style={{position:"absolute", height:5, color:"#0068B3", width:"100%", left:0, top:26, right:0}}/>}
                <p style={{textAlign:"left", margin:0, marginTop:12, marginBottom:12, color:"#0068B3", fontWeight:"bold", fontSize:16}}>
                    {"Nouveaux devis"}
                </p>
                <QuoteList
                    quotes={this.state.quotes}
                    virtualized={true}
                    listHeight={this.state.quotes.length >= 10 ? 1000 : this.state.quotes.length*100}
                />
            </div>
        )
    }

}
