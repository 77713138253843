import {CircularProgress, Typography} from "@mui/material";

export function Loader(props) {


    return <div style={{display: "flex", alignItems:"center", justifyContent: "center", flexDirection: "column", flex: 1, height: props.height||"inherit"}}>
        <CircularProgress/>
        <Typography style={{marginTop: 10}}>Chargement en cours</Typography>
    </div>
}
