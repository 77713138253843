import UserAPI from "./UserAPI";
import {EndPointProvider} from "./EndPointProvider";
import {db} from "../db";
import axios from "axios";

export default class AdminAPI {

    /**
     * Quick add a picture
     * @param article_id
     * @param url
     * @returns {Promise<*>}
     */
    static async quickAddPicture(article_id, url) {
        return await UserAPI._post('/api/article/' + article_id + '/add/picture/from/web', {url: url}, true);
    }

    /**
     * Quick add a PDF
     * @param article_id
     * @param url
     * @returns {Promise<*>}
     */
    static async quickAddPDF(article_id, url) {
        return await UserAPI._post('/api/article/' + article_id + '/add/pdf/from/web', {url: url}, true);
    }

    /**
     * Delete an attachment by ID
     * @param attachment_id
     * @returns {Promise<*>}
     */
    static async deleteAttachment(attachment_id) {
        return await UserAPI._delete(`/api/article/attachment/${attachment_id}`)
    }

    static async resetFirstAttachment(attachment_id, article_id) {
        return await UserAPI._getSecured(`/api/protected/reset/${article_id}/first/${attachment_id}/attachment`)
    }

    /**
     * putYoutubeLink
     * @param article_id
     * @param payload
     * @returns {Promise<void>}
     */
    static async putYoutubeLink(article_id, link) {
        return await UserAPI._put(`/api/protected/put/${article_id}/youtube/link`, {url: link})
    }

    static async getAllSalesCampaigns() {
        return await UserAPI._getSecured( `/api/protected/all/sales/campaigns`);
    }

    static async getSalesCampaignArticles(sales_campaign_id) {
        return await UserAPI._getSecured( `/api/protected/${sales_campaign_id}/sales/campaign/articles`);
    }

    /**
     * Create a sales campaign
     * @param sqlStart
     * @param sqlEnd
     * @param title
     * @param percentage
     * @returns {Promise<*>}
     */
    static async createSalesCampaign(sqlStart, sqlEnd, title, percentage) {

        return await UserAPI._post(`/api/protected/create/sales/campaign`, {startDateTime: sqlStart, endDateTime: sqlEnd, title: title, percentage: +percentage}, true);
    }

    /**
     * Add an article to a sales campaign. If impossible, returns overlapping_promotion => sales_campaign_id
     * @param sales_campaign_id
     * @param article_id
     * @returns {Promise<*>}
     */
    static async addArticleToSalesCampaign(sales_campaign_id, article_id) {
        return await UserAPI._getSecured(`/api/protected/${sales_campaign_id}/sales/campaign/add/${article_id}/article`)
    }

    /**
     * Delete a full campaign. Use with great care.
     * @param sales_campaign_id
     * @returns {Promise<*>}
     */
    static async deleteSalesCampaign(sales_campaign_id) {
        return await UserAPI._delete(`/api/protected/delete/${sales_campaign_id}/sales/campaign`);
    }

    /**
     * Closes now a sales campaign
     * @param sales_campaign_id
     * @returns {Promise<*>}
     */
    static async closeNowSalesCampaign(sales_campaign_id) {
        return await UserAPI._put(`/api/protected/close/${sales_campaign_id}/sales/campaign`);
    }

    /**
     * payload is : {title: string, percentage: number}
     * @param sales_campaign_id
     * @param payload
     * @returns {Promise<void>}
     */
    static async putSalesCampaign(sales_campaign_id, payload) {
        return await UserAPI._put(`/api/protected/update/${sales_campaign_id}/sales/campaign`);
    }

    /**
     * Delete an article from a sales campaign
     * @param sales_campaign_id
     * @returns {Promise<*>}
     */
    static async removeArticleFromSalesCampaign(article_id, sales_campaign_id) {
        return await UserAPI._delete(`/api/protected/remove/${article_id}/article/from/${sales_campaign_id}/sales/campaigns`);
    }


    /**
     * You can update the title or the percentage
     * @param sales_campaign_id
     * @param payload
     * @returns {Promise<*>}
     */
    static async updateSalesCampaign(sales_campaign_id, payload) {
        return await UserAPI._put(`/api/protected/update/${sales_campaign_id}/sales/campaign`, payload);
    }

    /**
     * If article was featured, it is now not featured. Can you imagine what happens if it was featured?
     * @param article_id
     */
    static async toggleFeatured(article_id) {
        return await UserAPI._getSecured(`/api/protected/toggle/${article_id}/featured`)
    }

    /**
     * Adding a file to an article
     * @param article_id
     * @param file
     * @returns {Promise<any>}
     */
    static async addFileToArticle(article_id, file:File) {

        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + (await db.jwt.get(1))?.jwt);

        let formdata = new FormData();
        formdata.append("file", file, file.name);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        try {
            return (await (await fetch(EndPointProvider.getBaseURLWithoutEndSlash() + `/api/protected/post/article/${article_id}/attachment`, requestOptions)).json());
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }

    /**
     *
     * @returns {Promise<void>}
     */
    static async getPendingQuotes() {
        let status = "PENDING";
        return await UserAPI._getSecured(`/api/protected/quotes/by/${status}/status`)
    }

    static async getOnGoingQuotes() {
        let status = "ON_GOING";
        return await UserAPI._getSecured(`/api/protected/quotes/by/${status}/status`)
    }

    static async getArchivedQuotes() {
        let status = "ARCHIVED";
        return await UserAPI._getSecured(`/api/protected/quotes/by/${status}/status`)
    }

    static async updateQuoteStatus(quote_id, new_status) {
        return await UserAPI._post(`/api/protected/update/quote/${quote_id}/status`, {new_status: new_status}, true);
    }

    static async performOxygeneImport(file){
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + (await db.jwt.get(1))?.jwt);

        let formdata = new FormData();
        formdata.append("file", file, file.name);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        try {
            return (await (await fetch(EndPointProvider.getBaseURLWithoutEndSlash() + `/api/oxygene/perform/import`, requestOptions)).json());
        } catch (e) {
            if(e.response) {
                throw new Error(+e.response.status);
            }
            throw new Error(600);
        }
    }

    static async getUserList() {
        return await UserAPI._getSecured(`/api/protected/all/users/list`)
    }

    static async deleteUser(user_id) {
        return await UserAPI._delete(`/api/protected/${user_id}/user/delete`)
    }

    static async getNotifiedEmailsList() {
        return await UserAPI._getSecured(`/api/protected/mailer/btx/staff`)
    }





}
