import React from "react";
import {Snackbar,Alert} from "@mui/material";

export default class SnackBar extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state={
            open:false,
            message:"",
            severity:"info"
        }
    }

    _showSnackBarSubscription;

    componentDidMount(){
        this._showSnackBarSubscription = global.emitter.addListener("showSnackBar",(data)=>{
            this.openSnackBar(data)
        })
    }

    closeSnackBar(){
        this.setState({
            open:false,
            message:"",
            severity:"info"
        })
    }

    openSnackBar(data){
        this.setState({
            open:true,
            message:data.message,
            severity:data.severity,
        })
    }

    render(){
        return(
            <Snackbar
                anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
                autoHideDuration={3000}
                onClose={()=>{this.closeSnackBar()}}
                open={this.state.open}
            >
                <Alert onClose={()=>{this.closeSnackBar()}} severity={this.state.severity} sx={{ width: '100%'}}>
                    {this.state.message}
                </Alert>
            </Snackbar>
        )
    }


}
