import React from "react";
import './AboutPage.css';
import {Paper} from "@mui/material";
import btx_office from "../../images/promotionalPhotos/btx-office.jpg"

export default class AboutPage extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div style={{width: "100%"}}>
               <Paper style={{marginTop:25, width:"100%", padding:12}}>

                   <div className={"about-page-content-wrapper"}>

                       <img alt={"btx"} src={btx_office} style={{width:400, borderRadius:4}} className={"btx-image"}/>

                       <div style={{flex:1, paddingLeft:12}}  className={"btx-texts-wrapper"}>

                           <p style={{color:"#222222",fontSize:20, fontWeight:"bold", margin:0}}>Qui sommes-nous ?</p>

                           <p style={{color:"#555555",fontSize:15, margin:0, marginTop:20}}>
                               Bio-Technofix, société indépendante de taille humaine, commercialise des consommables, matériels, appareillages et produits chimiques destinés aux laboratoires de l'enseignement, de la recherche, ou de l'industrie.
                               <br/> <br/>
                               Outre son activité historique de verrier (Les Verreries SEVAL), Bio-Technofix est l' importateur exclusif pour la France des produits 4titude : consommables plastiques pour la PCR, le séquençage, le screening ou le stockage.
                               <br/> <br/>
                               Fidèle à sa politique de service, Bio-Technofix possède la particularité de livrer directement via son propre service de livraison ses clients de la couronne parisienne.
                           </p>

                       </div>

                   </div>

               </Paper>
            </div>
        )
    }
}
