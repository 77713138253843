import React from "react";
import './CartValidatePage.css';
import {
    Button,
    Icon,
    ButtonBase,
    Popover,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    DialogContentText,
    Paper,
    LinearProgress,
    Grid,
    Typography,
    TextField,
    FormControlLabel, Checkbox, CircularProgress, Alert
} from "@mui/material";
import {withRouter} from "../../withRouter";
import UserApi from "../../apis/UserAPI";
import {Link} from "react-router-dom"
import CartAPI from "../../apis/CartAPI";
import {ExposedAPI} from "../../apis/ExposedAPI";

class ArticleEditPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state={

            logged:UserApi.isUserLoggedIn(),

            firstName:"",
            lastName:"",
            email:"",
            phone:"",

            dStreetName:"",
            dStreetNameComp:"",
            dZipCode:"",
            dCity:"",
            dCountry:"",

            bStreetName:"",
            bStreetNameComp:"",
            bZipCode:"",
            bCity:"",
            bCountry:"",

            sameAdresses:false,

            comment:"",

            articleAmount:0,
            htTotal:0,
            ttcTotal:0,
            taxesTotal:0,
            articles:[],

            emailError:null,

            sendingQuote:false,
            quoteSent:false,
        }
    }

    componentDidMount(){
        if(this.state.logged && global.user){
            this.hydrateUserFields();
        }

        this.getCartContent()
    }

    hydrateUserFields(){
        this.setState({
            firstName:global.user.firstName,
            lastName:global.user.lastName ,
            email:global.user.email ,
            phone:"",

            dStreetName:global.user.default_delivery_address ? global.user.default_delivery_address.street : "",
            dStreetNameComp:global.user.default_delivery_address ? global.user.default_delivery_address.additionalStreet : "",
            dZipCode:global.user.default_delivery_address ? global.user.default_delivery_address.zipCode : "",
            dCity:global.user.default_delivery_address ? global.user.default_delivery_address.city : "",
            dCountry:global.user.default_delivery_address ? global.user.default_delivery_address.country : "",

            bStreetName:global.user.default_billing_address ? global.user.default_billing_address.street : "",
            bStreetNameComp:global.user.default_billing_address ? global.user.default_billing_address.additionalStreet : "",
            bZipCode:global.user.default_billing_address ? global.user.default_billing_address.zipCode : "",
            bCity:global.user.default_billing_address ? global.user.default_billing_address.city : "",
            bCountry:global.user.default_billing_address ? global.user.default_billing_address.country : "",
        })
    }

    getCartContent(){
        CartAPI.getCartContent()
            .then((cartArticles)=>{
                let articles = [];
                let articleAmount = 0;
                let htTotal = 0;
                let ttcTotal = 0;
                let taxesTotal = 0;

                for(let cartArticle of cartArticles){
                    let art = cartArticle.article;
                    art.quantity = parseInt(cartArticle.quantity);
                    articles.push(art);

                    let quantity = parseInt(cartArticle.quantity);
                    let htPrice = +cartArticle.article.basePrice;
                    let ttcPrice = +cartArticle.article.ttcPrice;

                    if(art.activeSaleCampaign){
                        htPrice = htPrice*(1-parseFloat(art.activeSaleCampaign.percentage)/100);
                        ttcPrice = ttcPrice*(1-parseFloat(art.activeSaleCampaign.percentage)/100);
                    }

                    articleAmount = articleAmount + quantity;
                    htTotal = htTotal + quantity*htPrice;
                    ttcTotal = ttcTotal + quantity*ttcPrice;
                }

                htTotal = htTotal.toFixed(2)
                ttcTotal = ttcTotal.toFixed(2)

                taxesTotal = (ttcTotal - htTotal).toFixed(2);

                this.setState({
                    articles:articles,
                    articleAmount:articleAmount,
                    htTotal:htTotal,
                    ttcTotal:ttcTotal,
                    taxesTotal:taxesTotal,
                })

            })
            .catch((err)=>{
                console.log(err);
            })
    }

    render(){
        const fields = this.getFields();
        const isFinishDisabled = this.isFinishDisabled();

        return(
            <div style={{width:"100%", marginTop:25}}>
                <p style={{margin:0, fontSize:24, color:"#555555"}}>Valider mon devis</p>

                <Paper style={{width:"100%", padding:12, marginTop:25}}>

                    <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>

                        <div style={{height:24, width:24, backgroundColor:"#0068B3", borderRadius:"50%", overflow:"hidden", display:"flex",
                            justifyContent:"center", alignItems:"center", marginRight:8}}>
                            <span style={{color:"#FFFFFF", fontSize:14, fontWeight:"bold"}}>1</span>
                        </div>

                        <span style={{color:"#222222", fontSize:14, fontWeight:"bold"}}>Coordonnées</span>
                    </div>


                    <div className={"cart-validate-coordinates-wrapper"}>

                        <div className={"cart-validate-coordinates-grid-elt"}>

                            <Grid container spacing={2} style={{marginTop:8}}>
                                {
                                    fields.map((field, index) => field.spacer ?
                                        <Grid key={index} item xs={12} style={{marginTop:8}}>
                                            <Typography variant={"h4"} />{field.spacer}

                                            {field.toggle &&
                                                <FormControlLabel style={{marginLeft:8, borderRadius:4, backgroundColor:"#FAFAFA", border:"1px solid #EFEFEF",
                                                padding:0, paddingRight:14}} control={
                                                    <Checkbox checked={this.state[field.toggle.k]}
                                                              onChange={(e)=>{this.handleCheckValueChange(e,field.toggle.k)}}
                                                    />
                                                } label={field.toggle.l} />
                                            }
                                        </Grid>
                                        :
                                        <Grid  key={index} item md={field.md || 6} xs={12}>
                                           <div style={{width:"100%", position:"relative"}}>
                                               <TextField
                                                   onChange={(e)=>{this.handleFieldValueChange(e,field.k)}}
                                                   style={{backgroundColor:field.disabledIf && this.state[field.disabledIf] === true ? "#FAFAFA" : "#FFFFFF",}}
                                                   value={this.state[field.k]}
                                                   label={field.l} fullWidth={true}
                                                   disabled={field.disabledIf && this.state[field.disabledIf] === true}
                                                   size="small"
                                                   variant="outlined"
                                                   required={field.r === true}
                                                   error={this.state[field.error]}
                                                   helperText={this.state[field.error] ? "Ce champ est obligatoire" : null}
                                               />
                                               {field.id && <div style={{position:"absolute", left:0, top:-60}} id={field.id}/>}
                                           </div>
                                        </Grid>
                                    )
                                }
                            </Grid>


                        </div>

                        {!this.state.logged &&
                            <Paper className={"cart-validate-login-popup"}>
                                <p style={{width:"100%", color:"#FFFFFF", margin:0, textAlign:"center"}}>
                                    Vous n'êtes pas connecté à votre compte client. Pour pré-remplir les champs, connectez-vous:
                                </p>

                                <Link to={"/login?from=validate"} style={{textDecoration:"none"}}>
                                    <Button size={"medium"} variant={"contained"} style={{backgroundColor:"#FFFFFF", color:"#0068B3", marginTop:25}}>
                                        <Icon style={{color:"#0068B3", fontSize:18, marginRight:8}}>login</Icon>
                                        ME CONNECTER
                                    </Button>
                                </Link>

                            </Paper>
                        }


                    </div>


                </Paper>

                <Paper style={{width:"100%", padding:12, marginTop:25}}>

                    <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>

                        <div style={{height:24, width:24, backgroundColor:"#0068B3", borderRadius:"50%", overflow:"hidden", display:"flex",
                            justifyContent:"center", alignItems:"center", marginRight:8}}>
                            <span style={{color:"#FFFFFF", fontSize:14, fontWeight:"bold"}}>2</span>
                        </div>

                        <span style={{color:"#222222", fontSize:14, fontWeight:"bold"}}>Récapitulatif</span>
                    </div>

                    <div className={"cart-validate-recap-wrapper"}>

                        <Paper style={{ padding:12, backgroundColor:"#FAFAFA", border:"1px solid #DDDDDD"}} className={"cart-validate-recap"}>
                            <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"center"}}>
                                <p style={{flex:1, textAlign:"left", color:"#555555", fontSize:14, margin:0}} className={"cart-laius"}>
                                    Informations de votre devis
                                </p>
                                <Icon style={{fontSize:18, color:"#797979"}}>keyboard_arrow_down</Icon>
                            </div>

                            <div style={{width:"100%", borderRadius:4, border:"1px solid #CCCCCC", overflow:"hidden", marginTop:12, backgroundColor:"#FFFFFF"}}>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:50}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Total HT
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#0068B3", fontSize:24, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.htTotal}€
                                        </p>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:36, borderTop:"1px solid #CCCCCC"}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Total TTC
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.ttcTotal}€
                                        </p>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:36, borderTop:"1px solid #CCCCCC"}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Total taxes
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.taxesTotal}€
                                        </p>
                                    </div>
                                </div>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", height:36, borderTop:"1px solid #CCCCCC"}}>
                                    <div style={{width:"40%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", borderRight:"1px solid #CCCCCC", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, fontWeight:"bold", width:"100%", textAlign:"left", margin:0}}>
                                            Nbr articles
                                        </p>
                                    </div>
                                    <div style={{width:"60%", height:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start",
                                        alignItems:"center", paddingLeft:8}}>
                                        <p style={{color:"#222222", fontSize:14, width:"100%", textAlign:"left", margin:0}}>
                                            {this.state.articleAmount}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Paper>

                        <div style={{flex:1, display:"flex", flexDirection:"column", marginTop:12}}>
                            <TextField

                                onChange={(e)=>{this.setState({comment:e.target.value})}}
                                value={this.state.comment}
                                multiline
                                rows={5}
                                label={"Commentaire (facultatif)"}
                                fullWidth={true}
                                variant="outlined"
                            />

                            <Button size={"large"} variant={"contained"} fullWidth style={{backgroundColor:isFinishDisabled ? "#CCCCCC" : "#1FAF3F",
                                color:"#FFFFFF", marginTop:25}} disabled={isFinishDisabled} onClick={()=>{this.tryFinish()}}>
                                ENVOYER LA DEMANDE DE DEVIS
                                <Icon style={{color:"#FFFFFF", fontSize:18, marginLeft:8}}>send</Icon>
                            </Button>

                        </div>

                    </div>
                </Paper>

                {this.renderLoader()}
            </div>
        )
    }

    isFinishDisabled(){
        let result = false;
        if(this.state.articleAmount === 0 || !this.state.email || this.state.email.length === 0){result = true}
        return result;
    }

    getFields(){
        let fields = [
            {spacer: "Informations générales"},
            {k:"firstName", l: "Prénom"},
            {k: "lastName", l: "Nom"},
            {k: "email", l: "Email", r:true, id:"email", error:"emailError"},
            {k: "phone", l: "Téléphone"},
            {spacer: "Adresse de livraison"},
            {k: "dStreetName", l: "Nom et adresse", md:12},
            {k: "dStreetNameComp", l: "Complément d'adresse", md: 12},
            {k: "dZipCode", l: "Code postal", md: 3},
            {k: "dCity",l: "Ville", md: 5},
            {k: "dCountry", l: "Pays", md: 4},
            {spacer: "Adresse de facturation", toggle:{k:"sameAdresses",l:"Utiliser l'adresse de livraison",}},
            {k: "bStreetName", l: "Nom et adresse", md:12, disabledIf:"sameAdresses"},
            {k: "bStreetNameComp", l: "Complément d'adresse", md: 12, disabledIf:"sameAdresses"},
            {k: "bZipCode", l: "Code postal", md: 3, disabledIf:"sameAdresses"},
            {k: "bCity", l: "Ville", md: 5, disabledIf:"sameAdresses"},
            {k: "bCountry", l: "Pays", md: 4, disabledIf:"sameAdresses"}
        ]

        return fields;
    }

    handleFieldValueChange(event, name) {

        this.setState({
            [name]: event.target.value
        },()=>{

            let bStreetName = this.state.bStreetName;
            let bStreetNameComp = this.state.bStreetNameComp;
            let bZipCode = this.state.bZipCode;
            let bCity = this.state.bCity;
            let bCountry = this.state.bCountry;

            if(this.state.sameAdresses){
                bStreetName = this.state.dStreetName;
                bStreetNameComp = this.state.dStreetNameComp;
                bZipCode = this.state.dZipCode;
                bCity = this.state.dCity;
                bCountry = this.state.dCountry;
            }

            this.setState({
                bStreetName:bStreetName,
                bStreetNameComp:bStreetNameComp,
                bZipCode:bZipCode,
                bCity:bCity,
                bCountry:bCountry,
                emailError:null,
            })
        })
    }

    handleCheckValueChange(event,name){

        let bStreetName = this.state.bStreetName;
        let bStreetNameComp = this.state.bStreetNameComp;
        let bZipCode = this.state.bZipCode;
        let bCity = this.state.bCity;
        let bCountry = this.state.bCountry;

        if(name === "sameAdresses" && event.target.checked === true){
            bStreetName = this.state.dStreetName;
            bStreetNameComp = this.state.dStreetNameComp;
            bZipCode = this.state.dZipCode;
            bCity = this.state.dCity;
            bCountry = this.state.dCountry;
        }

        this.setState({
            [name]: event.target.checked,
            bStreetName:bStreetName,
            bStreetNameComp:bStreetNameComp,
            bZipCode:bZipCode,
            bCity:bCity,
            bCountry:bCountry,
            emailError:null,
        })
    }

    tryFinish(){
        if(!this.state.email || this.state.email.length === 0){
            this.setState({
                emailError:true,
            },()=>{
                try{
                    let elt = document.getElementById("email");
                    elt.scrollIntoView({block: "start", inline: "nearest"})
                }
                catch(e){console.log(e)}
            })
        }
        else{

            let payload = this.getPayload();

            this.setState({sendingQuote:true,})

            ExposedAPI.postQuote(payload)
                .then(()=>{
                    global.emitter.emit("showSnackBar", {severity:"success", message : "Votre demande de devis a été envoyée avec succès !"});
                    CartAPI.resetCart()
                        .then(()=>{
                            this.setState({
                                quoteSent:true
                            })
                        })
                })
                .catch((err)=>{
                    console.log(err);
                    global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue lors du traitement de votre demande"});
                    this.setState({
                        quoteSent:false,
                        sendingQuote:false,
                    })
                })

        }
    }

    getPayload(){
        let payload = {
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            email:this.state.email,
            phone:this.state.phone,
            comment:this.state.comment,

            deliveryStreet:this.state.dStreetName,
            deliveryStreetComplement:this.state.dStreetNameComp,
            deliveryCityZipCode:this.state.dZipCode,
            deliveryCity:this.state.dCity,
            deliveryCountry:this.state.dCountry,

            billingStreet:this.state.bStreetName,
            billingStreetComplement:this.state.bStreetNameComp,
            billingCityZipCode:this.state.bZipCode,
            billingCity:this.state.bCity,
            billingCountry:this.state.bCountry,

            lines:[]
        }

        for(let article of this.state.articles){
            payload.lines.push({
                article_id:article.id,
                quantity:article.quantity,
            })
        }

        return payload;
    }

    quitPage(){
        this.props.router.navigate("/my/account/quotes")
    }

    renderLoader(){
        if(this.state.sendingQuote === false){return null}
        return(
            <Dialog open={true}>
                <DialogTitle>
                    {this.state.quoteSent ? "Demande envoyée" : "Envoi en cours"}
                </DialogTitle>
                <DialogContent>
                    {this.state.quoteSent === false &&
                        <div style={{width:350, height:50, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center', borderRadius:6,
                            border:"1px solid #FFFFFF"}}>
                            <div style={{height:50, width:40, marginRight:10, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <CircularProgress size={24} style={{color:"#0068B3"}}/>
                            </div>
                            <p style={{flex:1, paddingLeft:8, paddingRight:8, textAlign:"left", color:"#797979", fontSize:16, margin:0}}>
                                Votre demande de devis est en cours d'envoi, veuillez patienter ...
                            </p>
                        </div>
                    }
                    {this.state.quoteSent === true  &&
                        <Alert severity="success">
                            <strong>Confirmation: </strong> Votre demande de devis a été envoyée à nos services, nous vous transmettrons très prochainement
                            un devis correspondant à votre demande. Vous pouvez consulter l'avancement de votre demande sur la page "MES DEVIS"
                        </Alert>
                    }
                </DialogContent>

                {this.state.quoteSent === true  &&
                    <DialogActions>
                        <Button style={{color:"#0068B3"}} onClick={()=>{this.quitPage()}}>
                            VOIR MES DEVIS
                        </Button>
                    </DialogActions>
                }

            </Dialog>
        )
    }
}

export default withRouter(ArticleEditPopup)
