import {useEffect, useState} from "react";
import {Alert, Box, Breadcrumbs, Button, Grid, LinearProgress, Link, Paper, TextField, Typography} from "@mui/material";
import {VerifiedUser} from "@mui/icons-material";
import {ExposedAPI} from "../../apis/ExposedAPI";
import UserAPI from "../../apis/UserAPI";
import {useNavigate} from "react-router-dom";


export function LoginPage() {

    const [loginDelay, setLoginDelay] = useState(100);    //We add a delay to avoid being bullied
    const [loading, setLoading] = useState(false);    //We add a delay to avoid being bullied
    const [forgottenPassword, setForgottenPassword] = useState(false);    //We add a delay to avoid being bullied
    const [createAccount, setCreateAccount] = useState(false);    //We add a delay to avoid being bullied
    const [fpAck, setFpAck] = useState(false);    //forgotten password acknowledgement. Will tell you if you're logged in.
    const [email, setEmail] = useState("");    //We add a delay to avoid being bullied
    const [pass, setPass] = useState("");    //We add a delay to avoid being bullied
    const [denied, setDenied] = useState(false);    //We add a delay to avoid being bullied


    // Create account s
    const [newFirstName, setNewFirstName] = useState("");    //We add a delay to avoid being bullied
    const [newLastName, setNewLastName] = useState("");    //We add a delay to avoid being bullied
    const [newPhone, setNewPhone] = useState("");    //We add a delay to avoid being bullied
    const [newEmail, setNewEmail] = useState("");    //We add a delay to avoid being bullied
    const [newPass, setNewPass] = useState("");    //We add a delay to avoid being bullied
    const [newPassConfirm, setNewPassConfirm] = useState("");    //We add a delay to avoid being bullied
    const [accountCreationDone, setAccountCreationDone] = useState(false);    //We add a delay to avoid being bullied

    useEffect(_ => {

    })

    let navigate = useNavigate();

    async function login() {
        await setLoading(true);
        await setDenied(false);
        try {
            await sleep(loginDelay);
            await UserAPI.login(email, pass);
            global.emitter.emit("showSnackBar", {severity:"success", message : "Connexion réussie ! Bienvenue !"})

            let urlParams = new URLSearchParams(window.location.search);
            let from = urlParams.get("from");
            if(from && from === "validate"){
                navigate("/cart/validate");
            }
            else{
                navigate("/");
            }

        } catch (error) {
            setPass("");
            setDenied(true);
            setLoginDelay(loginDelay + 100);
            await setLoading(false);
        }

    }

    function sleep(ms) {
        return new Promise(r => setTimeout(_ => r(), ms));
    }

    async function retrievePassword() {
        await setLoading(true);
        await ExposedAPI.retrievePassword(email)
        await setFpAck(true);
        await setLoading(false);
    }

    function isEmailValid(email) {
        return  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());
    }

    function isCreationDisabled(){
        return !newEmail || newEmail.length === 0 || !isEmailValid(newEmail) || !newPass || newPass.length < 6  || !newPassConfirm || newPassConfirm.length < 6  || newPass !== newPassConfirm
    }

    return <Grid container
                 justifyContent="center"
                 alignItems="center">
        <Grid item md={6} xs={12} style={{marginTop: 20, padding:0}}>
            {
                (!forgottenPassword && !createAccount  && !loading) && <Paper style={{margin: 15, padding: 15}}>
                    <Typography variant={"h5"} style={{marginTop: 10, marginBottom: 30}}>Connexion à votre espace personnel</Typography>

                    {
                        denied && <Alert severity={"error"} style={{marginBottom: 30}}>Connexion impossible ! adresse ou mot de passe incorrect.</Alert>
                    }
                    {/*<Typography variant={"body2"}>Addresse e-mail</Typography>*/}
                    <TextField
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        label="Adresse e-mail" fullWidth={true}
                        size="small"
                        variant="outlined"
                        style={{marginBottom: 40}}
                    />
                    <TextField
                        onChange={e => setPass(e.target.value)}
                        value={pass}
                        label="Mot de passe" fullWidth={true}
                        size="small"
                        type={"password"}
                        variant="outlined"
                        style={{marginBottom: 20}}
                    />
                    <div style={{display:"flex", alignItems: "center", justifyContent: "center", marginBottom: 20}}>
                        <Typography className={"linkStyle"} onClick={_ => setForgottenPassword(true)}>E-mail ou mot de passe oublié ?</Typography>
                    </div>
                    <Button disabled={!isEmailValid(email) || pass.length < 2} onClick={_ => login()} fullWidth variant={"outlined"} color={"success"} startIcon={<VerifiedUser/>}>
                        Connexion
                    </Button>
                    <div style={{display:"flex", alignItems: "center", justifyContent: "center", margin: 20}}>
                        <Typography className={"linkStyle"} variant={"text"} onClick={_ => setCreateAccount(true)}>Pas encore de compte ? Créer un nouveau compte</Typography>
                    </div>
                </Paper>
            }

            {/*Forgotten password prompt */}

            {
                forgottenPassword && !loading &&  <Paper style={{margin: 15, padding: 15}}>
                    <Typography variant={"h5"} style={{marginTop: 10, marginBottom: 10}}>Récupérer mon compte</Typography>
                    <Breadcrumbs style={{marginBottom: 20}}>
                        <Link underline="hover" href={"/login"}>
                            Connexion
                        </Link>
                        <Typography>Mot de passe oublié</Typography>
                    </Breadcrumbs>
                    {
                        fpAck && <Alert severity={"success"} style={{marginBottom: 20}}><strong>E-mail envoyé</strong> un email contenant les instructions de récupération de votre compte a été envoyé à l'adresse fournie si celle-ci est bien enregistrée. Suivez les instructions sur cet e-mail pour récupérer votre compte.</Alert>
                    }
                    <TextField
                        onChange={e => setEmail(e.target.value)}
                        disabled={fpAck}
                        value={email}
                        label="Adresse e-mail du compte" fullWidth={true}
                        size="small"
                        variant="outlined"
                        style={{marginBottom: 40}}
                    />
                    <Button disabled={!isEmailValid(email) || fpAck} fullWidth variant={"outlined"} onClick={_ => retrievePassword()} startIcon={<VerifiedUser/>}>
                        Récupérer mon compte
                    </Button>
                </Paper>

            }

            {
                loading &&
                <Paper style={{width: "100%", height: "100%", minHeight: 400, display: "flex", padding: 20, alignItems: "center", justifyContent: "center", flexDirection:"column"}}>
                    <Typography variant={"body1"}>Merci de patienter</Typography>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </Paper>
            }

            {createAccount && !loading &&
                <Paper style={{ padding: 15, marginTop:15}}>

                    <Typography variant={"h5"} style={{marginTop: 10, marginBottom: 10}}>Créer mon compte</Typography>
                    <Breadcrumbs style={{marginBottom: 20}}>
                        <Link underline="hover" href={"/login"}>
                            Connexion
                        </Link>
                        <Typography>Créer mon compte</Typography>
                    </Breadcrumbs>

                    <div style={{width:"100%", display:"flex", flexDirection:"row", marginBottom:20}}>
                        <TextField
                            onChange={e => setNewFirstName(e.target.value)}
                            disabled={accountCreationDone}
                            value={newFirstName}
                            label="Prénom"
                            size="small"
                            variant="outlined"
                            style={{flex:1}}
                        />
                        <div style={{width:20}}/>
                        <TextField
                            onChange={e => setNewLastName(e.target.value)}
                            disabled={accountCreationDone}
                            value={newLastName}
                            label="Nom"
                            size="small"
                            variant="outlined"
                            style={{flex:1}}
                        />
                    </div>

                    <TextField
                        onChange={e => setNewPhone(e.target.value)}
                        disabled={accountCreationDone}
                        value={newPhone}
                        label="Numéro de téléphone" fullWidth={true}
                        size="small"
                        variant="outlined"
                        style={{marginBottom:20}}
                    />

                    <TextField
                        onChange={e => setNewEmail(e.target.value)}
                        disabled={accountCreationDone}
                        value={newEmail}
                        required
                        label="Adresse e-mail (requis)" fullWidth={true}
                        size="small"
                        variant="outlined"
                        style={{marginBottom:20}}
                    />
                    <div style={{width:"100%", display:"flex", flexDirection:"row", marginBottom:20}}>
                        <TextField
                            onChange={e => setNewPass(e.target.value)}
                            disabled={accountCreationDone}
                            value={newPass}
                            required
                            label="Mot de passe (6 char min)"
                            size="small"
                            variant="outlined"
                            type={"password"}
                            style={{flex:1}}
                        />
                        <div style={{width:12}}/>
                        <TextField
                            onChange={e => setNewPassConfirm(e.target.value)}
                            disabled={accountCreationDone}
                            value={newPassConfirm}
                            required
                            label="Confirmer le mot de passe"
                            size="small"
                            variant="outlined"
                            type={"password"}
                            style={{flex:1}}
                        />
                    </div>


                    <Button disabled={isCreationDisabled()} size={"medium"} fullWidth variant={"contained"}
                     style={{backgroundColor:isCreationDisabled() ? "#CCCCCC" : "#0068b3", color:"#FFFFFF"}} onClick={_ => createNewAccount()}>
                        CRÉER MON COMPTE
                    </Button>

                </Paper>
            }

        </Grid>

    </Grid>

    async function createNewAccount() {
        await setLoading(true);

        await new Promise((resolve)=>{setTimeout(()=>{resolve()},1500)})

        let payload = {
            firstName:newFirstName,
            lastName:newLastName,
            phone:newPhone,
            email:newEmail,
            password:newPass,
        }

        try {
            await ExposedAPI.createAccount(payload);
            global.emitter.emit("showSnackBar", {severity:"success", message : "Votre compte client a été créé avec succès !"});
            await setAccountCreationDone(true);
            await setCreateAccount(false);
            await setLoading(false);
        }
        catch(e){
            console.log(e);
            global.emitter.emit("showSnackBar", {severity:"error", message : "Une erreur est survenue lors de la création du compte"});
            await setLoading(false);
        }

    }



}
