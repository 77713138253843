
import React from "react";
import './ArticleRow.css';
import {Button,Icon,Paper, IconButton} from "@mui/material";
import {ExposedAPI} from "../../apis/ExposedAPI";
import {withRouter} from "../../withRouter";

import discount from "../../images/pennants/discount.svg";
import featured from "../../images/pennants/featured.svg";
import CartAPI from "../../apis/CartAPI";

class ArticleRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            amountToAdd:1
        }
    }

    goToArticlePage(){
        global.emitter.emit("articleNavigationTriggered")
        this.props.router.navigate("/a/" + this.props.article.id);
    }

    render(){

        const article = this.props.article;
        // if(article.soft_deleted === "1"){return null;}

        return(
            <div style={{width:"100%"}} key={"art_" + article.id} className={"article-wrapper"}>
                <div style={{width:"100%", height:100, borderBottom:"1px solid #EEEEEE"}} className={"article-row-wrapper"}
                     onClick={()=>{this.goToArticlePage()}}
                     onContextMenu={(e)=>{global.onArticleRightClicked(e,article)}}
                >
                    <div className={"article-image-wrapper"} style={{position:"relative"}}>
                        <div className={"article-image"} style={{backgroundImage:"url(" + ExposedAPI.getThumbnailURL(article.id) + ")"}}>
                            {article.activeSaleCampaign && article.featured !== "1" && <div className={"article-pennant"} style={{backgroundImage:"url(" + discount + ")"}}/>}
                            {article.featured === "1" && <div className={"article-pennant"} style={{backgroundImage:"url(" + featured + ")"}}/>}
                        </div>
                        {article.activeSaleCampaign &&
                            <p style={{position:"absolute", bottom:8, right:8, padding:2, backgroundColor:"#2CBE4E", color:"#FFFFFF", margin:0, zIndex:22,
                            fontWeight:"bold", borderRadius:4, border:"1px solid #FFFFFF"}}>
                                -{article.activeSaleCampaign.percentage}%
                            </p>
                        }
                    </div>
                    <div className={"article-name-desc"}>
                        <p style={{color:"#222222", fontSize:14, margin:0, textAlign:"left", width:"100%", fontWeight:"bold", marginBottom:4}}>
                            {article.label}
                        </p>
                        <p style={{color:"#555555", fontSize:10, margin:0, textAlign:"left", width:"100%",}}>
                            {article.short_description}
                        </p>
                    </div>
                    <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{article.brandName}</p>
                    </div>
                    <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{article.crmId}</p>
                    </div>
                    <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{article.conditionnement}</p>
                    </div>
                    <div style={{width:100, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>
                        <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center", fontWeight:"bold",
                            textDecoration:article.activeSaleCampaign ? "line-through" : "none"}}>{article.basePrice + "€"}</p>

                        {article.activeSaleCampaign &&
                            <p style={{color:"#2CBE4E", fontSize:16, width:"100%", margin:0, textAlign:"center", fontWeight:"bold", marginTop:4}}>
                                {(parseFloat(article.basePrice)*(1-parseFloat(article.activeSaleCampaign.percentage)/100)).toFixed(2)} €
                            </p>
                        }
                    </div>
                    <div style={{width:100, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                        borderRight:"1px solid #EEEEEE", padding:5}}>

                        {this.props.isCart !== true &&
                            <>
                                <input type="number" min="0" value={this.state.amountToAdd} style={{width:"100%", textAlign:"center", padding:4, paddingLeft:16}}
                                       onClick={(e)=>{e.stopPropagation()}}
                                       onChange={(event)=>{this.onAmountToAddChanged(event.target.value)}}/>
                                <Button variant={"contained"} size={"small"} style={{backgroundColor:"#1faf3f", color:"#FFFFFF",
                                    marginTop:8}} onClick={(e)=>{e.stopPropagation(); this.addToCart()}}>
                                    AJOUTER
                                </Button>
                            </>
                        }

                        {this.props.isCart === true &&
                            <input type="number" min="0" value={article.quantity} style={{width:"100%", textAlign:"center", padding:4, paddingLeft:16}}
                                   onClick={(e)=>{e.stopPropagation()}}
                                   onChange={(event)=>{this.onQuantityChanged(event.target.value)}}/>
                        }


                    </div>
                    <div style={{flex:1, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start", padding:5}}>

                        {this.props.isCart !== true &&
                            <>

                                <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"left"}}>{article.crm_secondary_id}</p>
                                {article.youtubeLink &&
                                    <a rel={"nofollow noreferrer"} href={article.youtubeLink} target={"_blank"}>
                                        <Icon style={{color:"#FA0001", fontSize:20, margin:6}}>subscriptions</Icon>
                                    </a>
                                }
                            </>
                        }
                        {this.props.isCart === true &&
                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center"}}
                            onClick={(e)=>{e.stopPropagation()}}>

                                <IconButton style={{marginLeft:4}} onClick={(e)=>{e.stopPropagation(); this.removeFromCart()}}>
                                    <Icon style={{color:"#555555", fontSize:18}}>delete</Icon>
                                </IconButton>

                            </div>
                        }

                    </div>
                </div>

                <Paper style={{width:"100%", height:200, border:"1px solid #DDDDDD", borderRadius:6, marginBottom:12}} className={"article-row-card"} onClick={()=>{this.goToArticlePage()}}>
                    <div style={{width:"100%", height:100, borderBottom:"1px solid #EFEFEF", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <div className={"article-image-wrapper"}>
                            <div className={"article-image"} style={{backgroundImage:"url(" + ExposedAPI.getThumbnailURL(article.id) + ")", position:"relative"}}>
                                {article.activeSaleCampaign && article.featured !== "1" && <div className={"article-pennant"} style={{backgroundImage:"url(" + discount + ")"}}/>}
                                {article.featured === "1" && <div className={"article-pennant"} style={{backgroundImage:"url(" + featured + ")"}}/>}
                                {article.activeSaleCampaign &&
                                    <p style={{position:"absolute", bottom:2, right:2, padding:2, backgroundColor:"#2CBE4E", color:"#FFFFFF", margin:0, zIndex:22,
                                        fontWeight:"bold", borderRadius:4, border:"1px solid #FFFFFF"}}>
                                        -{article.activeSaleCampaign.percentage}%
                                    </p>
                                }
                            </div>
                        </div>
                        <div className={"article-name-desc"}>
                            <p style={{color:"#222222", fontSize:14, margin:0, textAlign:"left", width:"100%", fontWeight:"bold", marginBottom:4}}>
                                {article.label}
                            </p>
                            <p style={{color:"#555555", fontSize:10, margin:0, textAlign:"left", width:"100%",}}>
                                {article.short_description}
                            </p>
                        </div>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{article.brandName}</p>
                        </div>
                    </div>
                    <div style={{width:"100%", height:100, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{article.crmId}</p>

                            <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center", marginTop:8}}>{article.crm_secondary_id}</p>
                        </div>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#222222", fontSize:14, width:"100%", margin:0, textAlign:"center"}}>{article.conditionnement}</p>
                        </div>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>
                            <p style={{color:"#0068B3", fontSize:14, width:"100%", margin:0, textAlign:"center", fontWeight:"bold",
                            textDecoration:article.activeSaleCampaign ? "line-through" : "none"}}>{article.basePrice + "€"}</p>
                            {article.activeSaleCampaign &&
                                <p style={{color:"#2CBE4E", fontSize:16, width:"100%", margin:0, textAlign:"center", fontWeight:"bold", marginTop:4}}>
                                    {(parseFloat(article.basePrice)*(1-parseFloat(article.activeSaleCampaign.percentage)/100)).toFixed(2)} €
                                </p>
                            }
                        </div>
                        <div style={{width:100, height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",
                            borderRight:"1px solid #EEEEEE", padding:5}}>

                            {this.props.isCart !== true &&
                                <>
                                    <input type="number" min="0" value={this.state.amountToAdd} style={{width:"100%", textAlign:"center", padding:4,}}
                                           onClick={(e)=>{e.stopPropagation()}}
                                           onChange={(event)=>{this.onAmountToAddChanged(event.target.value)}}/>
                                    <Button variant={"contained"} size={"small"} style={{backgroundColor:"#1faf3f", color:"#FFFFFF",
                                        marginTop:8}} onClick={(e)=>{e.stopPropagation(); this.addToCart()}}>
                                        AJOUTER
                                    </Button>
                                </>
                            }
                            {this.props.isCart === true &&
                                <input type="number" min="0" value={article.quantity} style={{width:"100%", textAlign:"center", padding:4, paddingLeft:16}}
                                       onClick={(e)=>{e.stopPropagation()}}
                                       onChange={(event)=>{this.onQuantityChanged(event.target.value)}}/>
                            }

                        </div>
                        {this.props.isCart === true &&
                            <div style={{flex:1, height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center"}}
                                 onClick={(e)=>{e.stopPropagation()}}>

                                <IconButton style={{marginLeft:4}} onClick={(e)=>{e.stopPropagation(); this.removeFromCart()}}>
                                    <Icon style={{color:"#555555", fontSize:18}}>delete</Icon>
                                </IconButton>

                            </div>
                        }

                    </div>
                </Paper>
            </div>
        )
    }

    onAmountToAddChanged(value){
        if(!value || !CartAPI.isInteger(value)){return}
        this.setState({amountToAdd:value})
    }

    addToCart(){
        let message = "";
        if(this.state.amountToAdd > 1){message = this.state.amountToAdd + " articles ajoutés au devis"}
        else{message = "1 article ajouté au devis"}
        CartAPI.addArticleToCart(this.props.article,this.state.amountToAdd)
            .then((res)=>{
                global.emitter.emit("showSnackBar",{
                    severity:"success",
                    message:message
                })
                this.setState({
                    amountToAdd:1,
                })
            })
            .catch((err)=>{
                console.log(err)
            })
    }

    removeFromCart(){
        CartAPI.removeArticleFromCart(this.props.article.id)
            .then(()=>{}).catch((err)=>{console.log(err)})
    }

    onQuantityChanged(value){
        if(!value || !CartAPI.isInteger(value)){return}
        if(value + "" === "0"){
            CartAPI.removeArticleFromCart(this.props.article.id)
                .then(()=>{}).catch((err)=>{console.log(err)})
        }
        else{
            CartAPI.setArticleQuantity(this.props.article.id,value)
                .then(()=>{}).catch((err)=>{console.log(err)})
        }
    }

}

export default withRouter(ArticleRow)
